import {ISmsService} from "./ISms.service";
import {ICoreContainer} from "../ICoreContainer";

export class SmsService implements ISmsService {
    constructor(
        private readonly _coreContainer: ICoreContainer,
    ) { }

    public async sendSingleSms(phoneNumber: string, smsText: string): Promise<boolean> {
        return await this._coreContainer.IHttpService.smsSendSingle(
            phoneNumber,
            smsText,
        );
    }
}
