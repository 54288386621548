import {IEventPostsService} from "./IEventPosts.service";
import {IPost, IPostEditModel, IPostInsertModel} from "../models/IPost";
import {IHttpService} from "../http/IHttp.service";

export class EventPostsService implements IEventPostsService {
    constructor(
        private readonly _httpService: IHttpService,
    ) { }

    public async addComment(eventId: string, postId: string, comment: string): Promise<boolean> {
        return await this._httpService.eventPostsAddComment(eventId, postId, comment);
    }

    public async createPost(eventId: string, postData: IPostInsertModel): Promise<string | null> {
        const {resourceId: postId} = await this._httpService.eventPostsCreatePost(eventId, postData);
        return postId;
    }

    public async deletePost(eventId: string, postId: string): Promise<boolean> {
        return await this._httpService.eventPostsDeletePost(eventId, postId);
    }

    public async deletePostComment(eventId: string, postId: string, commentId: string): Promise<boolean> {
        return await this._httpService.eventPostsDeletePostComment(eventId, postId, commentId);
    }

    public async editPost(eventId: string, postId: string, postData: IPostEditModel): Promise<boolean> {
        return await this._httpService.eventPostsEditPost(eventId, postId, postData);
    }

    public async getPostDetails(eventId: string, postId: string): Promise<IPost> {
        return await this._httpService.eventPostsGetPost(eventId, postId);
    }

    public async getPosts(eventId: string): Promise<IPost[]> {
        return await this._httpService.eventPostsGetPosts(eventId);
    }
}
