import React, {BaseSyntheticEvent} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputLabel, MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import {FormDialogInputTypeEnum} from "../../core/dialogsController/FormDialogInputTypeEnum";
import {IFormDialogParams} from "../../core/dialogsController/IFormDialogParams";

interface IFormDialogProps extends IFormDialogParams {
    onConfirm: (values: {[key: string]: any}) => any;
    onCancel: () => any;
}

interface IFormDialogState {
    values: {
        [name: string]: any;
    };
}

class FormDialog extends React.Component<IFormDialogProps, IFormDialogState> {
    state: IFormDialogState = {
        values: {},
    };

    constructor(props: IFormDialogProps) {
        super(props);

        for (const input of props.inputs) {
            if (input.type === FormDialogInputTypeEnum.text || input.type === FormDialogInputTypeEnum.select) {
                this.state.values[input.field] = input.defaultValue ? input.defaultValue : '';
            }
        }
    }

    onFieldChange = (field: string) => (event: BaseSyntheticEvent) => this.setState({
        values: {
            ...this.state.values,
            [field]: event.target.value,
        },
    });

    onConfirm = () => {
        const values: {[key: string]: any} = {};
        for (const field in this.state.values) {
            values[field] = this.state.values[field];
        }
        this.props.onConfirm(values);
    };

    onCancel = () => this.props.onCancel();

    canBeSubmitted = (): boolean => {
        return this.props.inputs
            .filter((input) => input.required)
            .filter((input) => input.type === FormDialogInputTypeEnum.text)
            .map((input) => input.field)
            .every((field) => !!this.state.values[field]);
    };

    render() {
        return (
            <Dialog
                maxWidth={'sm'}
                fullWidth={true}
                open={true}
            >
                <DialogTitle>{this.props.title}</DialogTitle>
                <DialogContent>
                    {this.props.inputs.map((row, index) => {
                        switch (row.type) {
                            case FormDialogInputTypeEnum.text: return (
                                <TextField
                                    label={row.label}
                                    value={this.state.values[row.field]}
                                    onChange={this.onFieldChange(row.field)}
                                    required={row.required}
                                    key={`index_${index}`}
                                    fullWidth
                                />
                            );
                            case FormDialogInputTypeEnum.select: return row.options !== undefined ? (
                                <React.Fragment>
                                    <InputLabel style={{marginTop: 10}}>{row.label}</InputLabel>
                                    <Select
                                        value={this.state.values[row.field]}
                                        onChange={this.onFieldChange(row.field)}
                                        required={row.required}
                                        key={`index_${index}`}
                                        fullWidth
                                    >
                                        {row.options.map((option, optionIndex) => (
                                            <MenuItem
                                                key={`index_${optionIndex}`}
                                                value={option.value}
                                            >{option.label}</MenuItem>
                                        ))}
                                    </Select>
                                </React.Fragment>
                            ) : null;
                            default: return null;
                        }
                    })}
                </DialogContent>
                <DialogActions>
                    <Button
                        color={'primary'}
                        onClick={this.onConfirm}
                        disabled={!this.canBeSubmitted()}
                    >{this.props.confirmText ? this.props.confirmText : 'Confirm'}</Button>

                    <Button
                        color={'secondary'}
                        onClick={this.onCancel}
                    >{this.props.cancelText ? this.props.cancelText : 'Cancel'}</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default FormDialog;
