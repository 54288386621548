import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import React from "react";

const styles = (theme: Theme) => (
    createStyles({
        categoryHeader: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
        categoryHeaderPrimary: {
            color: theme.palette.common.white,
        },
    })
);

export interface INavigatorCategoryHeaderProps extends WithStyles<typeof styles> {
    text: string;
}

function NavigatorCategoryHeader(props: INavigatorCategoryHeaderProps): JSX.Element {
    const {
        classes,
        text,
    } = props;

    return (
        <ListItem className={classes.categoryHeader}>
            <ListItemText
                classes={{
                    primary: classes.categoryHeaderPrimary,
                }}
            >
                {text}
            </ListItemText>
        </ListItem>
    );
}

export default withStyles(styles)(NavigatorCategoryHeader);
