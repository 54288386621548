import Axios, {AxiosRequestConfig} from 'axios';
import {IHttpService} from "./IHttp.service";
import {IConfig} from "../config/IConfig";
import {IAuthCheckResponseDto, IAuthLoginResponseDto} from "../models/IAuth";
import {IAuthTokenStore} from "../auth/IAuthTokenStore";
import {ICar, ICarDetails, ICarInsertModel} from "../models/ICar";
import {IMap} from "../models/IMap";
import {IEvent, IEventDetails, IEventEditModel, IEventInsertModel} from "../models/IEvent";
import {IPost, IPostCommentInsertModel, IPostEditModel, IPostInsertModel} from "../models/IPost";
import {ISuccessResponse} from "../models/ISuccessResponse";
import {IRoute, IRouteInsertModel} from "../models/IRoute";
import {IScheduleActivityType, IScheduleActivityTypeInsertModel} from "../models/IScheduleActivityType";
import {
    IScheduleDay,
    IScheduleDayActivityEditModel,
    IScheduleDayActivityInsertModel, IScheduleDayEditModel,
    IScheduleDayInsertModel
} from "../models/IScheduleDay";
import {IUsersActiveInLast5MinutesResponseDto} from "../models/IStats";
import {IWebMapToken} from "../models/IWebMapToken";
import {IAbuseReport} from "../models/IAbuseReport";
import {IAdmin, IAdminInsertModel, IAdminWithPassword} from "../models/IAdmin";
import {IUser, IUserDetails, IUserInsertModel, IUserLocation} from "../models/IUser";
import {ISms} from "../models/ISms";
import {IResourceCreatedResponse} from "../models/IResourceCreatedResponse";

export class HttpService implements IHttpService {
    constructor(
        private readonly _authTokenStore: IAuthTokenStore,
        private readonly _config: IConfig,
    ) { }

    public async adminsGet(): Promise<IAdmin[]> {
        return (await this.requestGet<{admins: IAdmin[]}>(
            this.requestBuildUrl('admins'),
        )).admins;
    }

    public async adminsDelete(adminId: string): Promise<ISuccessResponse> {
        return await this.requestDelete(
            this.requestBuildUrl(`admins/${adminId}`),
        );
    }

    public async adminsPost(payload: IAdminInsertModel): Promise<IResourceCreatedResponse> {
        return await this.requestPost<IAdminInsertModel, IResourceCreatedResponse>(
            this.requestBuildUrl('admins'),
            payload,
        );
    }

    public async adminsPut(adminId: string, editData: Partial<IAdminWithPassword>): Promise<boolean> {
        return (await this.requestPut<Partial<IAdminWithPassword>, ISuccessResponse>(
            this.requestBuildUrl(`admins/${adminId}`),
            editData,
        )).success;
    }

    public async authCheck(): Promise<IAuthCheckResponseDto> {
        return await this.requestGet(
            this.requestBuildUrl('auth'),
        );
    }

    public async authLogin(email: string, password: string): Promise<IAuthLoginResponseDto> {
        return await this.requestPost<{
            email: string;
            password: string;
        }, IAuthLoginResponseDto>(
            this.requestBuildUrl('auth'),
            {
                email,
                password,
            },
        );
    }

    public async eventsGet(): Promise<IEvent[]>;
    public async eventsGet(eventId: string): Promise<IEventDetails>;
    public async eventsGet(eventId?: string): Promise<IEvent[] | IEventDetails> {
        if (eventId) {
            return await this.requestGet(
                this.requestBuildUrl(`events/${eventId}`),
            );
        } else {
            return (await this.requestGet<{events: IEvent[]}>(
                this.requestBuildUrl('events'),
            )).events;
        }
    }

    public async eventsPost(payload: IEventInsertModel): Promise<IResourceCreatedResponse> {
        return await this.requestPost(
            this.requestBuildUrl('events'),
            payload,
        );
    }

    public async eventsPut(eventId: string, payload: IEventEditModel): Promise<boolean> {
        return (await this.requestPut<IEventEditModel, {success: boolean}>(
            this.requestBuildUrl(`events/${eventId}`),
            payload,
        )).success;
    }

    public async eventAbuseReportsGet(eventId: string): Promise<IAbuseReport[]> {
        return (await this.requestGet<{reports: IAbuseReport[]}>(
            this.requestBuildUrl(`events/${eventId}/abuseReports`),
        )).reports;
    }

    public async eventAbuseReportMarkReviewed(eventId: string, reportId: string): Promise<boolean> {
        return (await this.requestPut<{reviewed: boolean}, ISuccessResponse>(
            this.requestBuildUrl(`events/${eventId}/abuseReports/${reportId}`),
            {
                reviewed: true,
            },
        )).success;
    }

    public async eventPostsAddComment(eventId: string, postId: string, comment: string): Promise<boolean> {
        return (await this.requestPost<IPostCommentInsertModel, ISuccessResponse>(
            this.requestBuildUrl(`events/${eventId}/posts/${postId}/comments`),
            {
                message: comment,
                images: [],
            },
        )).success;
    }

    public async eventPostsCreatePost(eventId: string, postData: IPostInsertModel): Promise<IResourceCreatedResponse> {
        return await this.requestPost<IPostInsertModel, IResourceCreatedResponse>(
            this.requestBuildUrl(`events/${eventId}/posts`),
            postData,
        );
    }

    public async eventPostsDeletePost(eventId: string, postId: string): Promise<boolean> {
        return (await this.requestDelete<ISuccessResponse>(
            this.requestBuildUrl(`events/${eventId}/posts/${postId}`),
        )).success;
    }

    public async eventPostsDeletePostComment(eventId: string, postId: string, commentId: string): Promise<boolean> {
        return (await this.requestDelete<ISuccessResponse>(
            this.requestBuildUrl(`events/${eventId}/posts/${postId}/comments/${commentId}`),
        )).success;
    }

    public async eventPostsEditPost(eventId: string, postId: string, postData: IPostEditModel): Promise<boolean> {
        return (await this.requestPut<IPostEditModel, ISuccessResponse>(
            this.requestBuildUrl(`events/${eventId}/posts/${postId}`),
            postData,
        )).success;
    }

    public async eventPostsGetPost(eventId: string, postId: string): Promise<IPost> {
        return await this.requestGet<IPost>(
            this.requestBuildUrl(`events/${eventId}/posts/${postId}`),
        );
    }

    public async eventPostsGetPosts(eventId: string): Promise<IPost[]> {
        return (await this.requestGet<{posts: IPost[]}>(
            this.requestBuildUrl(`events/${eventId}/posts`),
        )).posts;
    }

    public async eventRoutesCreateRoute(eventId: string, routeData: IRouteInsertModel): Promise<IResourceCreatedResponse> {
        return await this.requestPost<IRouteInsertModel, IResourceCreatedResponse>(
            this.requestBuildUrl(`events/${eventId}/routes`),
            routeData,
        );
    }

    public async eventRoutesDeleteRoute(eventId: string, routeId: string): Promise<boolean> {
        return (await this.requestDelete<ISuccessResponse>(
            this.requestBuildUrl(`events/${eventId}/routes/${routeId}`),
        )).success;
    }

    public async eventRoutesEditRoute(eventId: string, routeId: string, routeData: IRouteInsertModel): Promise<ISuccessResponse> {
        return await this.requestPut<IRouteInsertModel, ISuccessResponse>(
            this.requestBuildUrl(`events/${eventId}/routes/${routeId}`),
            routeData,
        );
    }

    public async eventRoutesGetRoute(eventId: string, routeId: string): Promise<IRoute> {
        return await this.requestGet<IRoute>(
            this.requestBuildUrl(`events/${eventId}/routes/${routeId}`)
        );
    }

    public async eventRoutesGetRoutes(eventId: string): Promise<IRoute[]> {
        return (await this.requestGet<any>(
            this.requestBuildUrl(`events/${eventId}/routes`)
        )).routes;
    }

    public async eventScheduleAddDay(eventId: string, dayData: IScheduleDayInsertModel): Promise<boolean> {
        return (await this.requestPost<IScheduleDayInsertModel, ISuccessResponse>(
            this.requestBuildUrl(`events/${eventId}/schedule/days`),
            dayData,
        )).success;
    }

    public async eventScheduleEditDay(eventId: string, dayId: string, dayData: IScheduleDayEditModel): Promise<boolean> {
        return (await this.requestPut<IScheduleDayEditModel, ISuccessResponse>(
            this.requestBuildUrl(`events/${eventId}/schedule/days/${dayId}`),
            dayData,
        )).success;
    }

    public async eventScheduleRemoveDay(eventId: string, dayId: string): Promise<boolean> {
        return (await this.requestDelete<ISuccessResponse>(
            this.requestBuildUrl(`events/${eventId}/schedule/days/${dayId}`),
        )).success;
    }

    public async eventScheduleGetDays(eventId: string): Promise<IScheduleDay[]> {
        return (await this.requestGet<any>(
            this.requestBuildUrl(`events/${eventId}/schedule/days`),
        )).days;
    }

    public async eventScheduleAddDayActivity(eventId: string, dayId: string, activityData: IScheduleDayActivityInsertModel): Promise<boolean> {
        return (await this.requestPost<IScheduleDayActivityInsertModel, ISuccessResponse>(
            this.requestBuildUrl(`events/${eventId}/schedule/days/${dayId}/activity`),
            activityData,
        )).success;
    }

    public async eventScheduleRemoveDayActivity(eventId: string, dayId: string, activityId: string): Promise<boolean> {
        return (await this.requestDelete<ISuccessResponse>(
            this.requestBuildUrl(`events/${eventId}/schedule/days/${dayId}/activity/${activityId}`),
        )).success;
    }

    public async eventScheduleEditDayActivity(eventId: string, dayId: string, activityId: string, activityData: IScheduleDayActivityEditModel): Promise<boolean> {
        return (await this.requestPut<IScheduleDayActivityEditModel, ISuccessResponse>(
            this.requestBuildUrl(`events/${eventId}/schedule/days/${dayId}/activity/${activityId}`),
            activityData,
        )).success;
    }

    public async eventUsersDelete(eventId: string, userId: string): Promise<boolean> {
        return (await this.requestDelete<ISuccessResponse>(
            this.requestBuildUrl(`events/${eventId}/users/${userId}`)
        )).success;
    }

    public async eventUsersGet(eventId: string): Promise<IUser[]> {
        return (await this.requestGet<{users: IUser[]}>(
            this.requestBuildUrl(`events/${eventId}/users`),
        )).users;
    }

    public async eventCarsDelete(eventId: string, carId: string): Promise<void> {
        await this.requestDelete(
            this.requestBuildUrl(`events/${eventId}/cars/${carId}`),
        )
    }

    public async eventCarsGet(eventId: string): Promise<ICar[]> {
        return (await this.requestGet<{cars: ICar[]}>(
            this.requestBuildUrl(`events/${eventId}/cars`),
        )).cars;
    }

    public async eventCarsGetDetails(eventId: string, carId: string): Promise<ICarDetails> {
        return await this.requestGet(
            this.requestBuildUrl(`events/${eventId}/cars/${carId}`),
        )
    }

    public async eventCarsPost(eventId: string, carData: ICarInsertModel): Promise<IResourceCreatedResponse> {
        return await this.requestPost(
            this.requestBuildUrl(`events/${eventId}/cars`),
            carData,
        );
    }

    public async eventCarsPatch(eventId: string, carId: string, editData: Partial<ICarDetails>): Promise<ISuccessResponse> {
        return await this.requestPatch(
            this.requestBuildUrl(`events/${eventId}/cars/${carId}`),
            editData,
        );
    }

    public async eventUsersGetDetails(eventId: string, userId: string): Promise<IUserDetails> {
        return await this.requestGet(
            this.requestBuildUrl(`events/${eventId}/users/${userId}`),
        )
    }

    public async eventUsersGetLocations(eventId: string, userId: string, startTime: number, endTime: number): Promise<IUserLocation[]> {
        return (await this.requestGet<{locations: IUserLocation[]}>(
            this.requestBuildUrl(`events/${eventId}/users/${userId}/locations?startTime=${startTime}&endTime=${endTime}`),
        )).locations;
    }

    public async eventUsersPatch(eventId: string, userId: string, payload: Partial<IUserInsertModel>): Promise<void> {
        await this.requestPut<Partial<IUserInsertModel>, void>(
            this.requestBuildUrl(`events/${eventId}/users/${userId}`),
            payload,
        );
    }

    public async eventUsersPost(eventId: string, payload: IUserInsertModel): Promise<IResourceCreatedResponse> {
        return await this.requestPost<IUserInsertModel, IResourceCreatedResponse>(
            this.requestBuildUrl(`events/${eventId}/users`),
            payload,
        );
    }

    public async eventUsersRevokeAccess(eventId: string, userId: string): Promise<boolean> {
        return (await this.requestDelete<ISuccessResponse>(
            this.requestBuildUrl(`events/${eventId}/users/${userId}/access`),
        )).success;
    }

    public async eventUsersRevokeAccessAllUsers(eventId: string): Promise<boolean> {
        return (await this.requestDelete<ISuccessResponse>(
            this.requestBuildUrl(`events/${eventId}/usersAccess`),
        )).success;
    }

    public async eventUsersSendPushNotification(eventId: string, userId: string, notificationTitle: string, notificationBody: string): Promise<ISuccessResponse> {
        return await this.requestPost<any, ISuccessResponse>(
            this.requestBuildUrl(`events/${eventId}/users/${userId}/notification`),
            {
                title: notificationTitle,
                body: notificationBody,
            },
        );
    }

    public async eventWebMapAddToken(eventId: string, name: string): Promise<ISuccessResponse> {
        return await this.requestPost<any, ISuccessResponse>(
            this.requestBuildUrl(`events/${eventId}/webMapTokens`),
            {
                name: name,
            },
        );
    }

    public async eventWebMapGetTokens(eventId: string): Promise<IWebMapToken[]> {
        return (await this.requestGet<any>(
            this.requestBuildUrl(`events/${eventId}/webMapTokens`),
        )).tokens;
    }

    public async eventMap(eventId: string): Promise<IMap> {
        const result = await this.requestGet<{mapData: IMap}>(
            this.requestBuildUrl(`events/${eventId}/map`),
        );

        return result.mapData;
    }

    public async fileGet(fileId: string): Promise<any> {
        return await Axios.get(
            this.requestBuildUrl(`files/${fileId}`),
            {
                headers: {
                    ...this.requestOptionsBuildHeaders(),
                },
                responseType: 'arraybuffer',
            },
        );
    }

    public async fileSend(formData: FormData, targetWidth: number | null): Promise<string> {
        const targetWidthSuffix: string = targetWidth ? `?targetWidth=${targetWidth}` : '';

        const result = await Axios.post(
            this.requestBuildUrl('files' + targetWidthSuffix),
            formData,
            {
                headers: {
                    ...this.requestOptionsBuildHeaders(),
                    'Content-Type': 'multipart/form-data',
                },
            },
        );

        return result.data.fileId;
    }

    public async pushNotificationSend(title: string, body: string, usersIds: string[]): Promise<[boolean, number, number]> {
        const result = await this.requestPost<any, any>(
            this.requestBuildUrl(`pushNotifications`),
            {
                title: title,
                body: body,
                usersIds: usersIds,
            },
        );

        return [
            result.success,
            result.successCount,
            result.failureCount,
        ];
    }

    public async settingsAddActivityType(typeData: IScheduleActivityTypeInsertModel): Promise<boolean> {
        return (await this.requestPost<IScheduleActivityTypeInsertModel, ISuccessResponse>(
            this.requestBuildUrl(`settings/activityTypes`),
            typeData,
        )).success;
    }

    public async settingsDeleteActivityType(typeId: string): Promise<boolean> {
        return (await this.requestDelete<ISuccessResponse>(
            this.requestBuildUrl(`settings/activityTypes/${typeId}`)
        )).success;
    }

    public async settingsGetActivityTypes(): Promise<IScheduleActivityType[]> {
        return (await this.requestGet<any>(
            this.requestBuildUrl('settings/activityTypes')
        )).types;
    }

    public async settingsEulaGet(): Promise<string> {
        return (await this.requestGet<any>(
            this.requestBuildUrl('settings/eula')
        )).eula;
    }

    public async settingsEulaSet(eula: string): Promise<boolean> {
        return (await this.requestPut<{eula: string}, ISuccessResponse>(
            this.requestBuildUrl(`settings/eula`),
            {
                eula: eula,
            },
        )).success;
    }

    public async smsSendSingle(phoneNumber: string, smsText: string): Promise<boolean> {
        return (await this.requestPost<ISms, ISuccessResponse>(
            this.requestBuildUrl('sms'),
            {
                to: phoneNumber,
                body: smsText,
            },
        )).success;
    }

    public async statsGetUsersActiveInLast5Minutes(): Promise<IUsersActiveInLast5MinutesResponseDto> {
        return await this.requestGet<IUsersActiveInLast5MinutesResponseDto>(
            this.requestBuildUrl('stats/usersActiveInLast5Minutes'),
        )
    }

    public async s3uploadCreate(): Promise<any> {
        return await this.requestPost(
            this.requestBuildUrl('files/s3upload'),
            {},
        );
    }

    public async s3uploadCommit(fileId: string): Promise<any> {
        return await this.requestPut(
            this.requestBuildUrl(`files/s3upload/${fileId}`),
            {},
        );
    }

    private async requestDelete<Response>(endpoint: string): Promise<Response> {
        const result = await Axios.delete<Response>(
            endpoint,
            this.requestOptionsBuild(),
        );
        
        return result.data;
    }

    private async requestGet<Response>(endpoint: string): Promise<Response> {
        const result = await Axios.get<Response>(
            endpoint,
            this.requestOptionsBuild(),
        );

        return result.data;
    }

    private async requestPatch<RequestBody, Response>(endpoint: string, body: RequestBody): Promise<Response> {
        const result = await Axios.patch<Response>(
            endpoint,
            body,
            this.requestOptionsBuild(),
        );

        return result.data;
    }

    private async requestPost<RequestBody, Response>(endpoint: string, body: RequestBody): Promise<Response> {
        const result = await Axios.post<Response>(
            endpoint,
            body,
            this.requestOptionsBuild(),
        );

        return result.data;
    }

    private async requestPut<RequestBody, Response>(endpoint: string, body: RequestBody): Promise<Response> {
        const result = await Axios.put<Response>(
            endpoint,
            body,
            this.requestOptionsBuild(),
        );

        return result.data;
    }
    
    private requestBuildUrl(endpoint: string): string {
        return `${this._config.apiUrl}/admin/${endpoint}`;
    }
    
    private requestOptionsBuild(): AxiosRequestConfig {
        return {
            headers: this.requestOptionsBuildHeaders()
        };
    }

    private requestOptionsBuildHeaders(): {[key: string]: string} {
        const token: string | undefined = this._authTokenStore.getToken();

        if (!token) {
            return {};
        }

        return {
            Authorization: `Bearer ${token}`,
        };
    }
}
