import React from 'react';
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";
import {IAbuseReport} from "../../../../core/models/IAbuseReport";
import {Button, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";

interface IEventAbuseReportsTableProps extends ICoreContainerRequired {
    label: string;
    onMarkReviewedClick: (report: IAbuseReport) => any;
    reports: IAbuseReport[];
    resolveUserName: (userId: string) => string | null;
}

interface IEventAbuseReportsTableState {

}

class EventAbuseReportsTable extends React.Component<IEventAbuseReportsTableProps, IEventAbuseReportsTableState> {
    extractAbusiveContent = (report: IAbuseReport) => {
        if (report.postCommentId) {
            if (!report.postCommentPresent) {
                return `COMMENT DELETED`;
            }

            return report.postCommentMessage;
        } else {
            if (!report.postPresent) {
                return `POST DELETED`
            }

            return report.postMessage;
        }
    };

    render() {
        return (
            <React.Fragment>
                <Typography>{this.props.label}</Typography>
                <Table size={'small'} style={{marginBottom: 20}}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{width: 100}}>Type</TableCell>
                            <TableCell style={{width: 250}}>Reporter</TableCell>
                            <TableCell style={{width: 250}}>Reason</TableCell>
                            <TableCell>Abusive content</TableCell>
                            <TableCell style={{width: 250}}>Reviewed By</TableCell>
                            <TableCell style={{width: 140}}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.reports.map((report) => (
                            <TableRow key={report.reportId}>
                                <TableCell>{report.postCommentId ? 'Comment' : 'Post'}</TableCell>
                                <TableCell>{this.props.resolveUserName(report.reportingUserId)}</TableCell>
                                <TableCell>{report.reason}</TableCell>
                                <TableCell>{this.extractAbusiveContent(report)}</TableCell>
                                <TableCell>
                                    {report.reviewedBy ? report.reviewedBy : 'Non Reviewed'}
                                </TableCell>
                                <TableCell style={{padding: 8}}>
                                    {!report.reviewedBy ? (
                                        <Button
                                            color={'primary'}
                                            size={'small'}
                                            variant={'outlined'}
                                            onClick={() => this.props.onMarkReviewedClick(report)}
                                        >Mark Reviewed</Button>
                                    ) : null}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </React.Fragment>
        );
    }
}

export default EventAbuseReportsTable;
