import React from 'react';
import {ICoreContainerRequired} from "../../core/ICoreContainer";
import {v1 as uuidV1} from "uuid";
import {Button, ButtonProps} from "@material-ui/core";

interface IFileUploadButtonProps extends ICoreContainerRequired, ButtonProps {
    onUpload: (fileId: string) => any;
    targetWidth?: number;
}

class FileUploadButton extends React.Component<IFileUploadButtonProps> {
    private readonly _inputId: string;

    constructor(props: IFileUploadButtonProps) {
        super(props);
        this._inputId = uuidV1();
    }

    onButtonClick = () => {
        const input = document.getElementById(this._inputId);
        if (input) {
            input.click();
        }
    };

    onFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target : { files } } = event;

        if (!files || files.length !== 1) {
            return;
        }

        const file = files[0];

        if (!file) {
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        const fileId: string | null = await this.props.coreContainer.IFilesService.uploadFile(file, this.props.targetWidth);

        if (fileId) {
            this.props.onUpload(fileId);
        }
    };

    render() {
        const {
            onUpload,
            coreContainer,
            targetWidth,
            children,
            ...buttonProps
        } = this.props;

        return (
            <Button
                {...buttonProps}
                onClick={this.onButtonClick}
            >
                <input
                    id={this._inputId}
                    type={'file'}
                    style={{
                        display: 'none',
                    }}
                    onChange={this.onFileSelect}
                />
                {children}
            </Button>
        );
    }
}

export default FileUploadButton;
