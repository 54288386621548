import React from 'react';
import {createStyles, Grid, ListItem, TextField, Theme, WithStyles, withStyles} from "@material-ui/core";
import moment from "moment";

const styles = (theme: Theme) => createStyles({

});

interface IEventScheduleDayActivityTimeSelectProps extends WithStyles<typeof styles> {
    dayDate: string;
    value: string | undefined;
    onChange: (time: number) => any;
    hourLabel: string;
    minuteLabel: string;
}

const EventScheduleDayActivityTimeSelect = (props: IEventScheduleDayActivityTimeSelectProps) => {
    const valueMoment = moment(props.value);
    const hour: number = Number(valueMoment.format('HH'));
    const minute: number = Number(valueMoment.format('mm'));

    const displayValueAsString = (value: number | string) => !value
        ? '00'
        : value.toString().length !== 2 ? `0${value}` : value;

    const onHourChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(moment(`${props.dayDate}T${displayValueAsString(event.target.value)}:${displayValueAsString(minute)}`).valueOf())
    };

    const onMinuteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(moment(`${props.dayDate}T${displayValueAsString(hour)}:${displayValueAsString(event.target.value)}`).valueOf());
    };

    return (
        <Grid
            container
            item
        >
            <Grid item xs={6}>
                <TextField
                    select
                    fullWidth
                    label={props.hourLabel}
                    value={hour}
                    onChange={onHourChange}
                >
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map((value) => {
                        return (
                            <ListItem value={value} key={value}>
                                {displayValueAsString(value)}
                            </ListItem>
                        );
                    })}
                </TextField>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    select
                    fullWidth
                    label={props.minuteLabel}
                    value={minute}
                    onChange={onMinuteChange}
                >
                    {[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55].map((value) => {
                        return (
                            <ListItem value={value} key={value}>
                                {displayValueAsString(value)}
                            </ListItem>
                        );
                    })}
                </TextField>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(EventScheduleDayActivityTimeSelect);
