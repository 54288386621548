import React from 'react';
import {
    Button,
    ButtonGroup,
    createStyles,
    Grid,
    TextField,
    Theme,
    WithStyles,
    withStyles
} from "@material-ui/core";
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";

const styles = (theme: Theme) => createStyles({

});

interface IEulaConfigProps extends WithStyles<typeof styles>, ICoreContainerRequired {

}

interface IEulaConfigState {
    editable: boolean;
    eula: string;
    initialized: boolean;
}

class EulaConfig extends React.Component<IEulaConfigProps, IEulaConfigState> {
    state: IEulaConfigState = {
        editable: false,
        eula: '',
        initialized: false,
    };

    componentDidMount(): void {
        this.fetchEula();
    }

    fetchEula = async () => {
        this.setState({
            editable: false,
            eula: await this.props.coreContainer.IHttpService.settingsEulaGet(),
            initialized: true,
        });
    };

    toggleEditing = () => this.setState({
        editable: !this.state.editable,
    });

    onEulaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => this.setState({
        eula: event.currentTarget.value,
    });

    saveEula = async () => {
        await this.props.coreContainer.IHttpService.settingsEulaSet(this.state.eula);
        this.fetchEula();
    };

    render() {
        if (!this.state.initialized) {
            return null;
        }

        return (
            <Grid container>
                <Grid item xs={12}>
                    {!this.state.editable ? (
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            onClick={this.toggleEditing}
                        >Edit</Button>
                    ) : (
                        <ButtonGroup>
                            <Button
                                variant={'outlined'}
                                color={'primary'}
                                onClick={this.saveEula}
                            >Save</Button>
                            <Button
                                variant={'outlined'}
                                color={'secondary'}
                                onClick={this.fetchEula}
                            >Cancel</Button>
                        </ButtonGroup>
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        value={this.state.eula}
                        fullWidth
                        multiline
                        rows={20}
                        onChange={this.onEulaChange}
                        disabled={!this.state.editable}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(EulaConfig);
