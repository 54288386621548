import React from 'react';
import {Button} from "@material-ui/core";
import {ICoreContainerRequired} from "../../../../../core/ICoreContainer";
import ImportCarsDialog from "./ImportCarsDialog";
import {ICar} from "../../../../../core/models/ICar";

interface IImportCarsButtonProps extends ICoreContainerRequired {
    eventCars: ICar[];
    eventId: string;
    fetchCars: () => any;
}

interface IImportCarsButtonState {
    opened: boolean;
}

class ImportCarsButton extends React.Component<IImportCarsButtonProps, IImportCarsButtonState> {
    state: IImportCarsButtonState = {
        opened: false,
    };

    onToggleRequest = () => this.setState({
        opened: !this.state.opened,
    }, this.props.fetchCars);

    render() {
        if (!this.state.opened) {
            return (
                <Button
                    size={'large'}
                    onClick={this.onToggleRequest}
                    variant={'outlined'}
                    color={'primary'}
                >+ Add Cars from CSV</Button>
            );
        }

        return (
            <ImportCarsDialog
                eventCars={this.props.eventCars}
                eventId={this.props.eventId}
                onCloseRequest={this.onToggleRequest}
                coreContainer={this.props.coreContainer}
            />
        );
    }
}

export default ImportCarsButton;
