import React from 'react';
import {createStyles, Paper, Theme, withStyles, WithStyles} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const styles = (theme: Theme) => createStyles({
    secondaryBar: {
        zIndex: 0,
    },
    main: {
        flex: 1,
        padding: theme.spacing(2, 2),
        background: '#eaeff1',
    },
    paper: {
        margin: 'auto',
        overflow: 'hidden',
    },
    contentWrapper: {
        margin: '16px 16px',
    },
});

interface ISubModuleProps extends WithStyles<typeof styles> {
    title: any;
    subModules: Array<{
        label: string;
        component?: any;
        action?: () => void;
        key?: string;
        backgroundTransparent?: boolean;
    }>;
    currentKey?: string;
    onTabClick?: (tabKey: string) => any;
}

interface ISubModuleState {
    activeTabIndex: number;
}

class SubModule extends React.Component<ISubModuleProps, ISubModuleState> {
    state: ISubModuleState = {
        activeTabIndex: 0,
    };

    constructor(props: ISubModuleProps) {
        super(props);

        for (let i = 0; i < props.subModules.length; i++) {
            if (props.subModules[i].component) {
                this.state.activeTabIndex = i;
                break;
            }
        }

        if (props.currentKey) {
            for (let i = 0; i < props.subModules.length; i++) {
                if (props.subModules[i].key === props.currentKey) {
                    this.state.activeTabIndex = i;
                    break;
                }
            }
        }
    }

    shouldComponentUpdate(nextProps: Readonly<ISubModuleProps>, nextState: Readonly<ISubModuleState>, nextContext: any): boolean {
        if (!nextProps.currentKey) {
            return true;
        }

        const newTabIndex = this.checkTabIndex(nextProps.currentKey);
        if (newTabIndex === this.state.activeTabIndex) {
            return true;
        }

        this.setState({
            activeTabIndex: newTabIndex,
        });

        return false;
    }

    checkTabIndex = (moduleKey: string): number => {
        for (let index = 0; index < this.props.subModules.length; index++) {
            if (this.props.subModules[index].key === moduleKey) {
                return index;
            }
        }

        return 0;
    };

    onTabClick = (tabIndex: number): void => {
        const tabDetails = this.props.subModules[tabIndex];

        if (tabDetails.component) {
            this.setState({
                activeTabIndex: tabIndex,
            });
        }

        if (tabDetails.action) {
            tabDetails.action();
        }

        if (this.props.onTabClick && tabDetails.key) {
            this.props.onTabClick(tabDetails.key);
        }
    };

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <AppBar
                    component="div"
                    className={classes.secondaryBar}
                    color="primary"
                    position="static"
                    elevation={0}
                >
                    <Toolbar>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item xs>
                                <Typography color="inherit" variant="h5" component="h2">
                                    {this.props.title}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>

                <AppBar
                    component="div"
                    className={classes.secondaryBar}
                    color="primary"
                    position="static"
                    elevation={0}
                >
                    <Tabs value={this.state.activeTabIndex + 1} textColor="inherit">
                        {this.props.subModules.map((subModule, index) => (
                            <Tab
                                key={`index_${index}`}
                                value={index + 1}
                                textColor="inherit"
                                label={subModule.label}
                                onClick={() => this.onTabClick(index)}
                            />
                        ))}
                    </Tabs>
                </AppBar>

                <main className={classes.main}>
                    <Paper
                        className={classes.paper}
                        style={{
                            ...(this.props.subModules[this.state.activeTabIndex].backgroundTransparent ? {
                                backgroundColor: 'transparent',
                                boxShadow: '0 0 0'
                            } : {})
                        }}
                    >
                        <div className={classes.contentWrapper}>
                            {this.props.subModules[this.state.activeTabIndex].component}
                        </div>
                    </Paper>
                </main>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(SubModule);
