import React from 'react';
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";
import {
    Button, ButtonGroup,
    createStyles,
    Grid, IconButton,
    MenuItem,
    TextField,
    Theme,
    withStyles,
    WithStyles
} from "@material-ui/core";
import {
    IScheduleDay,
    IScheduleDayActivity,
    IScheduleDayActivityInsertModel
} from "../../../../core/models/IScheduleDay";
import {IScheduleActivityType} from "../../../../core/models/IScheduleActivityType";
import moment from "moment";
import Image from "../../../usages/Image";
// import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
// import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import EventScheduleDayActivityTimeSelect from "./EventScheduleDayActivityTimeSelect";

const styles = (theme: Theme) => createStyles({
    inputGridItem: {
        padding: 3,
        marginTop: 10,
    },
    timeInputGridItem: {
        marginTop: 7,
    },
});

interface IEventScheduleDayActivityProps extends ICoreContainerRequired, WithStyles<typeof styles> {
    eventId: string;
    day: IScheduleDay;
    activity: Partial<IScheduleDayActivity>;
    fetchDays: () => any;
    newActivity: boolean;
    activityTypes: IScheduleActivityType[];
}

interface IEventScheduleDayActivityState {
    activity: IScheduleDayActivity;
    opened: boolean;
    changed: boolean;
    editable: boolean;
}

class EventScheduleDayActivity extends React.Component<IEventScheduleDayActivityProps, IEventScheduleDayActivityState> {
    private readonly emptyActivityData: IScheduleDayActivity = {
        activityId: '',
        googleMapUrl: '',
        name: '',
        description: '',
        typeId: '',
        timeStart: 0,
        timeEnd: 0,
    };

    state: IEventScheduleDayActivityState = {
        opened: true,
        activity: {
            ...this.emptyActivityData,
        },
        changed: false,
        editable: true,
    };

    constructor(props: IEventScheduleDayActivityProps) {
        super(props);

        this.state.opened = !props.newActivity;

        this.state.activity = {
            ...this.state.activity,
            ...props.activity,
        };
    }

    onActivityChange = (data: Partial<IScheduleDayActivity>) => this.setState({
        activity: {
            ...this.state.activity,
            ...data,
        },
        changed: true,
    });

    onTypeChange = (event: React.ChangeEvent<any>) => this.onActivityChange({
        typeId: event.target.value,
    });

    resolveTypeImage = () => {
        const type = this.props.activityTypes.find((type) => type.typeId === this.state.activity.typeId);

        if (!type) {
            return '';
        }

        return type.icon;
    };

    activityIsValid = (): boolean => {
        return (
            !!this.state.activity.typeId &&
            !!this.state.activity.timeStart &&
            !!this.state.activity.timeEnd
        );
    };

    addActivity = async () => {
        const activityInsertData: IScheduleDayActivityInsertModel = {
            googleMapUrl: this.state.activity.googleMapUrl,
            name: this.state.activity.name,
            description: this.state.activity.description,
            typeId: this.state.activity.typeId,
            timeStart: this.state.activity.timeStart,
            timeEnd: this.state.activity.timeEnd,
        };

        await this.props.coreContainer.IHttpService.eventScheduleAddDayActivity(
            this.props.eventId,
            this.props.day.dayId,
            activityInsertData,
        );

        this.setState({
            activity: {
                ...this.emptyActivityData,
            },
        }, () => {
            this.props.fetchDays();
        });
    };

    toggleEditing = () => this.setState({
        editable: !this.state.editable,
    });

    removeActivity = async () => {
        if (!(await this.props.coreContainer.IDialogsController.confirm('Are you sure you want delete this activity?'))) {
            return;
        }

        await this.props.coreContainer.IHttpService.eventScheduleRemoveDayActivity(
            this.props.eventId,
            this.props.day.dayId,
            this.state.activity.activityId,
        );

        this.props.fetchDays();
    };

    saveChanges = async () => {
        if (!this.activityIsValid()) {
            return;
        }

        await this.props.coreContainer.IHttpService.eventScheduleEditDayActivity(
            this.props.eventId,
            this.props.day.dayId,
            this.state.activity.activityId,
            {
                name: this.state.activity.name,
                description: this.state.activity.description,
                googleMapUrl: this.state.activity.googleMapUrl,
                timeStart: this.state.activity.timeStart,
                timeEnd: this.state.activity.timeEnd,
                typeId: this.state.activity.typeId,
            },
        );

        this.setState({
            editable: true,
            changed: false,
        }, () => {
            this.props.fetchDays();
        });
    };

    abortChanges = () => this.setState({
        activity: {
            ...this.state.activity,
            ...this.props.activity,
        },
        editable: true,
        changed: false,
    });

    render() {
        return (
            <Grid
                container
                item
                xs={12}
                style={{
                    border: '1px solid #eaeff1',
                    borderRadius: 16,
                    marginBottom: 20,
                    padding: 10,
                    boxShadow: '1px 1px 3px 0px #eaeff1'
                }}
                alignItems={'center'}
            >
                {this.state.opened ? (
                    <React.Fragment>
                        <Grid item xs={12} md={1} style={{padding: 2, textAlign: 'center'}}>
                            {this.state.activity.typeId && this.state.activity.typeId !== 'NONE' ? (
                                <Image
                                    key={this.resolveTypeImage()}
                                    imageId={this.resolveTypeImage()}
                                    coreContainer={this.props.coreContainer}
                                    style={{
                                        width: '50%',
                                    }}
                                />
                            ) : null}
                        </Grid>
                        <Grid container item xs={12} md={11}>
                            <Grid item xs={6} md={2} className={this.props.classes.inputGridItem}>
                                <TextField
                                    value={this.state.activity.typeId ? this.state.activity.typeId : 'NONE'}
                                    onChange={this.onTypeChange}
                                    fullWidth
                                    disabled={!(this.props.newActivity || this.state.editable)}
                                    label={'Icon'}
                                    select
                                    size={'small'}
                                >
                                    {this.props.newActivity ? (
                                        <MenuItem value={'NONE'}>- Select Icon -</MenuItem>
                                    ) : null}
                                    {this.props.activityTypes.map((type) => (
                                        <MenuItem
                                            key={type.typeId}
                                            value={type.typeId}
                                        >{type.name}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={6} md={4} className={this.props.classes.inputGridItem}>
                                <TextField
                                    value={this.state.activity.name}
                                    onChange={event => this.onActivityChange({name: event.currentTarget.value})}
                                    label={'Name'}
                                    fullWidth
                                    disabled={!(this.props.newActivity || this.state.editable)}
                                    size={'small'}
                                />
                            </Grid>
                            <Grid item xs={6} md={3} className={`${this.props.classes.inputGridItem} ${this.props.classes.timeInputGridItem}`}>
                                <EventScheduleDayActivityTimeSelect
                                    dayDate={this.props.day.date}
                                    value={this.state.activity.timeStart ? moment(this.state.activity.timeStart).format('YYYY-MM-DDTHH:mm') : undefined}
                                    onChange={timeStart => this.onActivityChange({timeStart})}
                                    hourLabel={'Hour from'}
                                    minuteLabel={'Minute from'}
                                />
                            </Grid>
                            <Grid item xs={6} md={3} className={`${this.props.classes.inputGridItem} ${this.props.classes.timeInputGridItem}`}>
                                <EventScheduleDayActivityTimeSelect
                                    dayDate={this.props.day.date}
                                    value={this.state.activity.timeEnd ? moment(this.state.activity.timeEnd).format('YYYY-MM-DDTHH:mm') : undefined}
                                    onChange={timeEnd => this.onActivityChange({timeEnd})}
                                    hourLabel={'Hour to'}
                                    minuteLabel={'Minute to'}
                                />
                            </Grid>
                            <Grid item xs={12} className={this.props.classes.inputGridItem}>
                                <TextField
                                    value={this.state.activity.description}
                                    fullWidth
                                    label={'Description'}
                                    multiline
                                    rows={5}
                                    onChange={event => this.onActivityChange({description: event.currentTarget.value})}
                                    disabled={!(this.props.newActivity || this.state.editable)}
                                    size={'small'}
                                />
                            </Grid>
                            <Grid item xs={12} className={this.props.classes.inputGridItem}>
                                <TextField
                                    value={this.state.activity.googleMapUrl}
                                    fullWidth
                                    label={'Google Maps URL'}
                                    onChange={event => this.onActivityChange({googleMapUrl: event.currentTarget.value})}
                                    disabled={!(this.props.newActivity || this.state.editable)}
                                    size={'small'}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} justify={'flex-end'}>
                            {this.props.newActivity ? (
                                <ButtonGroup
                                    style={{
                                        marginTop: 10,
                                    }}
                                >
                                    <Button
                                        variant={'outlined'}
                                        color={'primary'}
                                        disabled={!this.activityIsValid()}
                                        onClick={this.addActivity}
                                    >Add Activity</Button>
                                    <Button
                                        variant={'outlined'}
                                        color={'secondary'}
                                        onClick={() => this.setState({opened: false})}
                                    >Cancel</Button>
                                </ButtonGroup>
                            ) : (
                                <ButtonGroup
                                    style={{
                                        marginTop: 10,
                                    }}
                                >
                                    <IconButton
                                        key={'save'}
                                        color={'primary'}
                                        onClick={this.saveChanges}
                                        disabled={!this.state.changed || !this.activityIsValid()}
                                    >
                                        <SaveIcon />
                                    </IconButton>
                                    <IconButton
                                        key={'remove'}
                                        color={'secondary'}
                                        onClick={this.removeActivity}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </ButtonGroup>
                            )}
                        </Grid>
                    </React.Fragment>
                ) : (
                    <Button
                        variant={'outlined'}
                        color={'primary'}
                        size={'small'}
                        onClick={() => this.setState({opened: true})}
                    >Add new Activity</Button>
                )}
            </Grid>
        );
    }
}

export default withStyles(styles)(EventScheduleDayActivity);
