import React from 'react';
import {
    Checkbox, Chip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField
} from "@material-ui/core";
import {IUser} from "../../../../core/models/IUser";
import moment from "moment";

enum LastActivityFilterModesEnum {
    all,
    withActivity,
    withoutActivity,
}

const LastActivityFilterChip = (props: {
    mode: LastActivityFilterModesEnum;
    onClick: (newState: LastActivityFilterModesEnum) => any;
}) => {
    return (
        <Chip
            variant={props.mode === LastActivityFilterModesEnum.all ? 'outlined' : 'default'}
            color={'primary'}
            label={(() => {
                switch (props.mode) {
                    case LastActivityFilterModesEnum.all: return 'All';
                    case LastActivityFilterModesEnum.withActivity: return 'With Activity';
                    case LastActivityFilterModesEnum.withoutActivity: return 'Without Activity';
                }
            })()}
            clickable
            size={'small'}
            onClick={() => {
                switch (props.mode) {
                    case LastActivityFilterModesEnum.all: return props.onClick(LastActivityFilterModesEnum.withActivity);
                    case LastActivityFilterModesEnum.withActivity: return props.onClick(LastActivityFilterModesEnum.withoutActivity);
                    case LastActivityFilterModesEnum.withoutActivity: return props.onClick(LastActivityFilterModesEnum.all);
                }
            }}
        />
    );
};

enum LoggedStatusFilterModesEnum {
    all,
    logged,
    loggedOut,
}

const LoggedStatusFilterChip = (props: {
    mode: LoggedStatusFilterModesEnum;
    onClick: (newState: LoggedStatusFilterModesEnum) => any;
}) => {
    return (
        <Chip
            variant={props.mode === LoggedStatusFilterModesEnum.all ? 'outlined' : 'default'}
            color={'primary'}
            label={(() => {
                switch (props.mode) {
                    case LoggedStatusFilterModesEnum.all: return 'All';
                    case LoggedStatusFilterModesEnum.logged: return 'Logged In';
                    case LoggedStatusFilterModesEnum.loggedOut: return 'Logged Out';
                }
            })()}
            clickable
            size={'small'}
            onClick={() => {
                switch (props.mode) {
                    case LoggedStatusFilterModesEnum.all: return props.onClick(LoggedStatusFilterModesEnum.logged);
                    case LoggedStatusFilterModesEnum.logged: return props.onClick(LoggedStatusFilterModesEnum.loggedOut);
                    case LoggedStatusFilterModesEnum.loggedOut: return props.onClick(LoggedStatusFilterModesEnum.all);
                }
            }}
        />
    );
};

enum UserTypeFilterModesEnum {
    all,
    admins,
    users,
}

// const UserTypeFilterChip = (props: {
//     mode: UserTypeFilterModesEnum;
//     onClick: (newState: UserTypeFilterModesEnum) => any;
// }) => {
//     return (
//         <Chip
//             variant={props.mode === UserTypeFilterModesEnum.all ? 'outlined' : 'default'}
//             color={'primary'}
//             label={(() => {
//                 switch (props.mode) {
//                     case UserTypeFilterModesEnum.all: return 'All';
//                     case UserTypeFilterModesEnum.admins: return 'Admins';
//                     case UserTypeFilterModesEnum.users: return 'Users';
//                 }
//             })()}
//             clickable
//             size={'small'}
//             onClick={() => {
//                 switch (props.mode) {
//                     case UserTypeFilterModesEnum.all: return props.onClick(UserTypeFilterModesEnum.admins);
//                     case UserTypeFilterModesEnum.admins: return props.onClick(UserTypeFilterModesEnum.users);
//                     case UserTypeFilterModesEnum.users: return props.onClick(UserTypeFilterModesEnum.all);
//                 }
//             }}
//         />
//     );
// };


export interface IEventDetailsUsersTableUser extends IUser {
    selected: boolean;
}

interface IEventDetailsUsersTableProps {
    users: IEventDetailsUsersTableUser[];
    onUserClick: (user: IUser) => any;
    onUserSelectToggle: (user: IUser) => any;
    onAllUsersSelectToggle: (usersIds: string[]) => any;
    onPageChange: () => any;
    resolveCarName: (carId: string | null) => string;
}

interface IEventDetailsUsersTableState {
    lastActivityFilterMode: LastActivityFilterModesEnum;
    loggedStatusFilterMode: LoggedStatusFilterModesEnum;
    page: number;
    rowsPerPage: number;
    searchPhrase: string;
    sortDirection: 'asc' | 'desc';
    sortField: keyof IEventDetailsUsersTableUser;
    userTypeFilterMode: UserTypeFilterModesEnum;
}

class EventDetailsUsersTable extends React.Component<IEventDetailsUsersTableProps, IEventDetailsUsersTableState> {
    state: IEventDetailsUsersTableState = {
        lastActivityFilterMode: LastActivityFilterModesEnum.all,
        loggedStatusFilterMode: LoggedStatusFilterModesEnum.all,
        page: 0,
        rowsPerPage: 100000,
        searchPhrase: '',
        sortDirection: 'asc',
        sortField: 'name',
        userTypeFilterMode: UserTypeFilterModesEnum.all,
    };

    onAllUsersSelectToggle = () => this.props.onAllUsersSelectToggle(
        this.getUsersToDisplay().map((user) => user.userId)
    );

    onSearchPhraseChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
        searchPhrase: event.currentTarget.value,
    });

    getSortLabelActive = (field: keyof IEventDetailsUsersTableUser): boolean => field === this.state.sortField;

    getSortLabelDirection = (field: keyof IEventDetailsUsersTableUser): 'asc' | 'desc' => this.getSortLabelActive(field) ? this.state.sortDirection : 'asc';

    onSortLabelClick = (field: keyof IEventDetailsUsersTableUser) => () => this.getSortLabelActive(field)
        ? this.setState({
            sortDirection: this.state.sortDirection === 'asc' ? 'desc' : 'asc',
        })
        : this.setState({
            sortDirection: 'asc',
            sortField: field,
        });

    onChangePage = (event: any, page: number) => this.setState({
        page,
    }, () => this.props.onPageChange());

    onChangeRowsPerPage = (event: any) => this.setState({
        page: 0,
        rowsPerPage: event.target.value,
    });

    getUsersFiltered = () => [...this.props.users]
        .filter((user) => this.state.searchPhrase ? (
            user.name.toLowerCase().includes(this.state.searchPhrase) ||
            user.email.toLowerCase().includes(this.state.searchPhrase)
        ) : true)
        .filter((user) => {
            if (this.state.lastActivityFilterMode !== LastActivityFilterModesEnum.all) {
                if (this.state.lastActivityFilterMode === LastActivityFilterModesEnum.withActivity) {
                    return !!user.lastActivity;
                }

                if (this.state.lastActivityFilterMode === LastActivityFilterModesEnum.withoutActivity) {
                    return !user.lastActivity;
                }
            }

            return true;
        })
        .filter((user) => {
            if (this.state.loggedStatusFilterMode !== LoggedStatusFilterModesEnum.all) {
                if (this.state.loggedStatusFilterMode === LoggedStatusFilterModesEnum.logged) {
                    return user.logged === true;
                }

                if (this.state.loggedStatusFilterMode === LoggedStatusFilterModesEnum.loggedOut) {
                    return user.logged === false;
                }
            }

            return true;
        })
        .filter((user) => {
            if (this.state.userTypeFilterMode !== UserTypeFilterModesEnum.all) {
                if (this.state.userTypeFilterMode === UserTypeFilterModesEnum.admins) {
                    return user.isAdmin === true;
                }

                if (this.state.userTypeFilterMode === UserTypeFilterModesEnum.users) {
                    return user.isAdmin === false;
                }
            }

            return true;
        });

    getUsersSorted = (): IEventDetailsUsersTableUser[] => this.getUsersFiltered()
        .sort((left: IEventDetailsUsersTableUser, right: IEventDetailsUsersTableUser) => {
            if (this.state.sortField === 'carId') {
                let leftValue = left[this.state.sortField];
                let rightValue = right[this.state.sortField];
                leftValue = typeof leftValue === 'string' ? this.props.resolveCarName(leftValue as string) : '';
                rightValue = typeof rightValue === 'string' ? this.props.resolveCarName(rightValue as string) : '';
                return this.state.sortDirection === 'asc'
                    ? leftValue > rightValue ? 1 : -1
                    : leftValue < rightValue ? 1 : -1;
            }

            const leftValue = left[this.state.sortField];
            const rightValue = right[this.state.sortField];
            return this.state.sortDirection === 'asc'
                ? leftValue > rightValue ? 1 : -1
                : leftValue < rightValue ? 1 : -1
        });

    getUsersToDisplay = (): IEventDetailsUsersTableUser[] => {
        const usersFiltered = this
            .getUsersSorted()
            .splice(this.state.page * this.state.rowsPerPage)
            .filter((user) => this.state.searchPhrase ? (
                user.name.toLowerCase().includes(this.state.searchPhrase) ||
                user.email.toLowerCase().includes(this.state.searchPhrase)
            ) : true);

        const usersToReturn: IEventDetailsUsersTableUser[] = [];

        for (const user of usersFiltered) {
            if (usersToReturn.length === this.state.rowsPerPage) {
                return usersToReturn;
            }

            usersToReturn.push(user);
        }
        return usersToReturn;
    };

    render() {
        const usersDisplayed = this.getUsersToDisplay();
        const usersDisplayedCount: number = usersDisplayed.length;
        const usersSelectedCount: number = this.props.users.filter((user) => user.selected).length;

        return (
            <TableContainer>
                <Table
                    size={'small'}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell
                                colSpan={7}
                            >
                                <TextField
                                    size={'small'}
                                    fullWidth
                                    placeholder={'Search'}
                                    value={this.state.searchPhrase}
                                    onChange={this.onSearchPhraseChange}
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{
                                width: 110,
                                paddingLeft: 0,
                                paddingRight: 0,
                            }}>
                                <Checkbox
                                    checked={usersDisplayed.every((user) => user.selected)}
                                    onChange={this.onAllUsersSelectToggle}
                                />

                                {usersSelectedCount ? (
                                    <span>({usersSelectedCount}/{usersDisplayedCount})</span>
                                ) : null}
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={this.getSortLabelActive('name')}
                                    direction={this.getSortLabelDirection('name')}
                                    onClick={this.onSortLabelClick('name')}
                                >
                                    Name
                                </TableSortLabel>
                            </TableCell>
                            {/*<TableCell>*/}
                            {/*    <TableSortLabel*/}
                            {/*        active={this.getSortLabelActive('email')}*/}
                            {/*        direction={this.getSortLabelDirection('email')}*/}
                            {/*        onClick={this.onSortLabelClick('email')}*/}
                            {/*    >*/}
                            {/*        Email*/}
                            {/*    </TableSortLabel>*/}
                            {/*</TableCell>*/}
                            <TableCell>
                                <TableSortLabel
                                    active={this.getSortLabelActive('carId')}
                                    direction={this.getSortLabelDirection('carId')}
                                    onClick={this.onSortLabelClick('carId')}
                                >
                                    Car
                                </TableSortLabel>
                            </TableCell>
                            {/*<TableCell>Max speed</TableCell>*/}
                            <TableCell align={'center'}>
                                <TableSortLabel
                                    active={this.getSortLabelActive('lastActivity')}
                                    direction={this.getSortLabelDirection('lastActivity')}
                                    onClick={this.onSortLabelClick('lastActivity')}
                                >
                                    Last activity
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align={'center'}>
                                <TableSortLabel
                                    active={this.getSortLabelActive('logged')}
                                    direction={this.getSortLabelDirection('logged')}
                                    onClick={this.onSortLabelClick('logged')}
                                >
                                    Status
                                </TableSortLabel>
                            </TableCell>
                            {/*<TableCell align={'center'}>Type</TableCell>*/}
                            {/*<TableCell align={'center'}>Code</TableCell>*/}
                        </TableRow>

                        <TableRow>
                            <TableCell colSpan={3} />
                            <TableCell style={{textAlign: 'center'}}>
                                <LastActivityFilterChip
                                    mode={this.state.lastActivityFilterMode}
                                    onClick={(lastActivityFilterMode) => this.setState({
                                        lastActivityFilterMode: lastActivityFilterMode,
                                    })}
                                />
                            </TableCell>
                            <TableCell style={{textAlign: 'center'}}>
                                <LoggedStatusFilterChip
                                    mode={this.state.loggedStatusFilterMode}
                                    onClick={(loggedStatusFilterMode) => this.setState({
                                        loggedStatusFilterMode: loggedStatusFilterMode,
                                    })}
                                />
                            </TableCell>
                            {/*<TableCell>*/}
                            {/*    <UserTypeFilterChip*/}
                            {/*        mode={this.state.userTypeFilterMode}*/}
                            {/*        onClick={(userTypeFilterMode) => this.setState({*/}
                            {/*            userTypeFilterMode: userTypeFilterMode,*/}
                            {/*        })}*/}
                            {/*    />*/}
                            {/*</TableCell>*/}
                            {/*<TableCell colSpan={2} />*/}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {usersDisplayed.map((user) => (
                            <TableRow
                                key={user.userId}
                                hover
                                selected={user.selected}
                            >
                                <TableCell
                                    style={{
                                        paddingLeft: 0,
                                    }}
                                >
                                    <Checkbox
                                        checked={user.selected}
                                        onChange={() => this.props.onUserSelectToggle(user)}
                                    />
                                </TableCell>
                                <TableCell onClick={() => this.props.onUserClick(user)}>{user.name}</TableCell>
                                {/*<TableCell onClick={() => this.props.onUserClick(user)}>{user.email}</TableCell>*/}
                                <TableCell onClick={() => this.props.onUserClick(user)}>{this.props.resolveCarName(user.carId)}</TableCell>
                                {/*<TableCell>{user.maxSpeed ? `${Math.floor(user.maxSpeed * 3.6).toFixed(0)} km/h` : '-'}</TableCell>*/}
                                <TableCell
                                    onClick={() => this.props.onUserClick(user)}
                                    align={'center'}
                                    style={{
                                        width: 180
                                    }}
                                >
                                    {user.lastActivity ? moment(user.lastActivity).format('YYYY-MM-DD HH:mm:ss') : null}
                                </TableCell>
                                <TableCell
                                    onClick={() => this.props.onUserClick(user)}
                                    align={'center'}
                                    style={{
                                        width: 130,
                                    }}
                                >
                                    {user.logged ? 'Logged in' : 'Logged out'}
                                </TableCell>
                                {/*<TableCell*/}
                                {/*    onClick={() => this.props.onUserClick(user)}*/}
                                {/*    align={'center'}*/}
                                {/*    style={{*/}
                                {/*        width: 80,*/}
                                {/*    }}*/}
                                {/*>{user.isAdmin ? 'Admin' : 'User'}</TableCell>*/}
                                {/*<UserCodeCell userCode={user.code} />*/}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {/*<TablePagination*/}
                {/*    rowsPerPageOptions={[*/}
                {/*        10,*/}
                {/*        20,*/}
                {/*        50,*/}
                {/*        100,*/}
                {/*        250,*/}
                {/*        500,*/}
                {/*        1000,*/}
                {/*    ]}*/}
                {/*    component={'div'}*/}
                {/*    count={usersDisplayedCount}*/}
                {/*    rowsPerPage={this.state.rowsPerPage}*/}
                {/*    page={this.state.page}*/}
                {/*    onChangePage={this.onChangePage}*/}
                {/*    onChangeRowsPerPage={this.onChangeRowsPerPage}*/}
                {/*/>*/}
            </TableContainer>
        );
    }
}

export default EventDetailsUsersTable;

// const UserCodeCell = ({userCode}: {userCode: string}) => {
//     const [
//         displayCode,
//         setDisplay,
//     ] = useState(false);
//
//     return (
//         <TableCell
//             align={'center'}
//             style={{
//                 width: 140
//             }}
//         >
//             <Button
//                 onClick={() => setDisplay(!displayCode)}
//                 variant={'outlined'}
//                 color={'primary'}
//                 style={{
//                     width: 120,
//                 }}
//             >{displayCode ? userCode : 'Show code'}</Button>
//         </TableCell>
//     );
// };
