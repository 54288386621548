import React from 'react';
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";
import {Grid} from "@material-ui/core";
import {IAbuseReport} from "../../../../core/models/IAbuseReport";
import EventAbuseReportsTable from "./EventAbuseReportsTable";
import {IUser} from "../../../../core/models/IUser";

interface IEventAbuseReportsProps extends ICoreContainerRequired {
    eventId: string;
}

interface IEventAbuseReportsState {
    reports: IAbuseReport[];
    users: IUser[],
}

class EventAbuseReports extends React.Component<IEventAbuseReportsProps, IEventAbuseReportsState> {
    state: IEventAbuseReportsState = {
        reports: [],
        users: [],
    };

    componentDidMount(): void {
        this.fetchReports();
    }

    fetchReports = async () => {
        const reports: IAbuseReport[] = await this.props.coreContainer.IHttpService.eventAbuseReportsGet(this.props.eventId);
        const users: IUser[] = await this.props.coreContainer.IEventUsersService.getEventUsers(this.props.eventId);

        this.setState({
            reports,
            users,
        });
    };

    resolveUserName = (userId: string): string | null => {
        const userData = this.state.users.find((user) => user.userId === userId);
        return userData ? userData.name : null;
    };

    onMarkReviewedClick = async (report: IAbuseReport) => {
        await this.props.coreContainer.IHttpService.eventAbuseReportMarkReviewed(this.props.eventId, report.reportId);
        this.fetchReports();
    };

    render() {
        const reportToReview = this.state.reports.filter((report) => !report.reviewed);
        const reportReviewed = this.state.reports.filter((report) => report.reviewed);

        return (
            <Grid container>
                <EventAbuseReportsTable
                    label={`To Review (${reportToReview.length})`}
                    reports={reportToReview}
                    coreContainer={this.props.coreContainer}
                    resolveUserName={this.resolveUserName}
                    onMarkReviewedClick={this.onMarkReviewedClick}
                />

                <EventAbuseReportsTable
                    label={`Reviewed (${reportReviewed.length})`}
                    reports={reportReviewed}
                    coreContainer={this.props.coreContainer}
                    resolveUserName={this.resolveUserName}
                    onMarkReviewedClick={this.onMarkReviewedClick}
                />
            </Grid>
        );
    }
}

export default EventAbuseReports;
