import {IDialog, IDialogsController} from "./IDialogsController";
import {PubSubAbstract} from "../pubSub/PubSubAbstract";
import {v1 as uuidV1} from 'uuid';
import {DialogTypesEnum} from "./DialogTypesEnum";
import {IFormDialogParams} from "./IFormDialogParams";

export class DialogsController extends PubSubAbstract<undefined> implements IDialogsController {
    private _dialogs: IDialog[] = [];

    public async confirm(question: string): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            const dialogKey: string = uuidV1();

            this.appendDialog({
                key: dialogKey,
                type: DialogTypesEnum.confirm,
                title: 'Confirm',
                confirmDialog: {
                    question,
                },
                onFinish: (confirm: boolean) => {
                    this.removeDialog(dialogKey);
                    return resolve(confirm);
                },
            });
        });
    }

    public async form<FormValues extends {}>(params: IFormDialogParams<keyof FormValues>): Promise<FormValues | null> {
        return new Promise<FormValues | null>((resolve) => {
            const dialogKey: string = uuidV1();

            this.appendDialog({
                key: dialogKey,
                type: DialogTypesEnum.form,
                formDialog: {
                    params: params as any,
                },
                onFinish: (actionResult: FormValues | undefined) => {
                    this.removeDialog(dialogKey);
                    resolve(actionResult ? actionResult : null);
                },
            });
        });
    }

    public getDialogs(): IDialog[] {
        return this._dialogs;
    }

    public async prompt(question: string, defaultValue?: string): Promise<string> {
        return new Promise<string>((resolve) => {
            const dialogKey: string = uuidV1();

            this.appendDialog({
                key: dialogKey,
                type: DialogTypesEnum.prompt,
                title: '',
                promptDialog: {
                    question,
                    defaultValue,
                },
                onFinish: (result: string) => {
                    this.removeDialog(dialogKey);
                    resolve(result);
                },
            })
        });
    }

    private appendDialog(dialog: IDialog): void {
        this._dialogs.push(dialog);
        this.publish(undefined);
    }

    private removeDialog(dialogKey: string): void {
        this._dialogs = this._dialogs.filter((dialog) => dialog.key !== dialogKey);
        this.publish(undefined);
    }
}
