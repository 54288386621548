import {IPubSubAbstract, PubSubCallback} from "./IPubSubAbstract";

export class PubSubAbstract<TMessage> implements IPubSubAbstract<TMessage> {
    private readonly _logging: boolean;

    private _subscribers: Array<{
        subscriptionId: number;
        callback: PubSubCallback<TMessage>;
    }> = [];

    public constructor() {
        this._logging = (process.env.NODE_ENV === 'development');
    }

    public publish(message: TMessage): number {
        for (const subscriber of this._subscribers) {
            subscriber.callback(message);
        }

        this.onPublish(message);

        return this._subscribers.length;
    }

    public subscribe(callback: PubSubCallback<TMessage>): number {
        const subscriptionId: number = this._subscribers.length + 1;
        this._subscribers.push({
            subscriptionId,
            callback,
        });

        this.onSubscribe();

        return subscriptionId;
    }

    public unsubscribe(subscriptionId: number): void {
        this._subscribers = this._subscribers.filter((sub) => (
            sub.subscriptionId !== subscriptionId
        ));

        this.onUnsubscribe();
    }

    protected onPublish(message: TMessage): void {
        if (this._logging) {
            console.log('New message', message, {subscribers: this._subscribers});
        }
    }

    protected onSubscribe(): void {
        if (this._logging) {
            console.log('New subscriber', {subscribers: this._subscribers});
        }
    }

    protected onUnsubscribe(): void {
        if (this._logging) {
            console.log('Unsubscribed', {subscribers: this._subscribers});
        }
    }
}
