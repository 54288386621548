import React from 'react';
import {ICar} from "../../../../core/models/ICar";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel, TextField
} from "@material-ui/core";

interface IEventDetailsCarsTableProps {
    cars: ICar[];
    onCarClick: (carId: string) => void;
}

interface IEventDetailsCarsTableState {
    page: number;
    rowsPerPage: number;
    searchPhrase: string;
    sortDirection: 'asc' | 'desc';
    sortField: keyof ICar;
}

class EventDetailsCarsTable extends React.Component<IEventDetailsCarsTableProps, IEventDetailsCarsTableState> {
    state: IEventDetailsCarsTableState = {
        page: 0,
        rowsPerPage: 10000,
        searchPhrase: '',
        sortDirection: 'asc',
        sortField: 'name',
    };

    onSearchPhraseChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
        searchPhrase: event.currentTarget.value,
    });

    onChangePage = (event: any, page: number) => this.setState({
        page: page,
    });

    onChangeRowsPerPage = (event: any) => this.setState({
        page: 0,
        rowsPerPage: event.target.value,
    });

    getSortLabelActive = (field: keyof ICar): boolean => field === this.state.sortField;

    getSortLabelDirection = (field: keyof ICar): 'asc' | 'desc' => this.getSortLabelActive(field) ? this.state.sortDirection : 'asc';

    onSortLabelClick = (field: keyof ICar) => () => this.getSortLabelActive(field)
        ? this.setState({
            sortDirection: this.state.sortDirection === 'asc' ? 'desc' : 'asc',
        })
        : this.setState({
            sortDirection: 'asc',
            sortField: field,
        });

    getCarsSorted = (): ICar[] => {
        return [...this.props.cars].sort((left, right) => {
            const leftValue = left[this.state.sortField];
            const rightValue = right[this.state.sortField];
            if (!leftValue || !rightValue) {
                if (this.state.sortField === 'maxSpeed') {
                    if (!leftValue) {
                        return this.state.sortDirection === 'asc' ? -1 : 1;
                    }

                    if (!rightValue) {
                        return this.state.sortDirection === 'asc' ? 1 : -1;
                    }
                }

                return 0;
            }

            return this.state.sortDirection === 'asc'
                ? leftValue > rightValue ? 1 : -1
                : leftValue < rightValue ? 1 : -1
        });
    };

    getCarsToDisplay = (): ICar[] => {
        const carsSorted = this
            .getCarsSorted()
            .splice(this.state.page * this.state.rowsPerPage)
            .filter((car) => this.state.searchPhrase ? (
                car.licensePlate.toLowerCase().includes(this.state.searchPhrase) ||
                car.name.toLowerCase().includes(this.state.searchPhrase) ||
                car.color.toLowerCase().includes(this.state.searchPhrase) ||
                car.year.toLowerCase().includes(this.state.searchPhrase)
            ) : true);

        const carsToReturn = [];
        for (const car of carsSorted) {
            if (carsToReturn.length === this.state.rowsPerPage) {
                return carsToReturn;
            }

            carsToReturn.push(car);
        }

        return carsToReturn;
    };

    render() {
        // const allCarsCount: number = this.props.cars.length;
        const carsToDisplay: ICar[] = this.getCarsToDisplay();

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                colSpan={8}
                            >
                                <TextField
                                    size={'small'}
                                    fullWidth
                                    placeholder={'Search'}
                                    value={this.state.searchPhrase}
                                    onChange={this.onSearchPhraseChange}
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={this.getSortLabelActive('name')}
                                    direction={this.getSortLabelDirection('name')}
                                    onClick={this.onSortLabelClick('name')}
                                >
                                    Make and Model
                                </TableSortLabel>
                            </TableCell>

                            <TableCell
                                style={{
                                    width: 100,
                                }}
                            >
                                <TableSortLabel
                                    active={this.getSortLabelActive('year')}
                                    direction={this.getSortLabelDirection('year')}
                                    onClick={this.onSortLabelClick('year')}
                                >
                                    Year
                                </TableSortLabel>
                            </TableCell>

                            <TableCell
                                style={{
                                    width: 140,
                                }}
                            >
                                <TableSortLabel
                                    active={this.getSortLabelActive('color')}
                                    direction={this.getSortLabelDirection('color')}
                                    onClick={this.onSortLabelClick('color')}
                                >
                                    Color
                                </TableSortLabel>
                            </TableCell>

                            <TableCell
                                style={{
                                    width: 180,
                                }}
                            >
                                <TableSortLabel
                                    active={this.getSortLabelActive('licensePlate')}
                                    direction={this.getSortLabelDirection('licensePlate')}
                                    onClick={this.onSortLabelClick('licensePlate')}
                                >
                                    License plate
                                </TableSortLabel>
                            </TableCell>

                            <TableCell
                                style={{
                                    width: 180,
                                }}
                            >
                                <TableSortLabel
                                    active={this.getSortLabelActive('maxSpeed')}
                                    direction={this.getSortLabelDirection('maxSpeed')}
                                    onClick={this.onSortLabelClick('maxSpeed')}
                                >
                                    Max Speed
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {carsToDisplay.map((car: ICar) => (
                            <TableRow
                                key={car.carId}
                                hover
                                onClick={() => this.props.onCarClick(car.carId)}
                            >
                                <TableCell>{car.name}</TableCell>
                                <TableCell>{car.year}</TableCell>
                                <TableCell>{car.color}</TableCell>
                                <TableCell>{car.licensePlate}</TableCell>
                                <TableCell>{car.maxSpeed ? `${Math.floor(car.maxSpeed * 3.6).toFixed(0)} km/h` : '-'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {/*<TablePagination*/}
                {/*    rowsPerPageOptions={[*/}
                {/*        10,*/}
                {/*        20,*/}
                {/*        50,*/}
                {/*        100,*/}
                {/*        250,*/}
                {/*        500,*/}
                {/*        1000,*/}
                {/*    ]}*/}
                {/*    component={'div'}*/}
                {/*    count={allCarsCount}*/}
                {/*    rowsPerPage={this.state.rowsPerPage}*/}
                {/*    page={this.state.page}*/}
                {/*    onChangePage={this.onChangePage}*/}
                {/*    onChangeRowsPerPage={this.onChangeRowsPerPage}*/}
                {/*/>*/}
            </TableContainer>
        );
    }
}

export default EventDetailsCarsTable;
