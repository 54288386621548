import React from 'react';
import SubModule from "../../usages/SubModule";
import {ICoreContainerRequired} from "../../../core/ICoreContainer";
import AdminsList from "./list/AdminsList";

export interface IAdminsModuleProps extends ICoreContainerRequired {

}

export class AdminsModule extends React.Component<IAdminsModuleProps> {
    render() {
        return (
            <SubModule
                title={'Application Administrators'}
                subModules={[
                    {
                        label: 'Admins list',
                        component: (
                            <AdminsList
                                coreContainer={this.props.coreContainer}
                            />
                        ),
                    },
                ]}
            />
        );
    }
}
