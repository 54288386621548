import React from 'react';
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";
import {IUser} from "../../../../core/models/IUser";
import {
    AppBar,
    Button,
    Dialog,
    DialogContent,
    Grid,
    IconButton, Table, TableBody, TableCell, TableRow,
    Toolbar,
    Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

interface IEventUsersMultipleLogoutDialogProps extends ICoreContainerRequired {
    eventId: string;
    onCloseRequest: () => any;
    users: IUser[];
}

interface IEventUsersMultipleLogoutDialogState {
    inProgress: boolean;
    users: IUser[];
}

class EventUsersMultipleLogoutDialog extends React.Component<IEventUsersMultipleLogoutDialogProps, IEventUsersMultipleLogoutDialogState> {
    constructor(props: IEventUsersMultipleLogoutDialogProps) {
        super(props);
        this.state = {
            inProgress: false,
            users: [...props.users],
        };
    }

    onLogoutClick = async () => {
        if (!(await this.props.coreContainer.IDialogsController.confirm(`Are you sure you want to logout ${this.state.users.length} users?`))) {
            return;
        }

        await this.setInProgress();

        for (const user of this.state.users) {
            await this.logoutSingleUser(user);
        }

        this.props.coreContainer.ISnackbarsController.show({
            message: `${this.props.users.length} users logged out`,
            severity: 'success',
        });

        this.props.onCloseRequest();
    };

    setInProgress = () => new Promise((resolve) => this.setState({inProgress: true}, () => resolve()));

    logoutSingleUser = (user: IUser) => {
        return new Promise(async (resolve) => {
            try {
                await this.props.coreContainer.IHttpService.eventUsersRevokeAccess(
                    this.props.eventId,
                    user.userId,
                );

                this.setState({
                    users: this.state.users.filter((u) => u.userId !== user.userId),
                }, () => resolve());
            } catch {
                resolve();
            }
        });
    };

    render() {
        return (
            <Dialog open={true} fullScreen>
                <AppBar style={{
                    position: 'relative',
                    marginBottom: 20,
                }}>
                    <Toolbar>
                        <IconButton
                            edge={'start'}
                            color={'inherit'}
                            onClick={this.props.onCloseRequest}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant={'h6'}
                            style={{
                                flex: 1,
                            }}
                        >
                            SMS
                        </Typography>
                        <Button
                            color={'inherit'}
                            variant={'outlined'}
                            autoFocus
                            onClick={this.onLogoutClick}
                            disabled={this.state.inProgress}
                        >Logout</Button>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            These users will be logged out:
                        </Grid>
                        <Grid item xs={12} style={{marginTop: 20}}>
                            <Table>
                                <TableBody>
                                    {this.state.users.map((user) => (
                                        <TableRow key={user.userId}>
                                            <TableCell style={{width: 300}}>{user.name}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}

export default EventUsersMultipleLogoutDialog;
