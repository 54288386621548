import {ISnackbarOptions, ISnackbarOptionsRequired, ISnackbarsController} from "./ISnackbarsController";
import {ICoreContainer} from "../ICoreContainer";
import {PubSubAbstract} from "../pubSub/PubSubAbstract";
import {snackbarLocationEnum} from "./snackbarLocationEnum";
import {v1 as uuidV1} from 'uuid';

export class SnackbarsController extends PubSubAbstract<ISnackbarOptions> implements ISnackbarsController {
    constructor(
        private readonly _coreContainer: ICoreContainer,
    ) {
        super();
    }

    public show(options: ISnackbarOptionsRequired) {
        this.publish({
            componentId: uuidV1(),
            displayDuration: 4000,
            displayed: true,
            location: snackbarLocationEnum.topRight,
            severity: 'info',
            ...options,
        });
    }
}
