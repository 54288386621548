import React from 'react';
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";
import NewEventButton from "../newEvent/NewEventButton";
import {IEvent} from "../../../../core/models/IEvent";
import EventsListTable from "./EventsListTable";
import {AppPaths} from "../../../../core/router/AppPaths";
import {LinearProgress} from "@material-ui/core";

interface IEventsListProps extends ICoreContainerRequired {

}

interface IEventsListState {
    fetched: boolean;
    events: IEvent[];
}

class EventsList extends React.Component<IEventsListProps, IEventsListState> {
    state: IEventsListState = {
        fetched: false,
        events: [],
    };

    componentDidMount(): void {
        this.fetchEvents();
    }

    fetchEvents = async () => {
        const events: IEvent[] = await this.props.coreContainer.IEventsService.getEvents();

        this.setState({
            fetched: true,
            events,
        });
    };

    onEventClick = (eventId: string) => {
        this.props.coreContainer.IAppRouter.push(AppPaths.Events + `/${eventId}?view=general`);
    };

    getEventsSorted = (): IEvent[] => this.state.events.sort((left, right) => {
        return left.dateFrom < right.dateFrom ? -1 : 1;
    });

    render() {
        return (
            <React.Fragment>
                <NewEventButton
                    coreContainer={this.props.coreContainer}
                    onAdd={this.fetchEvents}
                />

                {this.state.fetched ? (
                    <EventsListTable
                        events={this.getEventsSorted()}
                        onEventClick={this.onEventClick}
                    />
                ) : <LinearProgress />}
            </React.Fragment>
        );
    }
}

export default EventsList;
