import {createStyles, Theme, WithStyles, withStyles} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import React from "react";

const styles = (theme: Theme) => createStyles({
    divider: {
        marginTop: theme.spacing(2),
    },
});

interface INavigatorDividerProps extends WithStyles<typeof styles> {

}

function NavigatorDivider(props: INavigatorDividerProps): JSX.Element {
    const {
        classes,
    } = props;

    return (
        <Divider className={classes.divider} />
    );
}

export default withStyles(styles)(NavigatorDivider);
