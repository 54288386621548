import React from 'react';
import moment from "moment";
import {Chip} from "@material-ui/core";

interface IEventDateLabelProps {
    dateFrom: string;
    dateTo: string;
}

const EventDateLabel = (props: IEventDateLabelProps) => {
    const now = moment().valueOf();
    const eventStartedBeforeNow: boolean = moment(`${props.dateFrom} 00:00:00`).valueOf() < now;

    const isEventNow: boolean = eventStartedBeforeNow
        ? moment(`${props.dateTo} 23:59:59`).valueOf() > now
        : false;

    const dateText: string = `${props.dateFrom} to ${props.dateTo}`;

    const futureEventDaysCount = eventStartedBeforeNow
        ? null
        : moment(props.dateFrom).diff(moment(), 'days');

    const chipStyle = {
        marginLeft: 10,
    };

    return (
        <React.Fragment>
            {dateText}
            {eventStartedBeforeNow ? (
                <React.Fragment>
                    {isEventNow ? (
                        <Chip
                            label={'Now'}
                            size={'small'}
                            style={chipStyle}
                            color={'primary'}
                        />
                    ) : (
                        <Chip
                            label={'Finished'}
                            size={'small'}
                            style={chipStyle}
                            color={'secondary'}
                            variant={'outlined'}
                        />
                    )}
                </React.Fragment>
            ) : (
                <Chip
                    label={`In ${futureEventDaysCount} days`}
                    size={'small'}
                    style={chipStyle}
                    color={'primary'}
                    variant={'outlined'}
                />
            )}
        </React.Fragment>
    );
};

export default EventDateLabel;
