import React from 'react';
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";
import FormTable from "../../../usages/FormTable";
import {IEvent, IEventDetails} from "../../../../core/models/IEvent";
import {FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, MenuItem, Switch} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import TextField from "@material-ui/core/TextField";

interface IEventDetailsSettingsProps extends ICoreContainerRequired {
    eventId: string;
    eventData: IEventDetails;
    fetchEventData: () => any;
}

interface IEventDetailsSettingsState {
    changes: Partial<IEventDetails>;
    events: IEvent[];
}

class EventDetailsSettings extends React.Component<IEventDetailsSettingsProps, IEventDetailsSettingsState> {
    state: IEventDetailsSettingsState = {
        changes: {},
        events: [],
    };

    componentDidMount() {
        this.fetchEventsList();
    }

    fetchEventsList = async () => {
        this.props.coreContainer.IEventsService.getEvents().then((events) => {
            this.setState({
                events,
            });
        });
    };

    toggleEvent = async () => {
        if (!(await this.props.coreContainer.IDialogsController.confirm('Are you sure?'))) {
            return;
        }

        await this.props.coreContainer.IEventsService.editEvent(this.props.eventId, {
            isActive: !this.props.eventData.isActive,
        });

        this.props.fetchEventData();
    };

    toggleRefreshing = async () => {
        if (!(await this.props.coreContainer.IDialogsController.confirm('Are you sure?'))) {
            return;
        }

        await this.props.coreContainer.IEventsService.editEvent(this.props.eventId, {
            mapRefreshingEnabled: !this.props.eventData.mapRefreshingEnabled,
        });

        this.props.fetchEventData();
    };

    isFieldModified = (field: keyof IEventDetails) => this.state.changes[field] !== undefined && this.state.changes[field] !== this.props.eventData[field];

    getTextFieldValue = (field: keyof IEventDetails) => this.isFieldModified(field)
        ? this.state.changes[field]
        : this.props.eventData[field];

    onTextFieldValueChange = (field: keyof IEventDetails) => (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
        changes: {
            ...this.state.changes,
            [field]: event.currentTarget.value,
        },
    });

    onTextFieldSaveClick = (field: keyof IEventDetails) => async () => {
        if (!(await this.props.coreContainer.IDialogsController.confirm('Are you sure?'))) {
            return;
        }

        await this.props.coreContainer.IEventsService.editEvent(this.props.eventId, {
            [field]: this.getTextFieldValue(field),
        });

        this.props.fetchEventData();
    };

    onScheduleSourceChange = async (event: React.ChangeEvent<any>) => {
        if (!(await this.props.coreContainer.IDialogsController.confirm('Are you sure?'))) {
            return;
        }

        const scheduleSourceEventId = event.target.value !== 'none' ? event.target.value : null;

        await this.props.coreContainer.IEventsService.editEvent(this.props.eventId, {
            scheduleSourceEventId,
        });

        this.setState({
            changes: {
                ...this.state.changes,
                scheduleSourceEventId,
            },
        });

        this.props.fetchEventData();
    };

    render() {
        return (
            <Grid container>
                <Grid item xs={12} md={6}>
                    <FormTable styles={[{width: 250}]} rows={[
                        ...(this.props.eventData.name !== 'La Famiglia' ? [
                            [
                                'Name',
                                (
                                  <TextEditableField
                                    label={'Name'}
                                    type={'text'}
                                    value={this.getTextFieldValue('name')}
                                    onChange={this.onTextFieldValueChange('name')}
                                    isFieldModified={this.isFieldModified('name')}
                                    onSaveClick={this.onTextFieldSaveClick('name')}
                                  />
                                ),
                            ],
                            [
                                'Date From',
                                (
                                  <TextEditableField
                                    label={'Date From'}
                                    type={'date'}
                                    value={this.getTextFieldValue('dateFrom')}
                                    onChange={this.onTextFieldValueChange('dateFrom')}
                                    isFieldModified={this.isFieldModified('dateFrom')}
                                    onSaveClick={this.onTextFieldSaveClick('dateFrom')}
                                  />
                                ),
                            ],
                            [
                                'Date To',
                                (
                                  <TextEditableField
                                    label={'Date To'}
                                    type={'date'}
                                    value={this.getTextFieldValue('dateTo')}
                                    onChange={this.onTextFieldValueChange('dateTo')}
                                    isFieldModified={this.isFieldModified('dateTo')}
                                    onSaveClick={this.onTextFieldSaveClick('dateTo')}
                                  />
                                ),
                            ],
                            [
                                'Active (login enabled)',
                                (
                                  <Switch
                                    checked={this.props.eventData.isActive}
                                    onChange={this.toggleEvent}
                                  />
                                ),
                            ],
                            [
                                'Map refreshing enabled',
                                (
                                  <Switch
                                    checked={this.props.eventData.mapRefreshingEnabled}
                                    onChange={this.toggleRefreshing}
                                  />
                                ),
                            ],
                            [
                                'Web Schedule URL',
                                (
                                  <a
                                    href={`https://granturismodriver.com/schedule/${this.props.eventData.scheduleToken}`}
                                    target={'_blank'}
                                    rel={'noopener noreferrer'}
                                  >{`https://granturismodriver.com/schedule/${this.props.eventData.scheduleToken}`}</a>
                                ),
                            ]
                        ] : [
                            [
                                'Schedule Source Instance (Event)',
                                (
                                  <TextField
                                    value={this.getTextFieldValue('scheduleSourceEventId') || 'none'}
                                    fullWidth
                                    select
                                    onChange={this.onScheduleSourceChange}
                                    size={'small'}
                                  >
                                      <MenuItem value={'none'}>- Do not display Schedule -</MenuItem>
                                      {this.state.events.filter(event => event.name !== 'La Famiglia').reverse().map(event => (
                                        <MenuItem key={event.eventId} value={event.eventId}>{event.name}</MenuItem>
                                      ))}
                                  </TextField>
                                ),
                            ]
                        ]),
                    ]} />
                </Grid>
            </Grid>
        );
    }
}

export default EventDetailsSettings;

interface ITextEditableFieldProps {
    label: string;
    value: any;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => any;
    onSaveClick: () => any;
    isFieldModified: boolean;
    type: string;
    disabled?: boolean;
}

const TextEditableField = (props: ITextEditableFieldProps) => (
    <FormControl fullWidth>
        <InputLabel>{props.label}</InputLabel>
        <Input
            type={props.type}
            value={props.value}
            fullWidth
            onChange={props.onChange}
            endAdornment={props.isFieldModified ? (
                <InputAdornment position={'end'}>
                    <IconButton onClick={props.onSaveClick}>
                        <SaveIcon />
                    </IconButton>
                </InputAdornment>
            ) : undefined}
            disabled={props.disabled}
        />
    </FormControl>
);
