import {createBrowserHistory, History} from 'history';
import {PubSubAbstract} from "../pubSub/PubSubAbstract";
import {MenuIdentifersEnum} from "../menuController/MenuIdentifiersEnum";
import {AppPaths} from "./AppPaths";
import {IAppRouter} from "./IAppRouter";
import {IEventsHub} from "../eventsHub/IEventsHub";

export type IHistoryChangeEvent = any;

export class AppRouter extends PubSubAbstract<IHistoryChangeEvent> implements IAppRouter {
    private readonly _history: History;
    public get history(): History {
        return this._history;
    }

    constructor(
        private readonly _eventsHub: IEventsHub,
    ) {
        super();
        this._history = createBrowserHistory();
        this._history.listen((change: IHistoryChangeEvent) => {
            this.publish(change);
        });
    }

    public initMenuSubscribe(): void {
        this._eventsHub.menuControllerPathChange.subscribe((moduleIdentifier: MenuIdentifersEnum) => {
            switch (moduleIdentifier) {
                case MenuIdentifersEnum.Apps: return this.push(AppPaths.Root);
                case MenuIdentifersEnum.Events: return this.push(AppPaths.Events);
                case MenuIdentifersEnum.SettingsAdmins: return this.push(AppPaths.SettingsAdmins);
                case MenuIdentifersEnum.SettingsConfig: return this.push(AppPaths.SettingsConfig);
            }
        });
    }

    public changeQueryField(key: string, value: string | undefined): void {
        const queryIsInitialized: boolean = this._history.location.search.includes('?');

        let newQuery: string = '';

        if (queryIsInitialized && this._history.location.search.includes(`${key}=`)) {
            if (!value) {
                const currentSearch: {[key: string]: string} = this.parseQuery();
                const currentSearchKeys: string[] = Object.keys(currentSearch);

                if (currentSearchKeys.length === 1 && currentSearchKeys[0] === key) {
                    newQuery = '';
                } else {
                    const currentValue: string = currentSearch[key];
                    newQuery = this._history.location.search
                        .replace(`${key}=${currentValue}&`, '')
                        .replace(`&${key}=${currentValue}`, '')
                        .replace(`&${key}=${currentValue}&`, '');
                }
            }
        } else {
            newQuery = queryIsInitialized
                ? `${this._history.location.search}&${key}=${value}`
                : `?${key}=value`;
        }

        this.push(
            this._history.location.pathname + newQuery
        );
    }

    public currentPath(): string {
        return this._history.location.pathname;
    }

    public goBack(): void {
        this._history.goBack();
    }

    public parseQuery(): { [key: string]: string } {
        return this._history.location.search
            .replace('?', '')
            .split('&')
            .map((keyAndValue) => (keyAndValue.split('=')))
            .filter(([key]) => !!key)
            .reduce((acc, [key, value]) => ({
                ...acc,
                [key]: value,
            }), {} as {[key: string]: string});
    }

    public push(target: AppPaths | string): void {
        this._history.push(target);
    }

    public replace(target: AppPaths | string): void {
        this._history.replace(target);
    }
}
