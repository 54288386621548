import React from 'react';
import {IPubSubAbstract} from "./IPubSubAbstract";

interface IPubSubConsumerProps {
    pubSub: IPubSubAbstract<any>;
    children: any;
}

export class PubSubConsumer extends React.Component<IPubSubConsumerProps> {
    private _subscriptionId: number | undefined;

    componentDidMount(): void {
        this._subscriptionId = this.props.pubSub.subscribe(() => {
            this.forceUpdate();
        });
    }

    componentWillUnmount() {
        if (this._subscriptionId) {
            this.props.pubSub.unsubscribe(this._subscriptionId);
        }
    }

    render() {
        return this.props.children;
    }
}
