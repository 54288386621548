import React from 'react';
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";
import {Button, ButtonGroup, Grid, LinearProgress} from "@material-ui/core";
import EventDetailsUserDetails from "./EventDetailsUserDetails";
import {IUser} from "../../../../core/models/IUser";
import EventDetailsUsersTable, {IEventDetailsUsersTableUser} from "./EventDetailsUsersTable";
import AddNewEventUserButton from "./AddNewEventUserButton";
import {ICar} from "../../../../core/models/ICar";
import UsersPostNotificationDialog from "./UsersPostNotificationDialog";
import ImportUsersFromCsvButton from "./ImportUsersFromCsvButton";
import EventUsersMultipleSmsDialog, {SmsDialogModeEnum} from "./EventUsersMultipleSmsDialog";
import EventUsersMultipleLogoutDialog from "./EventUsersMultipleLogoutDialog";

interface IEventDetailsUsersProps extends ICoreContainerRequired {
    eventId: string;
}

interface IEventDetailsUsersState {
    userId: string | null;
    usersFetched: boolean;
    cars: ICar[];
    logoutDialogOpened: boolean;
    users: IEventDetailsUsersTableUser[];
    pushNotificationWindowOpened: boolean;
    smsDialogMode: SmsDialogModeEnum;
    smsDialogOpened: boolean;
}

class EventDetailsUsers extends React.Component<IEventDetailsUsersProps, IEventDetailsUsersState> {
    state: IEventDetailsUsersState = {
        userId: null,
        usersFetched: false,
        cars: [],
        logoutDialogOpened: false,
        users: [],
        pushNotificationWindowOpened: false,
        smsDialogMode: SmsDialogModeEnum.text,
        smsDialogOpened: false,
    };

    constructor(props: IEventDetailsUsersProps) {
        super(props);

        const { user } = this.props.coreContainer.IAppRouter.parseQuery();
        if (user) {
            this.state.userId = user;
        }
    }

    componentDidMount() {
        if (!this.state.userId) {
            this.fetchUsers();
        }
    }

    fetchUsers = async () => {
        const cars: ICar[] = await this.props.coreContainer.IEventCarsService.getCars(this.props.eventId);
        const users: IUser[] = await this.props.coreContainer.IEventUsersService.getEventUsers(this.props.eventId);

        this.setState({
            usersFetched: true,
            cars,
            users: users.map((user) => ({
                ...user,
                selected: false,
            })),
        });
    };

    onUserAdd = (userId?: string) => {
        if (userId) {
            this.fetchUsers();
            this.userDetailsOpen(userId);
        } else {
            this.fetchUsers();
        }
    };

    userDetailsOpen = (user: IUser | string) => this.setState({
        userId: typeof user === 'string' ? user : user.userId,
    }, () => {
        this.props.coreContainer.IAppRouter.changeQueryField('user', typeof user === 'string' ? user : user.userId);
    });

    userDetailsClose = () => this.setState({
        userId: null,
    }, () => {
        this.props.coreContainer.IAppRouter.changeQueryField('user', undefined);
        this.fetchUsers();
    });

    resolveCarName = (carId: string | null): string => {
        if (!carId) {
            return '';
        }

        const carData = this.state.cars.find((car) => car.carId === carId);

        return carData ? `${carData.name} - ${carData.licensePlate}` : '';
    };

    revokeAllUsersAccess = async () => {
        if (!(await this.props.coreContainer.IDialogsController.confirm(`Are you sure?`))) {
            return;
        }

        await this.props.coreContainer.IHttpService.eventUsersRevokeAccessAllUsers(this.props.eventId);
        this.fetchUsers();
    };

    onUserSelectToggle = (user: IUser) => this.setState({
        users: this.state.users.map((u) => u.userId !== user.userId ? u : ({
            ...u,
            selected: !u.selected,
        }))
    });

    onAllUsersSelectToggle = (usersIds: string[]) => {
        const allRequestedUsersAreSelected = this.state.users
            .filter((user) => usersIds.includes(user.userId))
            .every((user) => user.selected);

        this.setState({
            users: this.state.users.map((user: IEventDetailsUsersTableUser) => ({
                ...user,
                selected: allRequestedUsersAreSelected ? false : usersIds.includes(user.userId),
            })),
        });
    };

    clearAllUsersSelection = () => this.setState({
        users: this.state.users.map((user) => ({
            ...user,
            selected: false,
        }))
    });

    togglePushNotificationsWindow = () => this.setState({
        pushNotificationWindowOpened: !this.state.pushNotificationWindowOpened,
    });

    openCustomSmsWindow = () => this.setState({
        smsDialogMode: SmsDialogModeEnum.text,
        smsDialogOpened: true,
    });

    openCodeSmsWindow = () => this.setState({
        smsDialogMode: SmsDialogModeEnum.code,
        smsDialogOpened: true,
    });

    toggleLogoutWindow = () => {
        if (this.state.logoutDialogOpened) {
            this.setState({
                logoutDialogOpened: false,
            }, () => this.fetchUsers());
        } else {
            this.setState({
                logoutDialogOpened: true,
            });
        }
    };

    render() {
        if (this.state.userId) {
            return (
                <EventDetailsUserDetails
                    eventId={this.props.eventId}
                    userId={this.state.userId}
                    coreContainer={this.props.coreContainer}
                    onCloseRequest={this.userDetailsClose}
                />
            )
        }

        if (!this.state.usersFetched) {
            return <LinearProgress />
        }

        const thereAreSomeUsersSelected: boolean = this.state.users.some((user) => user.selected);

        return (
            <Grid container>
                <Grid item xs={12} style={{marginBottom: 10}}>
                    <AddNewEventUserButton
                        coreContainer={this.props.coreContainer}
                        eventId={this.props.eventId}
                        onAdd={this.onUserAdd}
                    />

                    <ImportUsersFromCsvButton
                        coreContainer={this.props.coreContainer}
                        eventId={this.props.eventId}
                        onInsert={this.fetchUsers}
                    />
                </Grid>

                <Grid item xs={12} style={{marginBottom: 10}}>
                    <ButtonGroup>
                        <Button
                            color={'primary'}
                            variant={'outlined'}
                            disabled={!thereAreSomeUsersSelected}
                            onClick={this.togglePushNotificationsWindow}
                        >Send Push Notification</Button>
                        {/*<Button*/}
                        {/*    color={'primary'}*/}
                        {/*    variant={'outlined'}*/}
                        {/*    disabled={!thereAreSomeUsersSelected}*/}
                        {/*    onClick={this.openCustomSmsWindow}*/}
                        {/*>Send SMS</Button>*/}
                        <Button
                            color={'primary'}
                            variant={'outlined'}
                            disabled={!thereAreSomeUsersSelected}
                            onClick={this.openCodeSmsWindow}
                        >Send SMS</Button>
                        {this.props.coreContainer.IAuthStore.get().adminData.superAdmin ? (
                            <Button
                                color={'secondary'}
                                variant={'outlined'}
                                disabled={!thereAreSomeUsersSelected}
                                onClick={this.toggleLogoutWindow}
                            >Logout users</Button>
                        ) : null}
                    </ButtonGroup>
                </Grid>

                <Grid item xs={12}>
                    <EventDetailsUsersTable
                        resolveCarName={this.resolveCarName}
                        users={this.state.users}
                        onUserClick={this.userDetailsOpen}
                        onUserSelectToggle={this.onUserSelectToggle}
                        onAllUsersSelectToggle={this.onAllUsersSelectToggle}
                        onPageChange={this.clearAllUsersSelection}
                    />
                </Grid>

                {this.state.pushNotificationWindowOpened ? (
                    <UsersPostNotificationDialog
                        coreContainer={this.props.coreContainer}
                        users={this.state.users.filter((user) => user.selected)}
                        onCloseRequest={this.togglePushNotificationsWindow}
                    />
                ) : null}

                {this.state.smsDialogOpened ? (
                    <EventUsersMultipleSmsDialog
                        mode={this.state.smsDialogMode}
                        onCloseRequest={() => this.setState({smsDialogOpened: false})}
                        users={this.state.users.filter((user) => user.selected).filter((user) => !!user.phone)}
                        coreContainer={this.props.coreContainer}
                    />
                ) : null}

                {this.state.logoutDialogOpened ? (
                    <EventUsersMultipleLogoutDialog
                        eventId={this.props.eventId}
                        onCloseRequest={this.toggleLogoutWindow}
                        users={this.state.users.filter((user) => user.selected)}
                        coreContainer={this.props.coreContainer}
                    />
                ) : null}
            </Grid>
        );
    }
}

export default EventDetailsUsers;
