import React from 'react';
import Paperbase from "./paperbase/Paperbase";
import {ICoreContainerRequired} from "./core/ICoreContainer";
import {IAuthStoreStructure} from "./core/auth/IAuthStoreStructure";
import LoginPage from "./login/LoginPage";
import {LinearProgress} from "@material-ui/core";

interface IGatewayProps extends ICoreContainerRequired {

}

interface IGatewayState {
    authChecked: boolean;
    authorized: boolean;
}

export class Gateway extends React.Component<IGatewayProps, IGatewayState> {
    public state: IGatewayState = {
        authChecked: false,
        authorized: false,
    };

    componentDidMount(): void {
        this.props.coreContainer.IAuthService.checkAuthorization();

        this.props.coreContainer.IAuthStore.subscribe((authMessage: IAuthStoreStructure) => {
            if (authMessage.authorized) {
                this.setState({
                    authChecked: true,
                    authorized: true,
                });
            } else {
                this.setState({
                    authChecked: true,
                    authorized: false,
                });
            }
        });
    }

    render(): JSX.Element | null {
        if (!this.state.authChecked) {
            return <LinearProgress />
        }

        return this.state.authorized
            ? <Paperbase coreContainer={this.props.coreContainer} />
            : <LoginPage coreContainer={this.props.coreContainer} />;
    }
}
