import {IAdminsService} from "./IAdmins.service";
import {IAdmin, IAdminWithPassword} from "../models/IAdmin";
import {IHttpService} from "../http/IHttp.service";

export class AdminsService implements IAdminsService {
    constructor(
        private readonly _httpService: IHttpService,
    ) { }

    public async createAdmin(adminData: IAdminWithPassword): Promise<string | null> {
        const { resourceId: adminId } = await this._httpService.adminsPost(adminData);
        return adminId;
    }

    public async editAdmin(adminId: string, adminData: Partial<IAdminWithPassword>): Promise<boolean> {
        return await this._httpService.adminsPut(adminId, adminData);
    }

    public async deleteAdmin(adminId: string): Promise<void> {
        await this._httpService.adminsDelete(adminId);
    }

    public async getAdmins(): Promise<IAdmin[]> {
        return await this._httpService.adminsGet();
    }
}
