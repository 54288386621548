import React from 'react';
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";
import AddNewCarButton from "./AddNewCarButton";
import {ICar} from "../../../../core/models/ICar";
import {LinearProgress} from "@material-ui/core";
import EventDetailsCarsTable from "./EventDetailsCarsTable";
import EventCarDetails from "./EventCarDetails";
import ImportCarsButton from "./import/ImportCarsButton";

interface IEventDetailsCarsProps extends ICoreContainerRequired {
    eventId: string;
}

interface IEventDetailsCarsState {
    carsFetched: boolean;
    cars: ICar[];
    carId: string | null;
}

class EventDetailsCars extends React.Component<IEventDetailsCarsProps, IEventDetailsCarsState> {
    state: IEventDetailsCarsState = {
        carsFetched: true,
        cars: [],
        carId: null,
    };

    constructor(props: IEventDetailsCarsProps) {
        super(props);

        const { carId } = this.props.coreContainer.IAppRouter.parseQuery();
        if (carId) {
            this.state.carId = carId;
        }
    }

    componentDidMount(): void {
        this.fetchCars();
    }

    fetchCars = async () => this.setState({
        carsFetched: true,
        cars: await this.props.coreContainer.IEventCarsService.getCars(this.props.eventId),
    });

    onCarClick = (carId: string) => {
        this.props.coreContainer.IAppRouter.changeQueryField('carId', carId);

        this.setState({
            carId,
        });
    };

    onCarCloseRequest = () => {
        this.props.coreContainer.IAppRouter.changeQueryField('carId', undefined);
        this.setState({
            carId: null,
        }, () => this.fetchCars())
    };

    render() {
        if (this.state.carId) {
            return (
                <EventCarDetails
                    coreContainer={this.props.coreContainer}
                    eventId={this.props.eventId}
                    carId={this.state.carId}
                    onCloseRequest={this.onCarCloseRequest}
                />
            );
        }

        return (
            <React.Fragment>
                <AddNewCarButton
                    eventId={this.props.eventId}
                    onCarAdd={this.fetchCars}
                    coreContainer={this.props.coreContainer}
                />

                <ImportCarsButton
                    coreContainer={this.props.coreContainer}
                    eventCars={this.state.cars}
                    eventId={this.props.eventId}
                    fetchCars={this.fetchCars}
                />

                {this.state.carsFetched ? (
                    <EventDetailsCarsTable
                        cars={this.state.cars}
                        onCarClick={this.onCarClick}
                    />
                ) : <LinearProgress />}
            </React.Fragment>
        );
    }
}

export default EventDetailsCars;
