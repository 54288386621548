import React, {useState} from 'react';
import {
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, TextField,
    Theme,
    Typography,
    WithStyles,
    withStyles
} from "@material-ui/core";
import {ICoreContainerRequired} from "../../core/ICoreContainer";
import {IDialog} from "../../core/dialogsController/IDialogsController";
import {DialogTypesEnum} from "../../core/dialogsController/DialogTypesEnum";
import FormDialog from "./FormDialog";

const styles = (theme: Theme) => createStyles({

});

export interface IModalsComponentProps extends ICoreContainerRequired, WithStyles<typeof styles> {

}

class DialogsComponent extends React.Component<IModalsComponentProps> {
    private _subscriptionId: number | undefined;

    componentDidMount() {
        this._subscriptionId = this.props.coreContainer.IDialogsController.subscribe(() => {
            this.forceUpdate();
        });
    }

    componentWillUnmount() {
        if (this._subscriptionId) {
            this.props.coreContainer.IDialogsController.unsubscribe(this._subscriptionId);
        }
    }

    render() {
        return this.props.coreContainer.IDialogsController.getDialogs().map((dialog: IDialog) => (
            <Dialog key={dialog.key} open={true} maxWidth={'sm'} fullWidth>
                {dialog.title ? (
                    <DialogTitle>
                        {dialog.title}
                    </DialogTitle>
                ) : null}

                {(() => {
                    switch (dialog.type) {
                        case DialogTypesEnum.confirm: return dialog.confirmDialog ? (
                            <ConfirmDialog
                                question={dialog.confirmDialog.question}
                                onFinish={dialog.onFinish}
                            />) : null;
                        case DialogTypesEnum.prompt: return dialog.promptDialog ? (
                            <PromptDialog
                                question={dialog.promptDialog.question}
                                defaultValue={dialog.promptDialog.defaultValue ? dialog.promptDialog.defaultValue : ''}
                                onFinish={dialog.onFinish}
                            />) : null;
                        case DialogTypesEnum.form: return dialog.formDialog ? (
                            <FormDialog
                                onConfirm={(result) => dialog.onFinish(result)}
                                onCancel={() => dialog.onFinish(undefined)}
                                {...dialog.formDialog.params}
                            />
                        ) : null;
                        default: return null;
                    }
                })()}
            </Dialog>
        ));
    }
}

export default withStyles(styles)(DialogsComponent);

interface IPromptDialogProps {
    question: string;
    defaultValue: string;
    onFinish: (confirm: string) => any;
}

const PromptDialog = (props: IPromptDialogProps) => {
    const [
        value,
        setValue,
    ] = useState<string>(props.defaultValue);

    return (
        <React.Fragment>
            <DialogContent>
                <Typography>{props.question}</Typography>
                <TextField
                    value={value}
                    onChange={(event) => setValue(event.currentTarget.value)}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button color={'primary'} onClick={() => props.onFinish(value)}>Confirm</Button>
                <Button color={'secondary'} onClick={() => props.onFinish('')}>Cancel</Button>
            </DialogActions>
        </React.Fragment>
    );
};

interface IConfirmDialogProps {
    question: string;
    onFinish: (confirm: boolean) => any;
}

const ConfirmDialog = (props: IConfirmDialogProps) => {
    return (
        <React.Fragment>
            <DialogContent>
                <Typography>{props.question}</Typography>
            </DialogContent>
            <DialogActions>
                <Button color={'primary'} onClick={() => props.onFinish(true)}>Confirm</Button>
                <Button color={'secondary'} onClick={() => props.onFinish(false)}>Cancel</Button>
            </DialogActions>
        </React.Fragment>
    );
};
