import React from 'react';
import {Card, CardContent, createStyles, Theme, Typography, WithStyles, withStyles} from "@material-ui/core";
import {ICar} from "../../../../core/models/ICar";
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";

const styles = (theme: Theme) => createStyles({
    root: {
        minWidth: 275,
        marginTop: 10,
        marginBottom: 10,
        '&:hover': {
            backgroundColor: theme.palette.divider,
            cursor: 'pointer',
        },
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

interface ICarCardProps extends ICoreContainerRequired, WithStyles<typeof styles> {
    car: ICar;
    onClick: () => any;
}

const CarCard = (props: ICarCardProps) => {
    const {
        classes,
        car,
        onClick,
    } = props;

    return (
        <Card
            className={classes.root}
            onClick={onClick}
        >
            <CardContent>
                <Typography
                    variant={'h5'}
                    component={'h2'}
                >{car.licensePlate}</Typography>
                <Typography
                    className={classes.pos}
                    color={'textSecondary'}
                >{car.name}</Typography>
            </CardContent>
        </Card>
    );
};

export default withStyles(styles)(CarCard);
