import React from 'react';
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";
import {Grid, LinearProgress} from "@material-ui/core";
import EventScheduleNewDay from "./EventScheduleNewDay";
import {IScheduleDay} from "../../../../core/models/IScheduleDay";
// import EventScheduleDay from "./EventScheduleDay";
import {IScheduleActivityType} from "../../../../core/models/IScheduleActivityType";
import {IEventDetails} from "../../../../core/models/IEvent";
import EventScheduleDay2 from "./EventScheduleDay2";

interface IEventScheduleProps extends ICoreContainerRequired {
    eventId: string;
    eventDetails: IEventDetails;
}

interface IEventScheduleState {
    days: IScheduleDay[];
    daysFetched: boolean;
    activityTypes: IScheduleActivityType[];
    activityTypesFetched: boolean;
}

class EventSchedule extends React.Component<IEventScheduleProps, IEventScheduleState> {
    state: IEventScheduleState = {
        days: [],
        daysFetched: false,
        activityTypes: [],
        activityTypesFetched: false,
    };

    componentDidMount() {
        this.fetchDays();
        this.fetchActivityTypes();
    }

    fetchDays = async () => this.setState({
        days: await this.props.coreContainer.IHttpService.eventScheduleGetDays(this.props.eventId),
        daysFetched: true,
    });

    fetchActivityTypes = async () => this.setState({
        activityTypes: await this.props.coreContainer.IHttpService.settingsGetActivityTypes(),
        activityTypesFetched: true,
    });

    checkNewDayDefaultDate = (): string => {
        if (!this.state.days.length) {
            return this.props.eventDetails.dateFrom;
        }

        return this.state.days[this.state.days.length - 1].date;
    };

    render() {
        if (!this.state.daysFetched || !this.state.activityTypesFetched) {
            return <LinearProgress />
        }

        return (
            <Grid container spacing={2}>
                {this.state.days.map((day) => (
                    <EventScheduleDay2
                        key={day.dayId}
                        eventId={this.props.eventId}
                        dayDetails={day}
                        coreContainer={this.props.coreContainer}
                        fetchDays={this.fetchDays}
                        activityTypes={this.state.activityTypes}
                    />
                ))}

                {/*{this.state.days.map((day) => (*/}
                {/*    <EventScheduleDay*/}
                {/*        key={day.dayId}*/}
                {/*        eventId={this.props.eventId}*/}
                {/*        dayDetails={day}*/}
                {/*        coreContainer={this.props.coreContainer}*/}
                {/*        fetchDays={this.fetchDays}*/}
                {/*        activityTypes={this.state.activityTypes}*/}
                {/*    />*/}
                {/*))}*/}

                <EventScheduleNewDay
                    eventId={this.props.eventId}
                    coreContainer={this.props.coreContainer}
                    fetchDays={this.fetchDays}
                    defaultDate={this.checkNewDayDefaultDate()}
                />
            </Grid>
        );
    }
}

export default EventSchedule;
