import {IEventsService} from "./IEventsService";
import {IEvent, IEventDetails, IEventEditModel, IEventInsertModel} from "../models/IEvent";
import {IHttpService} from "../http/IHttp.service";

export class EventsService implements IEventsService {
    constructor(
        private readonly _httpService: IHttpService,
    ) { }

    public async addNewEvent(eventData: IEventInsertModel): Promise<string | null> {
        const { resourceId: eventId } = await this._httpService.eventsPost(eventData);
        return eventId;
    }

    public async editEvent(eventId: string, eventData: IEventEditModel): Promise<boolean> {
        try {
            return await this._httpService.eventsPut(eventId, eventData);
        } catch {
            return false;
        }
    }

    public async getEvent(eventId: string): Promise<IEventDetails> {
        const event = await this._httpService.eventsGet(eventId);

        return {
            eventId: event.eventId,
            name: event.name,
            dateFrom: event.dateFrom,
            dateTo: event.dateTo,
            isActive: event.isActive,
            mapRefreshingEnabled: event.mapRefreshingEnabled,
            postsCount: event.postsCount,
            postsCommentsCount: event.postsCommentsCount,
            postsLikesCount: event.postsLikesCount,
            carsCount: event.carsCount,
            usersCount: event.usersCount,
            locationsCount: event.locationsCount,
            locationsSize: event.locationsSize,
            webMapEnabled: event.webMapEnabled,
            mapCarsVisible: event.mapCarsVisible,
            mapUsersVisible: event.mapUsersVisible,
            scheduleDaysCount: event.scheduleDaysCount,
            scheduleActivitiesCount: event.scheduleActivitiesCount,
            abuseReportsToReviewCount: event.abuseReportsToReviewCount,
            scheduleToken: event.scheduleToken,
            scheduleSourceEventId: event.scheduleSourceEventId,
        };
    }

    public async getEvents(): Promise<IEvent[]> {
        return await this._httpService.eventsGet();
    }
}
