import React, {BaseSyntheticEvent} from 'react';
import {
    Button,
    createStyles,
    Dialog,
    DialogActions, DialogContent,
    DialogTitle, TextField,
    Theme,
    withStyles,
    WithStyles
} from "@material-ui/core";
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";
import AddIcon from "@material-ui/icons/Add";
import {IEventInsertModel} from "../../../../core/models/IEvent";
import {AppPaths} from "../../../../core/router/AppPaths";

const styles = (theme: Theme) => createStyles({
    addNewEventButton: {
        marginBottom: 20,
    },
});

interface INewEventButtonProps extends ICoreContainerRequired, WithStyles<typeof styles> {
    onAdd: () => any;
}

interface INewEventButtonState {
    formOpened: boolean;
    eventData: IEventInsertModel;
}

class NewEventButton extends React.Component<INewEventButtonProps, INewEventButtonState> {
    private readonly _defaultEventData: IEventInsertModel = {
        name: '',
        dateFrom: '',
        dateTo: '',
    };

    constructor(props: INewEventButtonProps) {
        super(props);

        this.state = {
            formOpened: false,
            eventData: {
                ...this._defaultEventData,
            },
        };
    }

    openEventForm = () => this.setState({
        formOpened: true,
    });

    closeEventForm = () => this.setState({
        formOpened: false,
        eventData: {
            ...this._defaultEventData,
        },
    });

    datesAreValid = (): boolean => {
        const {
            dateFrom,
            dateTo,
        } = this.state.eventData;

        if (!dateFrom || !dateTo) {
            return true;
        }

        return dateTo >= dateFrom;
    };

    canBeAdded = (): boolean => {
        return (
            this.datesAreValid() &&
            !!this.state.eventData.name &&
            !!this.state.eventData.dateFrom &&
            !!this.state.eventData.dateTo
        );
    };

    addEvent = async () => {
        const eventData: IEventInsertModel = {
            ...this.state.eventData,
        };

        this.closeEventForm();

        const eventId: string | null = await this.props.coreContainer.IEventsService.addNewEvent(eventData);

        if (!eventId) {
            this.props.coreContainer.ISnackbarsController.show({
                message: 'Event create error',
                severity: 'error',
            });

            return;
        }

        if (await this.props.coreContainer.IDialogsController.confirm(`Do you want to open ${eventData.name} details?`)) {
            this.props.coreContainer.IAppRouter.push(AppPaths.Events + `/${eventId}`);
        } else {
            this.props.onAdd();
        }
    };

    onFieldChange = (fieldName: string) => (event: BaseSyntheticEvent) => this.setState({
        eventData: {
            ...this.state.eventData,
            [fieldName]: event.currentTarget.value,
        },
    });

    render() {
        return (
            <React.Fragment>
                <Button
                    size={'large'}
                    variant={'outlined'}
                    color={'primary'}
                    startIcon={<AddIcon />}
                    className={this.props.classes.addNewEventButton}
                    onClick={this.openEventForm}
                >Add new Instance</Button>

                {this.state.formOpened ? (
                    <Dialog
                        open={this.state.formOpened}
                        onClose={this.closeEventForm}
                        maxWidth={'sm'}
                        fullWidth={true}
                    >
                        <DialogTitle>Add new Instance</DialogTitle>
                        <DialogContent>

                            <TextField
                                label={'Name'}
                                fullWidth
                                value={this.state.eventData.name}
                                onChange={this.onFieldChange('name')}
                                required
                            />

                            <TextField
                                label={'Date From'}
                                fullWidth
                                value={this.state.eventData.dateFrom}
                                onChange={this.onFieldChange('dateFrom')}
                                type={'date'}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />

                            <TextField
                                label={'Date To'}
                                fullWidth
                                value={this.state.eventData.dateTo}
                                onChange={this.onFieldChange('dateTo')}
                                type={'date'}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!this.datesAreValid()}
                                helperText={!this.datesAreValid() ? 'Date To must be after Date From' : null}
                                required
                            />

                        </DialogContent>
                        <DialogActions>
                            <Button color={'primary'} onClick={this.addEvent} disabled={!this.canBeAdded()}>Add Event</Button>
                            <Button color={'secondary'} onClick={this.closeEventForm}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                ) : null}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(NewEventButton);
