import React from 'react';
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";
import {IEvent} from "../../../../core/models/IEvent";
import {
    Card,
    CardContent,
    createStyles,
    Grid,
    LinearProgress,
    Theme,
    Typography,
    withStyles,
    WithStyles
} from "@material-ui/core";
import moment from "moment";
import EventDateLabel from "../../events/list/EventDateLabel";

const styles = (theme: Theme) => createStyles({
    eventGridItem: {

    },
    eventCard: {
        '&:hover': {
            cursor: 'pointer',
            boxShadow: '0px 2px 1px -1px rgba(0,155,229,1)',
            backgroundColor: `rgba(249, 253, 255, 1)`
        },
    },
});

interface IUpcomingEventsProps extends ICoreContainerRequired, WithStyles<typeof styles> {

}

interface IUpcomingEventsState {
    events: IEvent[]
    fetched: boolean;
}

class UpcomingEvents extends React.Component<IUpcomingEventsProps, IUpcomingEventsState> {
    state: IUpcomingEventsState = {
        events: [],
        fetched: false,
    };

    componentDidMount(): void {
        this.fetchEvents();
    }

    fetchEvents = async () => {
        const events: IEvent[] = await this.props.coreContainer.IEventsService.getEvents();

        this.setState({
            events,
            fetched: true,
        });
    };

    getUpcomingEvents = () => {
        const todayDate: string = moment().format('YYYY-MM-DD');
        return this.state.events
            .filter((event) => (event.dateFrom >= todayDate) || (event.dateTo >= todayDate) || event.isActive)
            .sort((left, right) => {
                return left.dateFrom > right.dateFrom ? 1 : -1;
            });
    };

    onNavigateToEventClick = (eventId: string) => () => this.props.coreContainer.IAppRouter.push(`/events/${eventId}?view=general`);

    render() {
        const upcomingEvents = this.getUpcomingEvents();

        return (
            <Grid container spacing={4}>
                {this.state.fetched ? (
                    <React.Fragment>
                        <Grid item xs={12} style={{paddingTop: 0, paddingBottom: 0}}>
                            <Typography
                                variant={'h5'}
                            >Active Instances</Typography>
                        </Grid>
                        {upcomingEvents.length ? upcomingEvents.map((event) => (
                            <Grid item xs={12} md={4} className={this.props.classes.eventGridItem}>
                                <Card className={this.props.classes.eventCard} onClick={this.onNavigateToEventClick(event.eventId)}>
                                    <CardContent>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant={'h5'}
                                                >{event.name}</Typography>
                                            </Grid>
                                            <Grid item xs={12} style={{marginTop: 8}}>
                                                <EventDateLabel
                                                    dateFrom={event.dateFrom}
                                                    dateTo={event.dateTo}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )) : (
                            <Grid item xs={12}>
                                No upcoming events
                            </Grid>
                        )}
                    </React.Fragment>
                ) : (
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                )}
            </Grid>
        );
    }
}

export default withStyles(styles)(UpcomingEvents);
