import React from 'react';
import {ICoreContainerRequired} from "../../core/ICoreContainer";

interface IImageProps extends ICoreContainerRequired, React.ImgHTMLAttributes<any> {
    imageId: string;
}

const Image = (props: IImageProps) => {
    const {
        imageId,
        coreContainer,
        ...imgProps
    } = props;

    return (
        <img
            src={`${coreContainer.IConfig.cdnBaseUrl}/${imageId}`}
            alt={'Something'}
            {...imgProps}
        />
    )
};

export default Image;
