import React from 'react';
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";
import {
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableRow,
    TextField
} from "@material-ui/core";
import {IScheduleActivityType} from "../../../../core/models/IScheduleActivityType";
import Image from "../../../usages/Image";
import FileUploadButton from "../../../usages/FileUploadButton";

interface IScheduleActivityTypesProps extends ICoreContainerRequired {

}

interface IScheduleActivityTypesState {
    activities: IScheduleActivityType[];
    newName: string;
    newImageId: string | null;
}

class ScheduleActivityTypes extends React.Component<IScheduleActivityTypesProps, IScheduleActivityTypesState> {
    state: IScheduleActivityTypesState = {
        activities: [],
        newName: '',
        newImageId: null,
    };

    componentDidMount(): void {
        this.fetchActivities();
    }

    fetchActivities = async () => {
        this.setState({
            activities: await this.props.coreContainer.IHttpService.settingsGetActivityTypes(),
        });
    };

    onFileUpload = (fileId: string) => this.setState({
        newImageId: fileId,
    });

    saveNewType = async () => {
        if (!this.state.newImageId) {
            return;
        }

        await this.props.coreContainer.IHttpService.settingsAddActivityType({
            name: this.state.newName,
            icon: this.state.newImageId,
        });

        this.setState({
            newImageId: null,
            newName: '',
        });

        this.fetchActivities();
    };

    removeType = async (typeId: string) => {
        await this.props.coreContainer.IHttpService.settingsDeleteActivityType(typeId);
        this.fetchActivities();
    };

    render() {
        return (
            <Grid container>
                <Grid item xs={12} md={4}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align={'center'}>Icon</TableCell>
                                <TableCell align={'center'}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.activities.map((type) => (
                                <TableRow key={type.typeId}>
                                    <TableCell>{type.name}</TableCell>
                                    <TableCell align={'center'}>
                                        {type.icon ? (
                                            <Image
                                                imageId={type.icon}
                                                coreContainer={this.props.coreContainer}
                                                style={{width: 50}}
                                            />
                                        ) : null}
                                    </TableCell>
                                    <TableCell align={'center'}>
                                        <Button
                                            size={'small'}
                                            variant={'outlined'}
                                            color={'secondary'}
                                            onClick={() => this.removeType(type.typeId)}
                                        >
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell>
                                    <TextField
                                        onChange={(event) => this.setState({newName: event.target.value})}
                                        value={this.state.newName}
                                        fullWidth
                                        label={'New type name'}
                                        size={'small'}
                                    />
                                </TableCell>
                                <TableCell align={'center'} style={{width: 140}}>
                                    {this.state.newImageId ? (
                                        <Image
                                            imageId={this.state.newImageId}
                                            coreContainer={this.props.coreContainer}
                                            style={{
                                                width: '50px',
                                            }}
                                        />
                                    ) : (
                                        <FileUploadButton
                                            onUpload={this.onFileUpload}
                                            coreContainer={this.props.coreContainer}
                                            variant={'outlined'}
                                            color={'primary'}
                                            size={'small'}
                                            targetWidth={200}
                                        >
                                            Upload icon
                                        </FileUploadButton>
                                    )}
                                </TableCell>
                                <TableCell align={'center'} style={{width: 50}}>
                                    <Button
                                        variant={'outlined'}
                                        color={'primary'}
                                        onClick={this.saveNewType}
                                        size={'small'}
                                    >Add</Button>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </Grid>
            </Grid>
        );
    }
}

export default ScheduleActivityTypes;
