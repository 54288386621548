import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import clsx from "clsx";
import ListItem from "@material-ui/core/ListItem";
import React from "react";
import ItemClass from "./styleClasses/ItemClass";

const styles = (theme: Theme) => createStyles({
    firebase: {
        fontSize: 24,
        color: theme.palette.common.white,
    },
    item: ItemClass,
    itemCategory: {
        backgroundColor: '#232f3e',
        boxShadow: '0 -1px 0 #404854 inset',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    icon: {
        height: 112,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
});

interface INavigatorHeaderProps extends WithStyles<typeof styles> {
    iconUrl: string;
}

function NavigatorHeader(props: INavigatorHeaderProps): JSX.Element {
    const {
        classes,
        iconUrl,
    } = props;

    return (
        <ListItem
            className={clsx(classes.firebase, classes.item, classes.itemCategory)}
        >
            <img
                className={classes.icon}
                src={iconUrl}
                alt={'Gran Turismo Driver Admin'}
            />
        </ListItem>
    );
}

export default withStyles(styles)(NavigatorHeader);
