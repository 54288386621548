import React from 'react';
import {ICoreContainerRequired} from "../../core/ICoreContainer";
import {ISnackbarOptions} from "../../core/snackbarsController/ISnackbarsController";
import {Snackbar, SnackbarOrigin} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import {snackbarLocationEnum} from "../../core/snackbarsController/snackbarLocationEnum";

interface ISnackbarsComponentProps extends ICoreContainerRequired {

}

interface ISnackbarsComponentState {
    snackbars: ISnackbarOptions[];
}

class SnackbarsComponent extends React.Component<ISnackbarsComponentProps, ISnackbarsComponentState> {
    state: ISnackbarsComponentState = {
        snackbars: [],
    };

    private subscriptionId: number | undefined;

    componentDidMount() {
        this.subscriptionId = this.props.coreContainer.ISnackbarsController.subscribe((snackbar: ISnackbarOptions) => {
            this.setState({
                snackbars: [
                    ...this.state.snackbars,
                    snackbar,
                ],
            });
        });
    }

    componentWillUnmount(): void {
        if (this.subscriptionId) {
            this.props.coreContainer.ISnackbarsController.unsubscribe(this.subscriptionId);
        }
    }

    onSnackbarClose = (componentId: string) => {
        this.setState({
            snackbars: this.state.snackbars.map((snackbar) => snackbar.componentId !== componentId ? snackbar : ({
                ...snackbar,
                displayed: false,
            }))
        });

        setTimeout(() => {
            this.setState({
                snackbars: this.state.snackbars.filter((s) => s.componentId !== componentId)
            });
        }, 500);
    };

    calculateSnackbarOrigin = (location: snackbarLocationEnum): SnackbarOrigin => {
        switch (location) {
            case snackbarLocationEnum.topRight: return {
                vertical: 'top',
                horizontal: 'right',
            };
            case snackbarLocationEnum.bottomRight: return {
                vertical: 'bottom',
                horizontal: 'right',
            };
        }
    };

    render() {
        return this.state.snackbars.map((snackbar: ISnackbarOptions) => (
            <Snackbar
                anchorOrigin={this.calculateSnackbarOrigin(snackbar.location)}
                key={snackbar.componentId}
                open={snackbar.displayed}
                onClose={() => this.onSnackbarClose(snackbar.componentId)}
                autoHideDuration={snackbar.displayDuration}
            >
                <MuiAlert severity={snackbar.severity}>
                    {snackbar.message}
                </MuiAlert>
            </Snackbar>
        ));
    }
}

export default SnackbarsComponent;
