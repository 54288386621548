import {IEventRoutesService} from "./IEventRoutes.service";
import {IRoute, IRouteInsertModel} from "../models/IRoute";
import {IHttpService} from "../http/IHttp.service";

export class EventRoutesService implements IEventRoutesService {
    constructor(
        private readonly _httpService: IHttpService,
    ) { }

    public async createRoute(eventId: string, routeData: IRouteInsertModel): Promise<string | null> {
        const result = await this._httpService.eventRoutesCreateRoute(
            eventId,
            routeData,
        );

        return result.resourceId;
    }

    public async getRoute(eventId: string, routeId: string): Promise<IRoute> {
        return await this._httpService.eventRoutesGetRoute(eventId, routeId);
    }

    public async deleteRoute(eventId: string, routeId: string): Promise<boolean> {
        return await this._httpService.eventRoutesDeleteRoute(eventId, routeId);
    }

    public async editRoute(
        eventId: string,
        routeId: string,
        routeData: IRouteInsertModel,
    ): Promise<void> {
        await this._httpService.eventRoutesEditRoute(
            eventId,
            routeId,
            routeData,
        );
    }

    public async getRoutes(eventId: string): Promise<IRoute[]> {
        return await this._httpService.eventRoutesGetRoutes(eventId);
    }
}
