import React from 'react';
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";
import {
    Button, ButtonGroup,
    Chip,
    createStyles,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Theme, withStyles,
    WithStyles
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import AdminsForm from "../form/AdminsForm";
import {IAdmin} from "../../../../core/models/IAdmin";

const styles = (theme: Theme) => createStyles({
    addNewAdminButton: {
        marginBottom: 20,
    },
});

interface IAdminsListProps extends ICoreContainerRequired, WithStyles<typeof styles> {

}

interface IAdminsListState {
    addNewAdmin: boolean;
    adminToEdit: IAdmin | null;
    admins: IAdmin[];
}

class AdminsList extends React.Component<IAdminsListProps, IAdminsListState> {
    state = {
        addNewAdmin: false,
        adminToEdit: null,
        admins: [

        ],
    };

    componentDidMount() {
        this.fetchAdmins();
    }

    fetchAdmins = async () => {
        const admins: IAdmin[] = await this.props.coreContainer.IAdminsService.getAdmins();

        this.setState({
            admins,
        });
    };

    addNewAdmin = () => {
        this.setState({
            addNewAdmin: true,
            adminToEdit: null,
        });
    };

    editAdmin = (admin: IAdmin) => {
        this.setState({
            addNewAdmin: false,
            adminToEdit: admin,
        });
    };

    changePassword = async (admin: IAdmin) => {
        const newPassword = await this.props.coreContainer.IDialogsController.prompt('Enter new password (min. 8 characters)');
        if (!newPassword) {
            return;
        }

        if (newPassword.length < 8) {
            this.props.coreContainer.ISnackbarsController.show({
                message: 'Password must be min. 8 characters long',
                severity: 'error',
            });
            return;
        }

        const confirmedPassword = await this.props.coreContainer.IDialogsController.prompt(`Please confirm password`);
        if (newPassword !== confirmedPassword) {
            this.props.coreContainer.ISnackbarsController.show({
                message: `Passwords doesn't match`,
                severity: 'error',
            });
            return;
        }

        const changeResult = await this.props.coreContainer.IAdminsService.editAdmin(admin.adminId, {
            password: newPassword,
        });

        if (changeResult) {
            this.props.coreContainer.ISnackbarsController.show({
                message: `${admin.name}'s password changed successfully`,
                severity: 'success',
            });
        } else {
            this.props.coreContainer.ISnackbarsController.show({
                message: `${admin.name}'s password change error`,
                severity: 'error',
            });
        }
    };

    deleteAdmin = async (admin: IAdmin) => {
        if (!(await this.props.coreContainer.IDialogsController.confirm(`Do you really want to remove ${admin.name}?`))) {
            return;
        }

        await this.props.coreContainer.IAdminsService.deleteAdmin(admin.adminId);

        this.fetchAdmins();
    };

    onEditFinish = () => {
        this.setState({
            addNewAdmin: false,
            adminToEdit: null,
        }, () => {
            this.fetchAdmins();
        });
    };

    getAdminsSorted = () => this.state.admins.sort((left: IAdmin, right: IAdmin) => left.name > right.name ? 1 : -1);

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Button
                    size={'large'}
                    variant={'outlined'}
                    color={'primary'}
                    startIcon={<AddIcon />}
                    className={classes.addNewAdminButton}
                    onClick={this.addNewAdmin}
                >Add new Admin</Button>

                <Table>
                    <TableBody>
                        {this.getAdminsSorted().map((admin: IAdmin) => (
                            <TableRow key={admin.adminId}>
                                <TableCell>{admin.name}</TableCell>
                                <TableCell>{admin.email}</TableCell>
                                <TableCell align={'center'} style={{width: 120}}>
                                    <Chip
                                        label={admin.status ? 'Active' : 'Disabled'}
                                        color={admin.status ? 'primary' : 'primary'}
                                        variant={'outlined'}
                                    />
                                </TableCell>
                                <TableCell align={'center'} style={{width: 120}}>{admin.superAdmin ? (
                                    <Chip label={'Super Admin'} color={'primary'} variant={'outlined'} />
                                ) : (
                                    <Chip label={'Admin'} color={'primary'} variant={'outlined'} />
                                )}</TableCell>
                                <TableCell align={'center'} style={{width: 350}}>
                                    <ButtonGroup>
                                        <Button
                                            variant={'outlined'}
                                            color={'primary'}
                                            onClick={() => this.editAdmin(admin)}
                                        >Edit</Button>
                                        <Button
                                            variant={'outlined'}
                                            color={'primary'}
                                            onClick={() => this.changePassword(admin)}
                                        >Change password</Button>
                                        <Button
                                            variant={'outlined'}
                                            color={'primary'}
                                            onClick={() => this.deleteAdmin(admin)}
                                            disabled={admin.adminId === this.props.coreContainer.IAuthStore.get().adminData.adminId}
                                        >Delete</Button>
                                    </ButtonGroup>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                {this.state.addNewAdmin || this.state.adminToEdit ? (
                    <AdminsForm
                        adminToEdit={this.state.adminToEdit}
                        coreContainer={this.props.coreContainer}
                        onFinish={this.onEditFinish}
                        selfEdit={this.state.adminToEdit ? ((this.state.adminToEdit as unknown as IAdmin).adminId === this.props.coreContainer.IAuthStore.get().adminData.adminId) : false}
                    />
                ) : null}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(AdminsList);
