import React from 'react';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import NavigatorItem from "./navigator/NavigatorItem";
import NavigatorCategoryHeader from "./navigator/NavigatorCategoryHeader";
import NavigatorHeader from "./navigator/NavigatorHeader";
import NavigatorDivider from "./navigator/NavigatorDivider";
import PubSubWatcher from "../core/pubSub/PubSubWatcher";
import {ICoreContainerRequired} from "../core/ICoreContainer";

export interface INavigatorProps extends ICoreContainerRequired, DrawerProps {

}

class Navigator extends React.Component<INavigatorProps> {
    private _watcher: PubSubWatcher | undefined;

    componentDidMount(): void {
        this._watcher = new PubSubWatcher(this.props.coreContainer.IMenuController as any, this);
    }

    componentWillUnmount(): void {
        if (this._watcher) {
            this._watcher.unmount();
        }
    }

    render() {
        const {
            classes,
            coreContainer,
            ...drawerProps
        } = this.props;

        return (
            <Drawer variant="permanent" {...drawerProps}>
                <List disablePadding>
                    <NavigatorHeader
                        iconUrl={`${coreContainer.IConfig.cdnBaseUrl}/app-icon-admin.png`}
                    />

                    {this.props.coreContainer.IMenuController.getMenu().map((menuRow, rowIndex: number) => {
                        if (!menuRow) {
                            return (
                                <NavigatorDivider
                                    key={rowIndex}
                                />
                            );
                        }

                        if (typeof menuRow === 'string') {
                            return (
                                <NavigatorCategoryHeader
                                    key={rowIndex}
                                    text={menuRow}
                                />
                            );
                        }

                        if (typeof menuRow === 'object') {
                            return (
                                <NavigatorItem
                                    key={rowIndex}
                                    active={menuRow.active}
                                    icon={menuRow.icon}
                                    text={menuRow.text}
                                    onClick={menuRow.action}
                                />
                            );
                        }

                        return null;
                    })}
                </List>
            </Drawer>
        );
    }
}

export default Navigator;
