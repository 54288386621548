import {createStyles, Theme, WithStyles, withStyles} from "@material-ui/core";
// import Copyright from "./Copyright";
import React from "react";

const styles = (theme: Theme) => createStyles({
    footer: {
        padding: theme.spacing(2),
        background: '#eaeff1',
    },
});

export interface IFooterProps extends WithStyles<typeof styles> {

}

function Footer(props: IFooterProps): JSX.Element {
    const {
        classes,
    } = props;

    return (
        <footer className={classes.footer}>
            {/*<Copyright />*/}
        </footer>
    );
}

export default withStyles(styles)(Footer);
