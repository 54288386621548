import React from 'react';
import {
    createStyles,
    ExpansionPanel, ExpansionPanelDetails,
    ExpansionPanelSummary,
    Theme,
    Typography,
    withStyles,
    WithStyles
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const styles = (theme: Theme) => createStyles({
    expansionPanelSummaryTypography: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
});

interface IExpandablePanelProps extends WithStyles<typeof styles> {
    defaultExpanded?: boolean;
    label: string;
    children: React.ReactNode;
}

const ExpandablePanel = (props: IExpandablePanelProps) => {
    return (
        <ExpansionPanel defaultExpanded={!!props.defaultExpanded}>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
            >
                <Typography className={props.classes.expansionPanelSummaryTypography}>{props.label}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                {props.children}
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default withStyles(styles)(ExpandablePanel);
