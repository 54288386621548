import {IFilesService} from "./IFiles.service";
import {IHttpService} from "../http/IHttp.service";
import Axios from 'axios';

export class FilesService implements IFilesService {
    constructor(
        private readonly _httpService: IHttpService,
    ) { }

    public async getFileContent(fileId: string): Promise<any> {
        const response = await this._httpService.fileGet(fileId);
        // console.log(response);
        // return `data:${response.headers['content-type']};base64,${Buffer.from(response.data, 'binary').toString('base64')}`;
        const image = btoa(
            new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        return `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
    }

    public async uploadFile(file: File, targetWidth?: number): Promise<string | null> {
        try {
            const s3credentials = await this._httpService.s3uploadCreate();

            const formData = new FormData();
            formData.append('Content-Type', file.type);
            Object.entries(s3credentials.fields).forEach(([k, v]) => {
               formData.append(k, v as string);
            });
            formData.append('file', file);

            try {
                await Axios.post(s3credentials.url, formData);
                await this._httpService.s3uploadCommit(s3credentials.fileId);
                return s3credentials.fileId;
            } catch (err) {
                console.log(err);
                return null;
            }
        } catch (err) {
            console.log(err);
            return null;
        }
    }
}
