import {IAuthService} from "./auth/IAuthService";
import {AuthService} from "./auth/AuthService";
import {Config} from "./config/Config";
import {ICoreContainer} from "./ICoreContainer";
import {IConfig} from "./config/IConfig";
import {IMenuController} from "./menuController/IMenuController";
import {IEventsHub} from "./eventsHub/IEventsHub";
import {EventsHub} from "./eventsHub/EventsHub";
import {IAppRouter} from "./router/IAppRouter";
import {AppRouter} from "./router/AppRouter";
import {MenuController} from "./menuController/MenuController";
import {IHttpService} from "./http/IHttp.service";
import {HttpService} from "./http/Http.service";
import {IAuthStore} from "./auth/IAuthStore";
import {AuthStore} from "./auth/AuthStore";
import {IAuthTokenStore} from "./auth/IAuthTokenStore";
import {AuthTokenStore} from "./auth/AuthTokenStore";
import {IAdminsService} from "./admins/IAdmins.service";
import {AdminsService} from "./admins/Admins.service";
import {IDialogsController} from "./dialogsController/IDialogsController";
import {DialogsController} from "./dialogsController/DialogsController";
import {IEventsService} from "./events/IEventsService";
import {EventsService} from "./events/EventsService";
import {IEventUsersService} from "./eventUsers/IEventUsersService";
import {EventUsersService} from "./eventUsers/EventUsersService";
import {IEventCarsService} from "./eventCars/IEventCars.service";
import {EventCarsService} from "./eventCars/EventCars.service";
import {IFilesService} from "./files/IFiles.service";
import {FilesService} from "./files/Files.service";
import {IEventPostsService} from "./eventPosts/IEventPosts.service";
import {EventPostsService} from "./eventPosts/EventPosts.service";
import {IGoogleApiService} from "./googleApi/IGoogleApi.service";
import {GoogleApiService} from "./googleApi/GoogleApi.service";
import {IEventRoutesService} from "./eventRoutes/IEventRoutes.service";
import {EventRoutesService} from "./eventRoutes/EventRoutes.service";
import {ISmsService} from "./sms/ISms.service";
import {SmsService} from "./sms/Sms.service";
import {ISnackbarsController} from "./snackbarsController/ISnackbarsController";
import {SnackbarsController} from "./snackbarsController/SnackbarsController";

class CoreContainer implements ICoreContainer {
    private _adminsService: IAdminsService | undefined;
    private _appRouter: IAppRouter | undefined;
    private _authService: IAuthService | undefined;
    private _dialogsController: IDialogsController | undefined;
    private _eventsService: IEventsService | undefined;
    private _eventCarsService: IEventCarsService | undefined;
    private _eventPostsService: IEventPostsService | undefined;
    private _eventRoutesService: IEventRoutesService | undefined;
    private _eventUsersService: IEventUsersService | undefined;
    private _filesService: IFilesService | undefined;
    private _googleApiService: IGoogleApiService | undefined;
    private _menuController: IMenuController | undefined;
    private _smsService: ISmsService | undefined;
    private _snackbarsController: ISnackbarsController | undefined;

    public constructor(
        private readonly _authTokenStore: IAuthTokenStore = new AuthTokenStore(),
        private readonly _authStore: IAuthStore = new AuthStore(),
        private readonly _config: IConfig = Config,
        private readonly _eventsHub: IEventsHub = EventsHub,
        private readonly _httpService: IHttpService = new HttpService(_authTokenStore, _config),
    ) {
        console.log(this._config);
    }

    public get IAdminsService(): IAdminsService {
        if (!this._adminsService) {
            this._adminsService = new AdminsService(this._httpService);
        }

        return this._adminsService;
    }

    public get IAppRouter(): IAppRouter {
        if (!this._appRouter) {
            this._appRouter = new AppRouter(
                this.IEventsHub,
            );
        }

        return this._appRouter;
    }

    public get IAuthTokenStore(): IAuthTokenStore {
        return this._authTokenStore;
    }

    public get IAuthService(): IAuthService {
        if (!this._authService) {
            this._authService = new AuthService(
                this.IAuthTokenStore,
                this.IAuthStore,
                this.IHttpService,
            );
        }
        
        return this._authService;
    }
    
    public get IAuthStore(): IAuthStore {
        return this._authStore;
    }
    
    public get IConfig(): IConfig {
        return this._config;
    }

    public get IDialogsController(): IDialogsController {
        if (!this._dialogsController) {
            this._dialogsController = new DialogsController();
        }

        return this._dialogsController;
    }

    public get IEventsHub(): IEventsHub {
        return this._eventsHub;
    }

    public get IEventsService(): IEventsService {
        if (!this._eventsService) {
            this._eventsService = new EventsService(
                this._httpService,
            );
        }

        return this._eventsService;
    }

    public get IEventCarsService(): IEventCarsService {
        if (!this._eventCarsService) {
            this._eventCarsService = new EventCarsService(
                this.IDialogsController,
                this._httpService,
                this.ISnackbarsController,
            );
        }

        return this._eventCarsService;
    }

    public get IEventPostsService(): IEventPostsService {
        if (!this._eventPostsService) {
            this._eventPostsService = new EventPostsService(
                this.IHttpService,
            );
        }

        return this._eventPostsService;
    }

    public get IEventRoutesService(): IEventRoutesService {
        if (!this._eventRoutesService) {
            this._eventRoutesService = new EventRoutesService(
                this.IHttpService,
            )
        }

        return this._eventRoutesService;
    }

    public get IEventUsersService(): IEventUsersService {
        if (!this._eventUsersService) {
            this._eventUsersService = new EventUsersService(this);
        }

        return this._eventUsersService;
    }

    public get IFilesService(): IFilesService {
        if (!this._filesService) {
            this._filesService = new FilesService(
                this.IHttpService,
            );
        }

        return this._filesService;
    }

    public get IGoogleApiService(): IGoogleApiService {
        if (!this._googleApiService) {
            this._googleApiService = new GoogleApiService(
                this.IConfig,
            );
        }

        return this._googleApiService;
    }

    public get IHttpService(): IHttpService {
        return this._httpService;
    }

    public get IMenuController(): IMenuController {
        if (!this._menuController) {
            this._menuController = new MenuController(
                this.IAppRouter,
                this.IAuthStore,
                this.IEventsHub,
            );
        }

        return this._menuController;
    }

    public get ISmsService(): ISmsService {
        if (!this._smsService) {
            this._smsService = new SmsService(this);
        }

        return this._smsService;
    }

    public get ISnackbarsController(): ISnackbarsController {
        if (!this._snackbarsController) {
            this._snackbarsController = new SnackbarsController(this);
        }

        return this._snackbarsController;
    }
}

export default new CoreContainer();
