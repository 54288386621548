import * as React from 'react';

import {createMuiTheme, createStyles, Theme, WithStyles, withStyles} from "@material-ui/core";
import clsx from 'clsx';

import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {ICoreContainerRequired} from "../core/ICoreContainer";

const AppTheme = createMuiTheme();

const styles = (theme: Theme) => createStyles({

});

export interface ILoginPageProps extends ICoreContainerRequired, WithStyles<typeof styles> {
    classes: any;
}

const LoginPage = (props: ILoginPageProps) => {
    const {
        classes,
    } = props;

    interface ILoginFormState {
        email: string;
        password: string;
        hasError: boolean;
    }

    const [
        state,
        setState,
    ] = React.useState<ILoginFormState>({
        email: '',
        password: '',
        hasError: false,
    });

    const onFieldChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => setState({
        ...state,
        [field]: event.currentTarget.value,
    });

    const onFormSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (state.email && state.password && state.password.length >= 8) {
            try {
                await props.coreContainer.IAuthService.login(state.email, state.password);
            } catch {
                setState({
                    ...state,
                    hasError: true,
                });
            }
        } else {
            setState({
                ...state,
                hasError: true,
            });
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={clsx(classes.avatar, state.hasError && classes.avatarWithError)}>
                    <LockOutlinedIcon />
                </Avatar>
                <form className={classes.form} noValidate onSubmit={onFormSubmit}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="E-mail"
                        name="email"
                        autoComplete="email"
                        value={state.email}
                        onChange={onFieldChange('email')}
                        error={state.hasError}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={state.password}
                        onChange={onFieldChange('password')}
                        error={state.hasError}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color={state.hasError ? 'secondary' : 'primary'}
                        className={classes.submit}
                    >
                        LOGIN
                    </Button>
                </form>
            </div>
        </Container>
    );
};

export default withStyles({
    '@global': {
        body: {
            backgroundColor: AppTheme.palette.common.white,
        },
    },
    paper: {
        marginTop: AppTheme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: AppTheme.spacing(1),
        backgroundColor: AppTheme.palette.primary.main,
    },
    avatarWithError: {
        backgroundColor: AppTheme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: AppTheme.spacing(1),
    },
    submit: {
        margin: AppTheme.spacing(3, 0 ,2),
    },
})(LoginPage);
