import React from 'react';
import {IUser} from "../../../../core/models/IUser";
import {
    AppBar,
    Button,
    Dialog,
    DialogContent, Grid,
    IconButton,
    TextField,
    Toolbar,
    Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";

interface IUsersPostNotificationDialogProps extends ICoreContainerRequired {
    users: IUser[];
    onCloseRequest: () => void;
}

interface IUsersPostNotificationDialogState {
    title: string;
    body: string;
    users: IUser[];
}

class UsersPostNotificationDialog extends React.Component<IUsersPostNotificationDialogProps, IUsersPostNotificationDialogState> {
    constructor(props: IUsersPostNotificationDialogProps) {
        super(props);
        this.state = {
            title: '',
            body: '',
            users: props.users.filter((user) => user.logged),
        };
    }

    onSendClick = async () => {
        if (!(await this.props.coreContainer.IDialogsController.confirm('Are you sure?'))) {
            return;
        }

        const [success, successCount, failureCount] = await this.props.coreContainer.IHttpService.pushNotificationSend(
            this.state.title,
            this.state.body,
            this.state.users.map((user) => user.userId),
        );

        if (success) {
            this.props.coreContainer.ISnackbarsController.show({
                message: 'All notifications send successfully',
                severity: 'success',
            });
        } else {
            this.props.coreContainer.ISnackbarsController.show({
                message: `${successCount} of ${successCount + failureCount} notifications send successfully`,
            });
        }

        this.props.onCloseRequest();
    };

    onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
        title: event.currentTarget.value,
    });

    onBodyChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
        body: event.currentTarget.value,
    });

    render() {
        return (
            <Dialog
                open={true}
                fullScreen
            >
                <AppBar style={{
                    position: 'relative',
                    marginBottom: 20,
                }}>
                    <Toolbar>
                        <IconButton
                            edge={'start'}
                            color={'inherit'}
                            onClick={this.props.onCloseRequest}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant={'h6'}
                            style={{
                                flex: 1,
                            }}
                        >
                            Push Notification
                        </Typography>
                        <Button
                            color={'inherit'}
                            variant={'outlined'}
                            autoFocus
                            onClick={this.onSendClick}
                            disabled={!this.state.title || !this.state.body}
                        >Send Notification</Button>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    {this.state.users.length ? (
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid item md={4}>
                                    <TextField
                                        label={'Title'}
                                        value={this.state.title}
                                        fullWidth
                                        onChange={this.onTitleChange}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid item md={4}>
                                    <TextField
                                        label={'Body'}
                                        value={this.state.body}
                                        fullWidth
                                        onChange={this.onBodyChange}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} style={{marginTop: 20}}>
                                Notification will be delivered to:
                                <ul>
                                    {this.state.users.map((user) => (
                                        <li key={user.userId}>{user.name}</li>
                                    ))}
                                </ul>
                            </Grid>
                        </Grid>
                    ) : (
                        <React.Fragment>
                            Selected users are not logged
                        </React.Fragment>
                    )}
                </DialogContent>
            </Dialog>
        );
    }
}

export default UsersPostNotificationDialog;
