import React from 'react';
import {ICoreContainerRequired} from "../../../../../core/ICoreContainer";
import {
    AppBar,
    Button,
    Dialog,
    DialogContent,
    Grid,
    IconButton, Table, TableBody, TableCell, TableHead, TableRow,
    TextField,
    Toolbar,
    Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {ICar} from "../../../../../core/models/ICar";
import {v1 as uuidV1} from 'uuid';

interface IImportCarsDialogProps extends ICoreContainerRequired {
    eventId: string;
    eventCars: ICar[];
    onCloseRequest: () => any;
}

interface ICarToInsertRow {
    insertId: string;
    externalId: number;
    name: string;
    color: string;
    year: string;
    licensePlate: string;
    inserted: boolean;
}

interface IImportCarsDialogState {
    buttonDisabled: boolean;
    carsToInsert: ICarToInsertRow[];
    csvSource: string;
    errors: string[];
}

class ImportCarsDialog extends React.Component<IImportCarsDialogProps, IImportCarsDialogState> {
    state: IImportCarsDialogState = {
        buttonDisabled: false,
        carsToInsert: [],
        csvSource: '',
        errors: [],
    };

    onInsertClick = async () => {
        if (!(await this.props.coreContainer.IDialogsController.confirm(`Are you sure you want to insert ${this.state.carsToInsert.length} cars?`))) {
            return;
        }

        await this.disableInsertButton();

        for (const car of this.state.carsToInsert) {
            await this.insertSingleCar(car);
        }

        this.props.coreContainer.ISnackbarsController.show({
            message: `${this.state.carsToInsert.length} cars added successfully`,
            severity: 'success',
        });

        this.props.onCloseRequest();
    };

    disableInsertButton = async () => new Promise<void>((resolve) => this.setState({
        buttonDisabled: true,
    }, () => resolve()));

    insertSingleCar = (car: ICarToInsertRow): Promise<void> => {
        return new Promise<void>(async (resolve) => {
            await this.props.coreContainer.IHttpService.eventCarsPost(this.props.eventId, {
                color: car.color,
                image: '',
                licensePlate: car.licensePlate,
                name: car.name,
                year: car.year,
                externalId: car.externalId,
            });

            this.setState({
                carsToInsert: this.state.carsToInsert.map((c) => c.insertId === car.insertId ? {
                    ...c,
                    inserted: true,
                } : c)
            }, () => resolve());
        });
    };

    onSourceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
            cars,
            errors,
        } = this.parseCsvSource(event.currentTarget.value);

        this.setState({
            carsToInsert: cars,
            csvSource: event.currentTarget.value,
            errors: errors,
        });
    };

    parseCsvSource = (csvSource: string): {
        cars: ICarToInsertRow[];
        errors: string[];
    } => {
        const errors: string[] = [];
        const rows: string[] = csvSource.split('\n');
        const cars: ICarToInsertRow[] = [];
        const parsedIds: number[] = [];
        for (const row of rows) {
            const [
                externalIdString,
                name,
                color,
                year,
                licensePlate,
            ] = row.split(';');

            const externalId: number = Number(externalIdString);
            if (!externalId) {
                continue;
            }

            const alreadyExists: boolean = this.props.eventCars.some((car: ICar) => car.externalId === externalId);
            if (alreadyExists) {
                errors.push(`Car with ID ${externalId} is already present in this event`);
                continue;
            }

            const duplicated: boolean = parsedIds.includes(externalId);
            if (duplicated) {
                errors.push(`CarID ${externalId} is duplicated in this import`);
                continue;
            }

            cars.push({
                insertId: uuidV1(),
                externalId: externalId,
                name: name || '',
                color: color || '',
                year: year || '',
                licensePlate: licensePlate || '',
                inserted: false,
            });
            parsedIds.push(externalId);
        }

        return {
            cars,
            errors,
        };
    };

    render() {
        return (
            <Dialog open={true} fullScreen>
                <AppBar
                    style={{
                        position: 'relative',
                        marginBottom: 20,
                    }}
                >
                    <Toolbar>
                        <IconButton
                            edge={'start'}
                            color={'inherit'}
                            onClick={this.props.onCloseRequest}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant={'h6'}
                            style={{
                                flex: 1,
                            }}
                        >
                            Add Cars from CSV
                        </Typography>
                        <Button
                            color={'inherit'}
                            variant={'outlined'}
                            autoFocus
                            onClick={this.onInsertClick}
                            disabled={this.state.buttonDisabled ? true : !this.state.carsToInsert.length}
                        >Add Cars</Button>
                    </Toolbar>
                </AppBar>

                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <p>
                                Please insert data in format: <br />
                                CarId;MakeModel;Color;Year;LicensePlate
                            </p>
                        </Grid>
                        <Grid item xs={12} style={{marginBottom: 30}}>
                            <TextField
                                multiline={true}
                                fullWidth
                                value={this.state.csvSource}
                                onChange={this.onSourceChange}
                            />
                        </Grid>
                        {this.state.errors.length ? (
                            <Grid item xs={12} style={{color: 'red'}}>
                                <Typography>Errors:</Typography>
                                <ul>
                                    {this.state.errors.map((e) => <li key={uuidV1()}>{e}</li>)}
                                </ul>
                            </Grid>
                        ) : null}
                        {this.state.carsToInsert.length ? (
                            <React.Fragment>
                                <Grid item xs={12}>
                                    {this.state.carsToInsert.length} cars can be inserted
                                </Grid>
                                <Grid item xs={12}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{width: 100}}>CarID</TableCell>
                                                <TableCell>Make + Model</TableCell>
                                                <TableCell style={{width: 100}}>Color</TableCell>
                                                <TableCell style={{width: 100}}>Year</TableCell>
                                                <TableCell style={{width: 150}}>License Plate</TableCell>
                                                <TableCell style={{width: 200}}>Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.carsToInsert.map((car) => (
                                                <TableRow key={car.insertId}>
                                                    <TableCell>{car.externalId}</TableCell>
                                                    <TableCell>{car.name}</TableCell>
                                                    <TableCell>{car.color}</TableCell>
                                                    <TableCell>{car.year}</TableCell>
                                                    <TableCell>{car.licensePlate}</TableCell>
                                                    <TableCell>{car.inserted ? 'Inserted' : 'Waiting for insert'}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </React.Fragment>
                        ) : null}
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}

export default ImportCarsDialog;
