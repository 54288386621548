import React from 'react';
import {Box, LinearProgress, LinearProgressProps, Typography} from "@material-ui/core";

const LinearProgressWithLabel = (props: LinearProgressProps & {value: number; label?: string}) => {
    return (
        <Box display={'flex'} alignItems={'center'}>
            <Box width={'100%'} mr={1}>
                <LinearProgress variant={'determinate'} {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography
                    variant={'body2'}
                    color={'textSecondary'}
                >{props.label ? props.label : `${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
};

export default LinearProgressWithLabel;
