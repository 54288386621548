import React from 'react';
import {Card, CardContent, createStyles, Grid, Theme, withStyles, WithStyles, Typography} from "@material-ui/core";

const styles = (theme: Theme) => createStyles({
    gridItemRoot: {

    },
    cardRoot: {
        height: 150,
        '&:hover': {
            cursor: 'pointer',
            boxShadow: '0px 2px 1px -1px rgba(0,155,229,1)',
            backgroundColor: `rgba(249, 253, 255, 1)`
        },
    },
    iconItem: {
        paddingTop: 10,
        // border: '1px solid black',
    },
    textItem: {
        // border: '1px solid black',
        padding: 5,
    },
    quantityItem: {
        // border: '1px solid black',
    },
});

interface IEventDetailsGeneralCardProps extends WithStyles<typeof styles> {
    icon: React.ReactNode;
    header: string;
    subheader: string;
    quantity: number | null;
    onClick: () => any;
}

const EventDetailsGeneralCardProps = (props: IEventDetailsGeneralCardProps) => {
    return (
        <Grid item xs={12} md={3} className={props.classes.gridItemRoot}>
            <Card className={props.classes.cardRoot} onClick={props.onClick}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={2} className={props.classes.iconItem}>
                            {props.icon}
                        </Grid>
                        <Grid item xs={8} className={props.classes.textItem}>
                            <Typography
                                color={'textPrimary'}
                                variant={'h5'}
                            >{props.header}</Typography>

                            <Typography
                                color={'textSecondary'}
                                variant={'subtitle1'}
                            >{props.subheader}</Typography>
                        </Grid>
                        <Grid item xs={2} className={props.classes.quantityItem}>
                            <Typography
                                align={'center'}
                                color={'textSecondary'}
                                variant={'h4'}
                            >{typeof props.quantity === 'number' ? props.quantity : props.quantity}</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default withStyles(styles)(EventDetailsGeneralCardProps);
