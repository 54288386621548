import {IEventCarsService} from "./IEventCars.service";
import {ICar, ICarDetails, ICarInsertModel} from "../models/ICar";
import {IHttpService} from "../http/IHttp.service";
import {IDialogsController} from "../dialogsController/IDialogsController";
import {FormDialogInputTypeEnum} from "../dialogsController/FormDialogInputTypeEnum";
import {IUser} from "../models/IUser";
import {ISnackbarsController} from "../snackbarsController/ISnackbarsController";

export class EventCarsService implements IEventCarsService {
    constructor(
        private readonly _dialogsController: IDialogsController,
        private readonly _httpService: IHttpService,
        private readonly _snackbarsController: ISnackbarsController,
    ) { }

    public async addNewCar(
        eventId: string,
        users: IUser[],
        withDriverSelect: boolean,
    ): Promise<string | null> {
        const userSelectOptions = [
            {
                label: '- no driver selected -',
                value: 'NO_SELECTED',
            },
            ...users.map((user) => {
                const label: string = `${user.name}`;

                return {
                    label: label,
                    value: user.userId,
                };
            }),
        ];

        const carData: ICarInsertModel | null = await this._dialogsController.form<ICarInsertModel>({
            title: 'Add new Car',
            inputs: [
                {
                    field: 'name' as keyof ICarInsertModel,
                    label: 'Name (make + model)',
                    type: FormDialogInputTypeEnum.text,
                    required: true,
                },
                {
                    field: 'year' as keyof ICarInsertModel,
                    label: 'Year of production',
                    type: FormDialogInputTypeEnum.text,
                    required: false,
                },
                {
                    field: 'color' as keyof ICarInsertModel,
                    label: 'Color',
                    type: FormDialogInputTypeEnum.text,
                    required: false,
                },
                {
                    field: 'licensePlate' as keyof ICarInsertModel,
                    label: 'License plate',
                    type: FormDialogInputTypeEnum.text,
                    required: false,
                },
                ...(withDriverSelect ? [
                    {
                        field: 'driver1' as keyof ICarInsertModel,
                        label: 'Driver',
                        type: FormDialogInputTypeEnum.select,
                        options: userSelectOptions,
                        defaultValue: 'NO_SELECTED',
                    },
                    {
                        field: 'driver2' as keyof ICarInsertModel,
                        label: 'Co-Driver',
                        type: FormDialogInputTypeEnum.select,
                        options: userSelectOptions,
                        defaultValue: 'NO_SELECTED',
                    },
                ] : []),
            ],
            confirmText: 'Add'
        });

        console.log(carData);

        if (!carData) {
            return null;
        }

        if (withDriverSelect) {
            if (carData.driver1 !== 'NO_SELECTED' || carData.driver2 !== 'NO_SELECTED') {
                if (carData.driver1 === carData.driver2) {
                    return null;
                }
            }
        }

        const { resourceId: carId } = await this._httpService.eventCarsPost(
            eventId,
            {
                ...carData,
                ...(withDriverSelect ? {
                    driver1: carData.driver1 === 'NO_SELECTED' ? undefined : carData.driver1,
                    driver2: carData.driver2 === 'NO_SELECTED' ? undefined : carData.driver2,
                } : {}),
                image: '',
            },
        );

        if (carId) {
            this._snackbarsController.show({
                message: 'Car added successfully',
                severity: 'success',
            });
        } else {
            this._snackbarsController.show({
                message: 'Car adding error',
                severity: 'error',
            });
        }

        return carId;
    }

    public async deleteCar(eventId: string, carId: string): Promise<boolean> {
        if (!(await this._dialogsController.confirm('Are you sure you want to delete this car?'))) {
            return false;
        }

        await this._httpService.eventCarsDelete(eventId, carId);

        return true;
    }

    public async getCarDetails(eventId: string, carId: string): Promise<ICarDetails | null> {
        return await this._httpService.eventCarsGetDetails(eventId, carId);
    }

    public async getCars(eventId: string): Promise<ICar[]> {
        return await this._httpService.eventCarsGet(eventId);
    }
}
