import React from 'react';
import GoogleMapReact, {Coords} from "google-map-react";
import {ICoreContainerRequired} from "../../core/ICoreContainer";
import RoomIcon from '@material-ui/icons/Room';
import {IGoogleMap, IGoogleMapsApi} from "../../core/googleApi/IGoogleApi.service";

export interface IGoogleMapMarker {
    lat: number;
    lng: number;
    text: string;
    color?: string;
    icon?: new (props: any) => React.Component<any>;
}

export interface IGoogleMapApiLoadedResult {
    map: IGoogleMap;
    maps: IGoogleMapsApi;
}

interface IGoogleMapProps extends ICoreContainerRequired {
    markers: IGoogleMapMarker[];
    height?: string;
    defaultZoom?: number;
    onApiLoaded?: (data: IGoogleMapApiLoadedResult) => any;
    changeCenterOnMarkersChange?: boolean;
}

interface IGoogleMapState {
    apiKey: string | null;
}

class GoogleMap extends React.Component<IGoogleMapProps, IGoogleMapState> {
    _defaultLat: number | undefined;
    _defaultLng: number | undefined;

    state: IGoogleMapState = {
        apiKey: null,
    };

    constructor(props: IGoogleMapProps) {
        super(props);
        this.state.apiKey = props.coreContainer.IConfig.googleMapsKey;
    }

    calculateCenter = (): Coords => {
        return {
            lat: this.props.markers.reduce((acc, cur) => acc + cur.lat, 0) / this.props.markers.length,
            lng: this.props.markers.reduce((acc, cur) => acc + cur.lng, 0) / this.props.markers.length,
        };
    };

    calculateDefaultCenter = (): Coords => {
        if (!this.props.markers.length) {
            return {
                lat: 52.403,
                lng: 16.911,
            };
        }

        if (this._defaultLat && this._defaultLng) {
            return {
                lat: this._defaultLat,
                lng: this._defaultLng,
            };
        }

        const {
            lat,
            lng,
        } = this.calculateCenter();

        this._defaultLat = lat
        this._defaultLng = lng;

        return {
            lat: this._defaultLat,
            lng: this._defaultLng,
        };
    };

    onGoogleApiLoaded = (data: IGoogleMapApiLoadedResult) => {
        if (this.props.onApiLoaded) {
            this.props.onApiLoaded(data);
        }
    };

    render() {
        if (!this.state.apiKey) {
            return null;
        }

        return (
            <div
                style={{
                    width: '100%',
                    height: this.props.height ? this.props.height : '50vh',
                }}
            >
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: this.state.apiKey,
                    }}
                    center={this.props.changeCenterOnMarkersChange ? this.calculateCenter() : undefined}
                    defaultCenter={this.calculateDefaultCenter()}
                    defaultZoom={this.props.defaultZoom ? this.props.defaultZoom : 11}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onGoogleApiLoaded={this.onGoogleApiLoaded}
                >
                    {this.props.markers.map((marker, index) => (
                        <MarkerComponent
                            key={`marker_${index}`}
                            lat={marker.lat}
                            lng={marker.lng}
                            text={marker.text}
                            color={marker.color}
                            icon={marker.icon}
                        />
                    ))}
                </GoogleMapReact>
            </div>
        );
    }
}

export default GoogleMap;

const MarkerComponent = ({text, color, icon}: IGoogleMapMarker) => {
    if (icon) {
        const IconComponent = icon;

        return (
            <IconComponent
                name={text}
                style={{
                    color: color ? color : 'red',
                    position: 'absolute',
                    transform: 'translate(-50%, -50%)',
                }}
            />
        );
    }

    return (
        <RoomIcon
            name={text}
            style={{
                color: color ? color : 'red',
                position: 'absolute',
                transform: 'translate(-50%, -50%)',
            }}
        />
    );
};
