import React from 'react';
import {Button} from "@material-ui/core";
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";

interface IAddNewCarButtonProps extends ICoreContainerRequired {
    eventId: string;
    onCarAdd: (carId?: string) => any;
}

class AddNewCarButton extends React.Component<IAddNewCarButtonProps> {
    onAddClick = async () => {
        const carId: string | null = await this.props.coreContainer.IEventCarsService.addNewCar(
            this.props.eventId,
            await this.props.coreContainer.IEventUsersService.getEventUsers(this.props.eventId),
            true,
        );

        if (carId) {
            this.props.onCarAdd(carId);
        }
    };

    render() {
        return (
            <Button
                variant={'outlined'}
                size={'large'}
                color={'primary'}
                onClick={this.onAddClick}
                style={{marginRight: 10}}
            >+ Add new Car</Button>
        );
    }
}

export default AddNewCarButton;
