import React from 'react';
import {
    createStyles,
    Theme,
    ThemeProvider,
    withStyles,
    WithStyles,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Navigator from './Navigator';
import Header from './header/Header';
import AppTheme from "./theme/AppTheme";
import Footer from "./footer/Footer";
import {Route, RouteChildrenProps, Router} from "react-router";
import {RootModule} from "./modules/root/RootModule";
import {ICoreContainerRequired} from "../core/ICoreContainer";
import {AppPaths} from "../core/router/AppPaths";
import {AdminsModule} from "./modules/admins/AdminsModule";
import DialogsComponent from "./usages/DialogsComponent";
import EventsModule from "./modules/events/EventsModule";
import EventDetailsModule from "./modules/eventDetails/EventDetailsModule";
import SnackbarsComponent from "./usages/SnackbarsComponent";
import ConfigModule from "./modules/config/ConfigModule";

const drawerWidth = 256;

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        minHeight: '100vh',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    app: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    main: {
        flex: 1,
        padding: theme.spacing(6, 4),
        background: '#eaeff1',
    },
});

export interface PaperbaseProps extends ICoreContainerRequired, WithStyles<typeof styles> {

}

function Paperbase(props: PaperbaseProps) {
    const { classes } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <ThemeProvider theme={AppTheme}>
            <div className={classes.root}>
                <CssBaseline />
                <nav className={classes.drawer}>
                    <Hidden smUp implementation="js">
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth } }}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            coreContainer={props.coreContainer}
                        />
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth } }}
                            coreContainer={props.coreContainer}
                        />
                    </Hidden>
                </nav>
                <div className={classes.app}>
                    <Header onDrawerToggle={handleDrawerToggle} coreContainer={props.coreContainer} />

                    <Router
                        history={props.coreContainer.IAppRouter.history}
                    >
                        <Route path={AppPaths.Root} exact={true}>
                            <RootModule coreContainer={props.coreContainer} />
                        </Route>
                        <Route path={AppPaths.Events} exact={true}>
                            <EventsModule coreContainer={props.coreContainer} />
                        </Route>

                        <Route
                            path={AppPaths.Events + '/:eventId'}
                            exact={true}
                            children={(routeChildrenProps: RouteChildrenProps<{eventId: string}>) => {
                                if (routeChildrenProps.match?.params?.eventId) {
                                    return (
                                        <EventDetailsModule
                                            coreContainer={props.coreContainer}
                                            eventId={routeChildrenProps.match.params.eventId}
                                        />
                                    );
                                }

                                return null;
                            }}
                        />

                        <Route path={AppPaths.SettingsAdmins} exact={true}>
                            <AdminsModule coreContainer={props.coreContainer} />
                        </Route>

                        <Route path={AppPaths.SettingsConfig} exact={true}>
                            <ConfigModule coreContainer={props.coreContainer} />
                        </Route>
                    </Router>

                    <Footer />
                </div>

                <DialogsComponent coreContainer={props.coreContainer} />
                <SnackbarsComponent coreContainer={props.coreContainer} />
            </div>
        </ThemeProvider>
    );
}

export default withStyles(styles)(Paperbase);
