import React from 'react';
import {IPost} from "../../../../../core/models/IPost";
import {ICoreContainerRequired} from "../../../../../core/ICoreContainer";
import {
    Card,
    CardHeader,
    createStyles,
    Theme,
    withStyles,
    WithStyles,
    CardMedia,
    CardContent,
    CardActions, Typography, Collapse, IconButton, Button, Divider, List, ListItem, ListItemText
} from "@material-ui/core";
import moment from "moment";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EventDetailsPostCardComment from "./EventDetailsPostCardComment";
import Lightbox from 'react-image-lightbox';

const styles = (theme: Theme) => createStyles({
    root: {
        maxWidth: 600,
        marginBottom: 40,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    media: {
        height: 0,
        paddingTop: '56.25%',
        cursor: 'pointer',
    },
});

interface IEventDetailsPostCardProps extends ICoreContainerRequired, WithStyles<typeof styles> {
    postData: IPost;
    resolveUserName: (userId: string) => string;
    resolveAdminName: (userId: string) => string;
    reloadPosts: () => any;
}

interface IEventDetailsPostCardState {
    detailsExpanded: boolean;
    lightBoxOpened: boolean;
    lightBoxPhotoIndex: number;
}

class EventDetailsPostCard extends React.Component<IEventDetailsPostCardProps, IEventDetailsPostCardState> {
    state: IEventDetailsPostCardState = {
        detailsExpanded: false,
        lightBoxOpened: false,
        lightBoxPhotoIndex: 0,
    };

    onDetailsToggleClick = () => this.setState({
        detailsExpanded: !this.state.detailsExpanded,
    });

    onPinnedToggleClick = async () => {
        if (!(await this.props.coreContainer.IDialogsController.confirm('Are you sure?'))) {
            return;
        }

        await this.props.coreContainer.IEventPostsService.editPost(
            this.props.postData.eventId,
            this.props.postData.postId,
            {
                pinned: !this.props.postData.pinned,
            },
        );

        this.props.reloadPosts();
    };

    onPostDeleteClick = async () => {
        if (!(await this.props.coreContainer.IDialogsController.confirm('Are you sure you want to delete this post?'))) {
            return;
        }

        await this.props.coreContainer.IEventPostsService.deletePost(
            this.props.postData.eventId,
            this.props.postData.postId,
        );

        this.props.reloadPosts();
    };

    onCommentDeleteClick = async (commentId: string) => {
        if (!(await this.props.coreContainer.IDialogsController.confirm('Are you sure you want to delete this comment?'))) {
            return;
        }

        await this.props.coreContainer.IEventPostsService.deletePostComment(
            this.props.postData.eventId,
            this.props.postData.postId,
            commentId,
        );

        this.props.reloadPosts();
    };

    onImageClick = (photoIndex: number) => this.setState({
        lightBoxOpened: true,
        lightBoxPhotoIndex: photoIndex,
    });

    resolveAuthorName = (): string => {
        if (this.props.postData.userId) {
            return this.props.resolveUserName(this.props.postData.userId);
        }

        if (this.props.postData.adminId) {
            return this.props.resolveAdminName(this.props.postData.adminId);
        }

        return '';
    };

    getLightBoxMainSrc = (): string => {
        return `${this.props.coreContainer.IConfig.cdnBaseUrl}/${this.props.postData.images[this.state.lightBoxPhotoIndex]}`;
    };

    getLightboxNextSrc = (): string | undefined => {
        const nextIndex = this.state.lightBoxPhotoIndex + 1;
        if (!this.props.postData.images[nextIndex]) {
            return undefined;
        }

        return `${this.props.coreContainer.IConfig.cdnBaseUrl}/${this.props.postData.images[nextIndex]}`;
    };

    getLightboxPrevSrc = (): string | undefined => {
        const previousIndex = this.state.lightBoxPhotoIndex - 1;
        if (!this.props.postData.images[previousIndex]) {
            return undefined;
        }

        return `${this.props.coreContainer.IConfig.cdnBaseUrl}/${this.props.postData.images[previousIndex]}`;
    };

    onOtherPhotoRequest = (indexDiff: number) => {
        const requestedIndex: number = this.state.lightBoxPhotoIndex + indexDiff;
        if (!this.props.postData.images[requestedIndex]) {
            return;
        }

        this.setState({
            lightBoxPhotoIndex: requestedIndex,
        });
    };

    render() {
        const { classes, coreContainer, postData } = this.props;

        return (
            <Card className={classes.root}>
                <CardHeader
                    title={this.resolveAuthorName()}
                    subheader={`at ${moment(postData.createTime).format('YYYY-MM-DD HH:mm:ss')}`}
                />
                {postData.images.map((imageId, imageIndex) => (
                    <CardMedia
                        key={imageId}
                        className={classes.media}
                        image={`${coreContainer.IConfig.cdnBaseUrl}/${imageId}`}
                        title={'Post Image'}
                        onClick={() => this.onImageClick(imageIndex)}
                    />
                ))}
                {postData.message ? (
                    <CardContent>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {postData.message}
                        </Typography>
                    </CardContent>
                ) : null}
                <CardActions disableSpacing>
                    <Button size={'small'} color={'primary'} onClick={this.onPinnedToggleClick}>
                        {postData.pinned ? 'Unpin' : 'Pin'}
                    </Button>
                    <Button size={'small'} color={'secondary'} onClick={this.onPostDeleteClick}>
                        Delete
                    </Button>
                    <IconButton
                        aria-expanded={this.state.detailsExpanded}
                        style={{
                            marginLeft: 'auto',
                            ...(this.state.detailsExpanded ? {
                                transform: 'rotate(180deg)'
                            } : {}),
                        }}
                        onClick={this.onDetailsToggleClick}
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </CardActions>
                <Collapse in={this.state.detailsExpanded} timeout={'auto'} unmountOnExit>
                    <CardContent>
                        <Typography variant="body1" color="textPrimary" component="p">
                            {postData.likes.length ? `Likes (${postData.likesCount}):` : 'No Likes'}
                        </Typography>
                        <List dense>
                            {postData.likes.map((userId) => (
                                <ListItem style={{paddingLeft: 0}}>
                                    <ListItemText
                                        primary={this.props.resolveUserName(userId)}
                                    />
                                </ListItem>
                            ))}
                        </List>

                        <Divider light style={{marginBottom: 10}} />

                        <Typography variant="body1" color="textPrimary" component="p" style={{marginBottom: 10}}>
                            {postData.commentsCount ? `Comments (${postData.commentsCount}):` : 'No Comments'}
                        </Typography>

                        {postData.comments.map((comment) => (
                            <EventDetailsPostCardComment
                                key={comment.commentId}
                                comment={comment}
                                authorName={comment.userId ? this.props.resolveUserName(comment.userId) : ''}
                                coreContainer={this.props.coreContainer}
                                onDeleteClick={this.onCommentDeleteClick}
                            />
                        ))}

                        <Typography variant="body2" color="textSecondary" component="p" style={{marginTop: 10, textAlign: 'right'}}>
                            ID: {postData.postId}
                        </Typography>
                    </CardContent>
                </Collapse>

                {this.state.lightBoxOpened ? (
                    <Lightbox
                        mainSrc={this.getLightBoxMainSrc()}
                        nextSrc={this.getLightboxNextSrc()}
                        prevSrc={this.getLightboxPrevSrc()}
                        onMoveNextRequest={() => this.onOtherPhotoRequest(1)}
                        onMovePrevRequest={() => this.onOtherPhotoRequest(-1)}
                        onCloseRequest={() => this.setState({lightBoxOpened: false})}
                        enableZoom={false}
                    />
                ) : null}
            </Card>
        );
    }
}

export default withStyles(styles)(EventDetailsPostCard);
