import {IStoreAbstract} from "./IStoreAbstract";
import {PubSubAbstract} from "../pubSub/PubSubAbstract";

export class StoreAbstract<StoreStructure extends {}> extends PubSubAbstract<StoreStructure> implements IStoreAbstract<StoreStructure> {
    private _store: StoreStructure;

    constructor(
        private readonly _storeDefaultData: StoreStructure,
    ) {
        super();
        this._store = {..._storeDefaultData};
    }
    
    public get(): StoreStructure {
        return this._store;
    }

    public reset(): void {
        this._store = {...this._storeDefaultData};

        this.publish(this._store);
    }

    public set(storeData: StoreStructure): void {
        this._store = {...storeData};

        this.publish(this._store);
    }

    public update(updateData: Partial<StoreStructure>): void {
        this._store = {
            ...this._store,
            ...updateData,
        };

        this.publish(this._store);
    }
}
