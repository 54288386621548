import React from 'react';
import {
    Button,
    createStyles,
    Dialog,
    DialogActions, DialogContent,
    DialogTitle, FormControlLabel, Switch, TextField,
    Theme,
    WithStyles,
    withStyles
} from "@material-ui/core";
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";
import {IAdmin} from "../../../../core/models/IAdmin";

const styles = (theme: Theme) => createStyles({
    textFieldWithMarginBottom: {
        marginBottom: 10,
    },
});

interface IAdminsFormProps extends ICoreContainerRequired, WithStyles<typeof styles> {
    adminToEdit: IAdmin | null;
    onFinish: () => any;
    selfEdit: boolean;
}

interface IAdminsFormState {
    adminData: IAdmin;
    password: string;
}

class AdminsForm extends React.Component<IAdminsFormProps, IAdminsFormState> {
    state: IAdminsFormState = {
        adminData: {
            adminId: '',
            email: '',
            name: '',
            status: true,
            superAdmin: false,
        },
        password: '',
    };

    constructor(props: IAdminsFormProps) {
        super(props);

        if (props.adminToEdit) {
            this.state.adminData = props.adminToEdit;
        }
    }

    onFieldChange = (field: string) => (event: React.BaseSyntheticEvent) => this.setState({
        adminData: {
            ...this.state.adminData,
            [field]: event.currentTarget.value,
        },
    });

    updateFormField = (field: string, value: any) => this.setState({
        adminData: {
            ...this.state.adminData,
            [field]: value,
        },
    });

    canBeSaved = (): boolean => {
        return (
            (!!this.state.adminData.name && !!this.state.adminData.email) &&
            (!!this.props.adminToEdit || this.state.password.length >= 8)
        );
    };

    onSaveClick = async () => {
        if (this.props.adminToEdit) {
            await this.saveExistingAdmin();
        } else {
            await this.saveNewAdmin();
        }

        this.props.onFinish();
    };

    private saveNewAdmin = async () => {
        await this.props.coreContainer.IAdminsService.createAdmin({
            ...this.state.adminData,
            password: this.state.password,
        });
    };

    private saveExistingAdmin = async () => {
        if (!this.props.adminToEdit) {
            return;
        }

        const editResult = await this.props.coreContainer.IAdminsService.editAdmin(this.props.adminToEdit?.adminId, {
            email: this.state.adminData.email,
            name: this.state.adminData.name,
            status: this.state.adminData.status,
            superAdmin: this.state.adminData.superAdmin,
        });

        if (editResult) {
            this.props.coreContainer.ISnackbarsController.show({
                message: `Admin modified successfully`,
                severity: 'success',
            });
        } else {
            this.props.coreContainer.ISnackbarsController.show({
                message: `Admin modification error`,
                severity: 'error',
            });
        }
    };

    render() {
        return (
            <Dialog
                open={true}
                onClose={this.props.onFinish}
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle>{this.props.adminToEdit ? `Edit ${this.state.adminData.name}` : 'Add new Admin'}</DialogTitle>

                <DialogContent>
                    <TextField
                        label={'Name'}
                        fullWidth
                        value={this.state.adminData.name}
                        onChange={this.onFieldChange('name')}
                        className={this.props.classes.textFieldWithMarginBottom}
                    />

                    <TextField
                        label={'E-mail'}
                        fullWidth
                        value={this.state.adminData.email}
                        onChange={this.onFieldChange('email')}
                        className={this.props.classes.textFieldWithMarginBottom}
                    />

                    {!this.props.adminToEdit ? (
                        <TextField
                            label={'Password'}
                            fullWidth
                            value={this.state.password}
                            onChange={(event) => this.setState({password: event.currentTarget.value})}
                            className={this.props.classes.textFieldWithMarginBottom}
                            type={'password'}
                        />
                    ) : null}

                    <FormControlLabel
                        control={(
                            <Switch
                                checked={this.state.adminData.superAdmin}
                                onChange={(event) => this.updateFormField('superAdmin', event.target.checked)}
                                disabled={this.props.selfEdit}
                            />
                        )}
                        label={'Super Admin'}
                    />

                    <FormControlLabel
                        control={(
                            <Switch
                                checked={this.state.adminData.status}
                                onChange={(event) => this.updateFormField('status', event.target.checked)}
                                disabled={this.props.selfEdit}
                            />
                        )}
                        label={'Active'}
                    />
                </DialogContent>

                <DialogActions>
                    <Button
                        color={'primary'}
                        onClick={this.onSaveClick}
                        disabled={!this.canBeSaved()}
                    >
                        Save
                    </Button>
                    <Button
                        color={'secondary'}
                        onClick={this.props.onFinish}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(AdminsForm);
