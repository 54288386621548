import React from 'react';
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";
import {IPost} from "../../../../core/models/IPost";
import {Grid} from "@material-ui/core";
import {IUser} from "../../../../core/models/IUser";
import {IAdmin} from "../../../../core/models/IAdmin";
import EventDetailsPostCard from "./postCard/EventDetailsPostCard";

interface IEventDetailsPostsProps extends ICoreContainerRequired {
    eventId: string;
}

interface IEventDetailsPostsState {
    postsFetched: boolean;
    posts: IPost[];
    users: IUser[];
    admins: IAdmin[];
}

class EventDetailsPosts extends React.Component<IEventDetailsPostsProps, any> {
    state: IEventDetailsPostsState = {
        postsFetched: false,
        posts: [],
        users: [],
        admins: [],
    };

    componentDidMount(): void {
        this.fetchPosts();
    }

    fetchPosts = async () => this.setState({
        postsFetched: true,
        posts: await this.props.coreContainer.IEventPostsService.getPosts(this.props.eventId),
        users: await this.props.coreContainer.IEventUsersService.getEventUsers(this.props.eventId),
        admins: await this.props.coreContainer.IAdminsService.getAdmins(),
    });

    resolveAdminName = (adminId: string): string => {
        const adminData: IAdmin | undefined = this.state.admins.find((admin) => admin.adminId === adminId);

        return adminData ? `${adminData.name} (Admin)` : 'Unknown Admin';
    };

    resolveUserName = (userId: string): string => {
        const userData: IUser | undefined = this.state.users.find((user) => user.userId === userId);

        return userData ? userData.name : 'Unknown User';
    };

    render() {
        return (
            <React.Fragment>
                {/*<AddNewPostButton*/}
                {/*    eventId={this.props.eventId}*/}
                {/*    coreContainer={this.props.coreContainer}*/}
                {/*    onPostAdd={this.fetchPosts}*/}
                {/*/>*/}

                <Grid container>
                    {this.state.postsFetched ? this.state.posts.map((post) => (
                        <Grid key={`post_${post.postId}`} item xs={12}>
                            <EventDetailsPostCard
                                postData={post}
                                coreContainer={this.props.coreContainer}
                                resolveAdminName={this.resolveAdminName}
                                resolveUserName={this.resolveUserName}
                                reloadPosts={this.fetchPosts}
                            />
                        </Grid>
                    )) : null}
                </Grid>
            </React.Fragment>
        );
    }
}

export default EventDetailsPosts;
