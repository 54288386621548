import {IEventUsersService} from "./IEventUsersService";
import {IUser, IUserDetails, IUserInsertModel} from "../models/IUser";
import {ICoreContainer} from "../ICoreContainer";

export class EventUsersService implements IEventUsersService {
    constructor(
        private readonly _coreContainer: ICoreContainer,
    ) { }

    public async addEventUser(eventId: string, userData: IUserInsertModel): Promise<string | null> {
        const { resourceId: userId } = await this._coreContainer.IHttpService.eventUsersPost(eventId, userData);
        return userId;
    }

    public async deleteUser(eventId: string, userId: string): Promise<boolean> {
        const confirmation: boolean = await this._coreContainer.IDialogsController.confirm('Are you sure you want to delete this user?');

        if (!confirmation) {
            return false;
        }

        return await this._coreContainer.IHttpService.eventUsersDelete(eventId, userId);
    }

    public async editEventUser(eventId: string, userId: string, userData: Partial<IUserInsertModel>): Promise<boolean> {
        try {
            await this._coreContainer.IHttpService.eventUsersPatch(
                eventId,
                userId,
                userData,
            );

            this._coreContainer.ISnackbarsController.show({
                message: 'User modified successfully',
                severity: 'success',
            });

            return true;
        } catch {
            this._coreContainer.ISnackbarsController.show({
                message: 'User modification error',
                severity: 'error',
            });

            return false;
        }
    }

    public async getEventUsers(eventId: string): Promise<IUser[]> {
        return await this._coreContainer.IHttpService.eventUsersGet(eventId);
    }

    public async getUserDetails(eventId: string, userId: string): Promise<IUserDetails> {
        return await this._coreContainer.IHttpService.eventUsersGetDetails(eventId, userId);
    }
}
