import {
    IGoogleApi,
    IGoogleAutocompletePrediction,
    IGoogleApiService,
    IGooglePlace,
    IGoogleApiDistanceCheckResult
} from "./IGoogleApi.service";
import * as GoogleMaps from "google-maps";
import {IConfig} from "../config/IConfig";
import {ILatLon} from "../models/ILatLon";

export class GoogleApiService implements IGoogleApiService {
    private _api: IGoogleApi | null;

    constructor(
        private readonly _config: IConfig,
    ) {
        this._api = null;
    }

    public async getApi(): Promise<IGoogleApi> {
        if (!this._api) {
            this._api = await new GoogleMaps.Loader(
                this._config.googleMapsKey,
                {
                    libraries: ['places']
                },
            ).load();
        }

        return this._api;
    }

    public async getAutocomplete(searchString: string): Promise<IGoogleAutocompletePrediction[]> {
        const api: IGoogleApi = await this.getApi();
        const service = new api.maps.places.AutocompleteService();
        return new Promise((resolve) => {
            service.getPlacePredictions({
                input: searchString,
            }, (predictions: IGoogleAutocompletePrediction[], status) => {
                resolve(predictions);
            })
        });
    }

    public async getDistance(source: ILatLon, target: ILatLon): Promise<IGoogleApiDistanceCheckResult> {
        const api: IGoogleApi = await this.getApi();
        const distanceMatrixService = new api.maps.DistanceMatrixService();

        return new Promise<IGoogleApiDistanceCheckResult>((resolve) => {
            distanceMatrixService.getDistanceMatrix({
                origins: [{
                    lat: source.lat,
                    lng: source.lon,
                }],
                destinations: [{
                    lat: target.lat,
                    lng: target.lon,
                }],
                travelMode: api.maps.TravelMode.DRIVING,
            }, (result) => {
                try {
                    const [ firstRow ] = result.rows;
                    const [ firstElement ] = firstRow.elements;
                    resolve({
                        distanceText: firstElement.distance.text,
                        distanceValue: firstElement.distance.value,
                    });
                } catch {
                    resolve({
                        distanceText: null,
                        distanceValue: null,
                    });
                }
            });
        });
    }

    public async getPlaceDetails(placeId: string): Promise<IGooglePlace> {
        const api: IGoogleApi = await this.getApi();
        const element = document.createElement('div');
        const placesService = new api.maps.places.PlacesService(element);
        return new Promise((resolve) => {
            placesService.getDetails({
                placeId,
            }, (result) => {
                resolve(result);
            });
        });
    }
}
