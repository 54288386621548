import React from 'react';
import {ICoreContainerRequired} from "../../../../../core/ICoreContainer";
import {IPostComment} from "../../../../../core/models/IPost";
import {Divider, Grid, IconButton, Typography} from "@material-ui/core";
import moment from "moment";
import DeleteIcon from '@material-ui/icons/Delete'

interface IEventDetailsPostCardCommentProps extends ICoreContainerRequired {
    comment: IPostComment;
    authorName: string;
    onDeleteClick: (commentId: string) => any;
}

interface IEventDetailsPostCardCommentState {

}

class EventDetailsPostCardComment extends React.Component<IEventDetailsPostCardCommentProps, IEventDetailsPostCardCommentState> {
    render() {
        return (
            <Grid container>
                <Grid item xs={11} style={{marginBottom: 10}}>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {this.props.authorName} at {moment(this.props.comment.createTime).format('YYYY-MM-DD HH:mm:ss')}
                    </Typography>
                    <Typography variant="body2" color="textPrimary" component="p">
                        {this.props.comment.message}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <IconButton
                        style={{marginLeft: 'auto'}}
                        onClick={() => this.props.onDeleteClick(this.props.comment.commentId)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={12}>
                    <Divider light style={{marginBottom: 10}} />
                </Grid>
            </Grid>
        );
    }
}

export default EventDetailsPostCardComment;
