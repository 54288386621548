import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import clsx from "clsx";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import React from "react";
import ItemClass from "./styleClasses/ItemClass";

const styles = (theme: Theme) => (
    createStyles({
        item: ItemClass,
        itemActiveItem: {
            color: '#4fc3f7',
        },
        itemIcon: {
            minWidth: 'auto',
            marginRight: theme.spacing(2),
        },
        itemPrimary: {
            fontSize: 'inherit',
        },
    })
);

export interface INavigatorItemProps extends WithStyles<typeof styles> {
    icon: JSX.Element;
    text: string;
    active?: boolean;
    onClick?: () => any;
}

function NavigatorItem(props: INavigatorItemProps): JSX.Element {
    const {
        active,
        classes,
        icon,
        text,
        onClick = () => null,
    } = props;

    return (
        <ListItem
            button
            className={clsx(classes.item, active && classes.itemActiveItem)}
            onClick={onClick}
        >
            <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
            <ListItemText
                classes={{
                    primary: classes.itemPrimary,
                }}
            >
                {text}
            </ListItemText>
        </ListItem>
    );
}

export default withStyles(styles)(NavigatorItem);
