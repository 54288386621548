import React from 'react';
import {
    Chip,
    createStyles,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Theme, Typography,
    withStyles,
    WithStyles
} from "@material-ui/core";
import {IEvent} from "../../../../core/models/IEvent";
import EventDateLabel from "./EventDateLabel";

const styles = (theme: Theme) => createStyles({
    clickableRow: {
        cursor: 'pointer',
    },
});

interface IEventsListTableProps extends WithStyles<typeof styles> {
    events: IEvent[];
    onEventClick: (eventId: string) => any;
}

const EventsListTable = (props: IEventsListTableProps) => (
    <Table>
        <TableBody>
            {props.events.map((event) => (
                <TableRow
                    className={props.classes.clickableRow}
                    key={event.eventId}
                    hover
                    onClick={() => props.onEventClick(event.eventId)}
                >
                    <TableCell>
                        <Typography>{event.name}</Typography>
                    </TableCell>
                    <TableCell>
                        <EventDateLabel dateFrom={event.dateFrom} dateTo={event.dateTo} />
                    </TableCell>
                    <TableCell align={'center'}>
                        <Chip
                            label={event.isActive ? 'Active' : 'Inactive'}
                            color={event.isActive ? 'primary' : 'secondary'}
                            variant={'outlined'}
                            size={'small'}
                        />
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>
);

export default withStyles(styles)(EventsListTable);
