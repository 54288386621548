import React from 'react';
import {ICoreContainerRequired} from "../../../core/ICoreContainer";
import SubModule from "../../usages/SubModule";
import EventDetailsGeneral from "./general/EventDetailsGeneral";
import {AppPaths} from "../../../core/router/AppPaths";
import {IEventDetails} from "../../../core/models/IEvent";
import EventDetailsCars from "./cars/EventDetailsCars";
import EventDetailsUsers from "./users/EventDetailsUsers";
import EventDetailsPosts from "./posts/EventDetailsPosts";
import {CircularProgress, IconButton, LinearProgress} from "@material-ui/core";
import EventDetailsMap from "./map/EventDetailsMap";
import EventDetailsSettings from "./settings/EventDetailsSettings";
import EventSchedule from "./schedule/EventSchedule";
// import EventDetailsWebMap from "./webMap/EventDetailsWebMap";
import EventAbuseReports from "./abuseReports/EventAbuseReports";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

interface IEventDetailsModuleProps extends ICoreContainerRequired {
    eventId: string | undefined;
}

interface IEventDetailsModuleState {
    eventData: IEventDetails | null;
}

class EventDetailsModule extends React.Component<IEventDetailsModuleProps, IEventDetailsModuleState> {
    state: IEventDetailsModuleState = {
        eventData: null,
    };

    componentDidMount(): void {
        this.fetchEventData();
    }

    fetchEventData = async () => {
        if (!this.props.eventId) {
            return;
        }

        const eventData: IEventDetails = await this.props.coreContainer.IEventsService.getEvent(this.props.eventId);

        this.setState({
            eventData,
        });
    };

    onBackClick = () => {
        this.props.coreContainer.IAppRouter.goBack();
    };

    parseSubViewKey = (): string | undefined => {
        const { view } = this.props.coreContainer.IAppRouter.parseQuery();
        return view;
    };

    onTabClick = (tabKey: string): void => {
        this.props.coreContainer.IAppRouter.push(`${AppPaths.Events}/${this.props.eventId}?view=${tabKey}`);
    };

    getTitle = () => {
        return this.state.eventData
            ? (
                <React.Fragment>
                    <IconButton
                        style={{color: 'white'}}
                        onClick={this.onBackClick}
                    >
                        <ArrowBackIosIcon />
                    </IconButton>
                    {this.state.eventData.name}
                </React.Fragment>
            )
            : <CircularProgress color={'secondary'} />;
    };

    render() {
        if (!this.state.eventData) {
            return <LinearProgress />
        }

        return (
            <SubModule
                title={this.getTitle()}
                currentKey={this.parseSubViewKey()}
                onTabClick={this.onTabClick}
                subModules={[
                    {
                        label: 'General',
                        component: (
                            <EventDetailsGeneral
                                coreContainer={this.props.coreContainer}
                                eventId={this.props.eventId as string}
                                eventData={this.state.eventData}
                                fetchEventData={this.fetchEventData}
                            />
                        ),
                        key: 'general',
                        backgroundTransparent: true,
                    },
                    {
                        label: 'Users',
                        component: <EventDetailsUsers coreContainer={this.props.coreContainer} eventId={this.props.eventId as string} />,
                        key: 'users',
                    },
                    {
                        label: 'Cars',
                        component: <EventDetailsCars coreContainer={this.props.coreContainer} eventId={this.props.eventId as string} />,
                        key: 'cars',
                    },
                    {
                        label: 'Posts',
                        component: <EventDetailsPosts coreContainer={this.props.coreContainer} eventId={this.props.eventId as string} />,
                        key: 'posts',
                        backgroundTransparent: true,
                    },
                    {
                        label: 'Abuse Reports',
                        component: <EventAbuseReports coreContainer={this.props.coreContainer} eventId={this.props.eventId as string} />,
                        key: 'abuseReports',
                    },
                    ...(this.state.eventData.name !== 'La Famiglia' ? [
                        {
                          label: 'Schedule',
                          component: <EventSchedule coreContainer={this.props.coreContainer} eventId={this.props.eventId as string} eventDetails={this.state.eventData} />,
                          key: 'schedule',
                          backgroundTransparent: true,
                        },
                    ] : []),
                    {
                        label: 'Map',
                        component: <EventDetailsMap coreContainer={this.props.coreContainer} eventData={this.state.eventData} />,
                        key: 'map',
                    },
                    // {
                    //     label: 'Routes',
                    //     component: <EventDetailsRoutes coreContainer={this.props.coreContainer} eventId={this.props.eventId as string} />,
                    //     key: 'routes',
                    // },
                    // {
                    //     label: 'Web Map',
                    //     component: <EventDetailsWebMap coreContainer={this.props.coreContainer} eventId={this.props.eventId as string} />,
                    //     key: 'webMap',
                    // },
                    ...(this.props.coreContainer.IAuthStore.get().adminData.superAdmin ? ([{
                        label: 'Settings',
                        component: (
                            <EventDetailsSettings
                                coreContainer={this.props.coreContainer}
                                eventId={this.props.eventId as string}
                                eventData={this.state.eventData}
                                fetchEventData={this.fetchEventData}
                            />
                        ),
                        key: 'settings',
                    }]) : [])
                ]}
            />
        );
    }
}

export default EventDetailsModule;
