import React from 'react';
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";
import {IUserDetails} from "../../../../core/models/IUser";
import GoogleMap, {IGoogleMapMarker} from "../../../usages/GoogleMap";
import {Button, Grid, MenuItem, Select, TextField} from "@material-ui/core";
import moment from "moment";
import FormTable from "../../../usages/FormTable";
import Haversine from 'haversine';

enum locationView {
    current = 'current',
    range = 'range',
    maxSpeed = 'maxSpeed',
}

interface IEventDetailsUserDetailsMapProps extends ICoreContainerRequired {
    eventId: string;
    userId: string;
    userDetails: IUserDetails;
}

interface IEventDetailsUserDetailsMapState {
    viewMode: locationView;
    startTime: number;
    endTime: number;
    locations: any[];
}

class EventDetailsUserDetailsMap extends React.Component<IEventDetailsUserDetailsMapProps, IEventDetailsUserDetailsMapState> {
    state: IEventDetailsUserDetailsMapState = {
        viewMode: locationView.current,
        startTime: 0,
        endTime: 0,
        locations: [],
    };

    constructor(props: IEventDetailsUserDetailsMapProps) {
        super(props);
        this.state.startTime = moment().subtract(1, 'hours').valueOf();
        this.state.endTime = moment().valueOf();
    }

    onSourceSelectChange = (event: React.BaseSyntheticEvent) => this.setState({
        viewMode: event.target.value,
    });

    prepareMarkers = (): IGoogleMapMarker[] => {
        if (this.state.viewMode === locationView.current) {
            return [{
                text: 'Last location',
                lat: this.props.userDetails.currentLocation.coordinates.lat,
                lng: this.props.userDetails.currentLocation.coordinates.lng,
            }];
        } else if (this.state.viewMode === locationView.range) {
            return this.state.locations.map((location) => ({
                text: moment(location.deviceTimestamp).toISOString(),
                lat: location.lat,
                lng: location.lng,
            }));
        } else if (this.state.viewMode === locationView.maxSpeed) {
            return [{
                text: 'Max speed location',
                lat: this.props.userDetails.maxSpeedLocation.coordinates.lat,
                lng: this.props.userDetails.maxSpeedLocation.coordinates.lng,
            }];
        } else {
            return [];
        }
    };

    onTimeChange = (field: 'startTime' | 'endTime') => (event: React.BaseSyntheticEvent) => this.setState({
        [field]: moment(event.target.value).valueOf(),
    } as any);

    fetchLocations = async () => {
        const locations = await this.props.coreContainer.IHttpService.eventUsersGetLocations(
            this.props.eventId,
            this.props.userId,
            Math.round(this.state.startTime / 1000),
            Math.round(this.state.endTime / 1000),
        );

        this.setState({
            locations,
        });
    };

    render() {
        if (!this.props.userDetails.currentLocation) {
            return 'No locations found';
        }

        return (
            <Grid container>
                <Grid item xs={12}>
                    <Select
                        value={this.state.viewMode}
                        onChange={this.onSourceSelectChange}
                        style={{
                            margin: 10,
                        }}
                    >
                        <MenuItem value={locationView.current}>Latest location</MenuItem>
                        <MenuItem value={locationView.range}>Locations history</MenuItem>
                        <MenuItem value={locationView.maxSpeed} disabled={!this.props.userDetails.maxSpeedLocation}>Max speed location</MenuItem>
                    </Select>
                </Grid>

                <Grid item xs={12}>
                    {(() => {
                        switch (this.state.viewMode) {
                            case locationView.current: return (
                                <FormTable
                                    rows={[
                                        [
                                            'Latest location coordinates',
                                            `${this.props.userDetails.currentLocation.coordinates.lat.toFixed(6)}, ${this.props.userDetails.currentLocation.coordinates.lng.toFixed(6)}`
                                        ],
                                        [
                                            'Latest location deviceTimestamp',
                                            moment(this.props.userDetails.currentLocation.deviceTimestamp * 1000).toISOString()
                                        ],
                                    ]}
                                />
                            );
                            case locationView.range: return (
                                <div style={{margin: 10}}>
                                    <TextField
                                        type={'datetime-local'}
                                        label={'Start time'}
                                        value={moment(this.state.startTime).format('YYYY-MM-DDTHH:mm')}
                                        onChange={this.onTimeChange('startTime')}
                                    />

                                    <TextField
                                        type={'datetime-local'}
                                        label={'End time'}
                                        value={moment(this.state.endTime).format('YYYY-MM-DDTHH:mm')}
                                        onChange={this.onTimeChange('endTime')}
                                    />

                                    <Button
                                        size={'large'}
                                        color={'primary'}
                                        variant={'outlined'}
                                        style={{marginLeft: 10}}
                                        onClick={this.fetchLocations}
                                    >Download locations</Button>
                                </div>
                            );
                            case locationView.maxSpeed: return (
                                <FormTable
                                    rows={[
                                        [
                                            'Max speed value',
                                            `${Math.floor(this.props.userDetails.maxSpeed * 3.6).toFixed(0)} km/h`,
                                        ],
                                        [
                                            'Max speed coordinates',
                                            `${this.props.userDetails.maxSpeedLocation.coordinates.lat.toFixed(6)}, ${this.props.userDetails.maxSpeedLocation.coordinates.lng.toFixed(6)}`
                                        ],
                                        [
                                            'Max speed deviceTimestamp',
                                            moment(this.props.userDetails.maxSpeedLocation.deviceTimestamp * 1000).toISOString()
                                        ],
                                    ]}
                                />
                            );
                            default: return null;
                        }
                    })()}
                </Grid>

                <Grid item xs={12}>
                    <div style={{margin: 10}}>
                        <GoogleMap
                            markers={this.prepareMarkers()}
                            coreContainer={this.props.coreContainer}
                        />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    {this.state.viewMode === locationView.range && this.state.locations.length ? (
                        <FormTable
                            header={[
                                'locationId',
                                'lat',
                                'lng',
                                'mode',
                                'modeConfidence',
                                'distanceDiff',
                                'speed',
                                'createdAt',
                                'deviceTimestamp',
                            ]}
                            rows={this.state.locations.sort((left, right) => left.deviceTimestamp - right.deviceTimestamp).map((location, index, locations) => {
                                const distance = index > 0 ? Haversine({
                                    latitude: location.lat,
                                    longitude: location.lng,
                                }, {
                                    latitude: locations[index - 1].lat,
                                    longitude: locations[index - 1].lng,
                                } as any, {
                                    unit: 'meter',
                                }) : null;

                                return [
                                    location.locationId,
                                    location.lat,
                                    location.lng,
                                    location.mode.join(', '),
                                    location.modeConfidence,
                                    distance ? distance.toFixed(0) + 'm' : ' - ',
                                    location.speed && location.speed !== -1 ? (location.speed * 3.6).toFixed(0) + ' km/h' : ' - ',
                                    moment(location.createdAt).toISOString(),
                                    moment(location.deviceTimestamp * 1000).toISOString(),
                                ];
                            })}
                        />
                    ) : null}
                </Grid>
            </Grid>
        );
    }
}

export default EventDetailsUserDetailsMap;
