import React from 'react';
import {ICoreContainerRequired} from "../../../core/ICoreContainer";
import SubModule from "../../usages/SubModule";
import ScheduleActivityTypes from "./submodules/ScheduleActivityTypes";
import EulaConfig from "./submodules/EulaConfig";

interface IConfigModuleProps extends ICoreContainerRequired {

}

class ConfigModule extends React.Component<IConfigModuleProps> {
    render() {
        return (
            <SubModule
                title={'Application config'}
                subModules={[
                    {
                        label: 'Schedule activity types',
                        component: (
                            <ScheduleActivityTypes
                                coreContainer={this.props.coreContainer}
                            />
                        ),
                    },
                    {
                        label: 'End User License Agreement',
                        component: (
                            <EulaConfig coreContainer={this.props.coreContainer} />
                        ),
                    },
                ]}
            />
        )
    }
}

export default ConfigModule;
