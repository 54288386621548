import {IAuthStore} from "./IAuthStore";
import {StoreAbstract} from "../store/StoreAbstract";
import {IAuthStoreStructure} from "./IAuthStoreStructure";

export class AuthStore extends StoreAbstract<IAuthStoreStructure> implements IAuthStore {
    constructor() {
        super({
            authorized: false,
            adminData: {
                adminId: null,
                email: null,
                name: null,
                superAdmin: null,
            },
        });
    }
}
