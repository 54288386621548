import React from 'react';
import {IEvent} from "../../../../core/models/IEvent";
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";
import {IMap, IMapRow} from "../../../../core/models/IMap";
import {FormControlLabel, Switch, Typography} from "@material-ui/core";
import moment from 'moment';
import FormTable from "../../../usages/FormTable";
import GoogleMap, {IGoogleMapMarker} from "../../../usages/GoogleMap";
import {IUser} from "../../../../core/models/IUser";
import {ICar} from "../../../../core/models/ICar";
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import PersonIcon from '@material-ui/icons/Person';
import ExpandablePanel from "../../../usages/ExpandablePanel";

interface IEventDetailsMapProps extends ICoreContainerRequired {
    eventData: IEvent;
}

interface IEventDetailsMapState {
    autoRefreshEnabled: boolean;
    mapData: IMap | null;
    lastRefresh: number | null;
    users: IUser[] | null;
    cars: ICar[] | null;
}

class EventDetailsMap extends React.Component<IEventDetailsMapProps, IEventDetailsMapState> {
    private _intervalId: number | undefined;

    state: IEventDetailsMapState = {
        autoRefreshEnabled: true,
        mapData: null,
        lastRefresh: null,
        users: null,
        cars: null,
    };

    componentDidMount(): void {
        this.fetchMap();
    }

    componentWillUnmount(): void {
        if (this._intervalId) {
            clearInterval(this._intervalId);
        }
    }

    setFetchInterval = (): void => {
        if (this._intervalId) {
            return;
        }

        this._intervalId = setInterval(() => {
            if (this.state.autoRefreshEnabled) {
                this.fetchMap();
            }
        }, 2000) as unknown as number;
    };

    fetchDictionaries = async () => {
        return new Promise(async (resolve) => {
            const users = await this.props.coreContainer.IEventUsersService.getEventUsers(
                this.props.eventData.eventId,
            );

             const cars = await this.props.coreContainer.IEventCarsService.getCars(
                 this.props.eventData.eventId,
             );

            this.setState({
                users,
                cars,
            }, () => {
                resolve();
            })
        });
    };

    fetchMap = async (): Promise<void> => {
        if (!this.state.users || !this.state.cars) {
            await this.fetchDictionaries();
        }

        const mapData: IMap = await this.props.coreContainer.IHttpService.eventMap(this.props.eventData.eventId);

        if (!this._intervalId) {
            this.setFetchInterval();
        }

        if (!mapData) {
            return;
        }

        this.setState({
            mapData,
            lastRefresh: moment().valueOf(),
        });
    };

    resolveUserName = (userId: string): string => {
        if (!this.state.users) {
            return ' - ';
        }

        const user = this.state.users.find((user) => user.userId === userId);
        return user ? user.name : ' - ';
    };

    resolveCarName = (carId: string): string => {
        if (!this.state.cars) {
            return ' - ';
        }

        const car = this.state.cars.find((car) => car.carId === carId);
        return car ? `${car.name} - ${car.licensePlate}` : ' - ';
    };

    toggleAutoRefresh = () => this.setState({
        autoRefreshEnabled: !this.state.autoRefreshEnabled,
    });

    render() {
        if (!this.state.mapData || !this.state.lastRefresh || !this.state.users || !this.state.cars) {
            return null;
        }

        return (
            <React.Fragment>
                <FormControlLabel
                    control={(<Switch checked={this.state.autoRefreshEnabled} onChange={this.toggleAutoRefresh} />)}
                    label={'Auto refresh'}
                />

                <Typography>Last update: {moment(this.state.lastRefresh).format('YYYY-MM-DD HH:mm:ss')}</Typography>

                {this.state.mapData.length ? (
                    <GoogleMap
                        markers={this.state.mapData.map((row): IGoogleMapMarker => ({
                            text: `${row[0] === 0 ? 'User' : 'Car'} - ${row[1]}`,
                            lat: row[2],
                            lng: row[3],
                            color: row[4] ? 'red' : 'green',
                            icon: row[0] === 0 ? PersonIcon as any : DirectionsCarIcon as any,
                        }))}
                        coreContainer={this.props.coreContainer}
                    />
                ) : null}

                <FormTable
                    header={[
                        'ID',
                        'Type',
                        'Name',
                        'Coordinates',
                        'Course',
                        'State',
                        'Speed',
                        'Device Timestamp'
                    ]}
                    rows={this.state.mapData.map((row: IMapRow) => [
                        row[1],
                        row[0] === 0 ? 'User' : 'Car',
                        row[0] === 0 ? this.resolveUserName(row[1]) : this.resolveCarName(row[1]),
                        `${row[2].toFixed(5)}, ${row[3].toFixed(5)}`,
                        (row[7] && row[7] !== -1) ? Math.ceil(row[7]) : ' - ',
                        row[4] ? 'Stopped' : 'Moving',
                        (row[6] && row[6] !== -1) ? `${Math.ceil(row[6] * 3.6)} km/h` : ' - ',
                        moment(row[5] * 1000).format('YYYY-MM-DD HH:mm'),
                    ])}
                    styles={[
                        {
                            width: 150,
                        },
                        {
                            width: 100,
                        },
                        {

                        },
                        {
                            width: 170,
                        },
                        {
                            width: 100,
                        },
                        {
                            width: 100,
                        },
                        {
                            width: 100,
                        },
                        {
                            width: 170,
                        },
                    ]}
                />

                <ExpandablePanel label={'JSON'}>
                    <pre>{JSON.stringify(this.state.mapData, null, 2)}</pre>
                </ExpandablePanel>
            </React.Fragment>
        );
    }
}

export default EventDetailsMap;
