import React from 'react';
import {ICoreContainerRequired} from "../../../core/ICoreContainer";
import SubModule from "../../usages/SubModule";
import EventsList from "./list/EventsList";

export interface IEventsModuleProps extends ICoreContainerRequired {

}

class EventsModule extends React.Component<IEventsModuleProps> {
    render() {
        return (
            <SubModule
                title={'Gran Turismo Events'}
                subModules={[
                    {
                        label: 'All instances',
                        component: <EventsList coreContainer={this.props.coreContainer} />
                    },
                ]}
            />
        );
    }
}

export default EventsModule;
