import React from 'react';
import {createStyles, Grid, IconButton, TextField, Theme, withStyles, WithStyles} from "@material-ui/core";
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";
import {IScheduleDay, IScheduleDayEditModel} from "../../../../core/models/IScheduleDay";
import {IScheduleActivityType} from "../../../../core/models/IScheduleActivityType";
import Image from "../../../usages/Image";
import DeleteIcon from '@material-ui/icons/Delete';
import ImageIcon from '@material-ui/icons/Image';
import SaveIcon from '@material-ui/icons/Save'
import FileUploadButton from "../../../usages/FileUploadButton";
import EventScheduleDayActivity from "./EventScheduleDayActivity";

const styles = (theme: Theme) => createStyles({
    dayGridContainer: {
        backgroundColor: 'white',
        marginBottom: 10,
        borderRadius: 10,
    },
    inputGridItem: {
        padding: 10,
    },
    dayImage: {
        width: '100%',
        marginTop: 6,
    },
});

interface IEventScheduleDay2Props extends ICoreContainerRequired, WithStyles<typeof styles> {
    eventId: string;
    fetchDays: () => any;
    dayDetails: IScheduleDay;
    activityTypes: IScheduleActivityType[];
}

interface IEventScheduleDay2State {
    changed: boolean;
    changes: IScheduleDayEditModel;
}

class EventScheduleDay2 extends React.Component<IEventScheduleDay2Props, IEventScheduleDay2State> {
    state: IEventScheduleDay2State = {
        changed: false,
        changes: {},
    };

    appendNewChange = (field: string, value: any) => this.setState({
        changes: {
            ...this.state.changes,
            [field]: value,
        },
    });

    onFieldChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => this.appendNewChange(field, event.currentTarget.value);

    onSaveClick = async () => {
        await this.props.coreContainer.IHttpService.eventScheduleEditDay(this.props.eventId, this.props.dayDetails.dayId, this.state.changes);

        this.setState({
            changed: false,
            changes: {},
        }, () => {
            this.props.fetchDays();
        });
    };

    onImageUpload = (fileId: string) => {
        this.appendNewChange('image', fileId);
    };

    onDeleteClick = async () => {
        if (!(await this.props.coreContainer.IDialogsController.confirm('Are you sure you want to delete this day from schedule?'))) {
            return;
        }

        await this.props.coreContainer.IHttpService.eventScheduleRemoveDay(this.props.eventId, this.props.dayDetails.dayId);

        await this.props.fetchDays();
    };

    canBeSaved = (): boolean => {
        return !!Object.keys(this.state.changes).length && Object.keys(this.state.changes).some((field: string) => {
            return (this.state.changes as any)[field] !== (this.props.dayDetails as any)[field];
        });
    };

    render() {
        return (
            <Grid
                className={this.props.classes.dayGridContainer}
                container
                item
                xs={12}
            >
                <Grid item xs={12} md={3} className={this.props.classes.inputGridItem}>
                    <Grid item>
                        <IconButton
                            color={'primary'}
                            disabled={!this.canBeSaved()}
                            onClick={this.onSaveClick}
                        >
                            <SaveIcon />
                        </IconButton>
                        <FileUploadButton onUpload={this.onImageUpload} coreContainer={this.props.coreContainer} color={'primary'}>
                            <ImageIcon />
                        </FileUploadButton>
                        <IconButton color={'secondary'} onClick={this.onDeleteClick}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                    {this.props.dayDetails.image ? (
                        <Image
                            key={this.state.changes.image ? this.state.changes.image : this.props.dayDetails.image}
                            className={this.props.classes.dayImage}
                            imageId={this.state.changes.image ? this.state.changes.image : this.props.dayDetails.image}
                            coreContainer={this.props.coreContainer}
                        />
                    ) : null}
                </Grid>

                <Grid
                    item
                    container
                    xs={12}
                    md={9}
                    alignItems={'flex-start'}
                >
                    <Grid item xs={12} md={4} className={this.props.classes.inputGridItem}>
                        <TextField
                            label={'Date'}
                            fullWidth
                            type={'date'}
                            value={!!this.state.changes.date ? this.state.changes.date : this.props.dayDetails.date}
                            onChange={this.onFieldChange('date')}
                        />
                    </Grid>
                    <Grid item xs={12} md={8} className={this.props.classes.inputGridItem}>
                        <TextField
                            label={'Title'}
                            fullWidth
                            value={!!this.state.changes.name || this.state.changes.name === '' ? this.state.changes.name : this.props.dayDetails.name}
                            onChange={this.onFieldChange('name')}
                        />
                    </Grid>
                    <Grid item xs={12} className={this.props.classes.inputGridItem}>
                        <TextField
                            label={'Description'}
                            fullWidth
                            multiline
                            rows={8}
                            value={!!this.state.changes.description || this.state.changes.description === '' ? this.state.changes.description : this.props.dayDetails.description}
                            onChange={this.onFieldChange('description')}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}>
                    {this.props.dayDetails.activities.map((activity) => (
                        <EventScheduleDayActivity
                            key={activity.activityId}
                            eventId={this.props.eventId}
                            day={this.props.dayDetails}
                            activity={activity}
                            fetchDays={this.props.fetchDays}
                            coreContainer={this.props.coreContainer}
                            newActivity={false}
                            activityTypes={this.props.activityTypes}
                        />
                    ))}

                    <EventScheduleDayActivity
                        eventId={this.props.eventId}
                        day={this.props.dayDetails}
                        activity={{}}
                        fetchDays={this.props.fetchDays}
                        coreContainer={this.props.coreContainer}
                        newActivity={true}
                        activityTypes={this.props.activityTypes}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(EventScheduleDay2);
