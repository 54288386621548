import React from 'react';
import {IPubSubAbstract} from "./IPubSubAbstract";

export default class PubSubWatcher {
    private readonly _subscriptionId: number;

    constructor(
        private readonly _pubSub: IPubSubAbstract<any>,
        component: React.Component,
    ) {
        this._subscriptionId = this._pubSub.subscribe(() => {
            component.forceUpdate();
        });
    }

    public unmount(): void {
        this._pubSub.unsubscribe(this._subscriptionId);
    }
}
