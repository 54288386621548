import React from 'react';
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";
import {
    Button,
    createStyles,
    Grid,
    TextField,
    Theme,
    Typography,
    withStyles,
    WithStyles
} from "@material-ui/core";
import FileUploadButton from "../../../usages/FileUploadButton";
import Image from "../../../usages/Image";
import moment from 'moment';

const styles = (theme: Theme) => createStyles({
    mainItem: {
        backgroundColor: 'white',
        borderRadius: 16,
        padding: 10,
        margin: 0,
    },
    dayImage: {
        width: '100%',
    },
});

interface IEventScheduleNewDayProps extends ICoreContainerRequired, WithStyles<typeof styles> {
    eventId: string;
    fetchDays: () => any;
    defaultDate: string | null;
}

interface IEventScheduleNewDayState {
    newDay: {
        date: string;
        image: string | null;
        name: string;
        description: string;
    };
}

class EventScheduleNewDay extends React.Component<IEventScheduleNewDayProps, IEventScheduleNewDayState> {
    state: IEventScheduleNewDayState = {
        newDay: {
            date: '',
            image: null,
            name: '',
            description: '',
        },
    };

    constructor(props: IEventScheduleNewDayProps) {
        super(props);

        if (props.defaultDate) {
            this.state.newDay.date = moment(props.defaultDate).add(1, 'day').format('YYYY-MM-DD');
        }
    }

    onImageUpload = (fileId: string) => this.setState({
        newDay: {
            ...this.state.newDay,
            image: fileId,
        },
    });

    onDateChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
        newDay: {
            ...this.state.newDay,
            date: event.target.value,
        },
    });

    onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
        newDay: {
            ...this.state.newDay,
            name: event.target.value,
        },
    });

    onAddNewDayClick = async () => {
        await this.props.coreContainer.IHttpService.eventScheduleAddDay(this.props.eventId, {
            date: this.state.newDay.date,
            image: this.state.newDay.image,
            name: this.state.newDay.name,
            description: this.state.newDay.description,
        });

        this.setState({
            newDay: {
                date: moment(this.state.newDay.date).add(1, 'day').format('YYYY-MM-DD'),
                image: null,
                name: '',
                description: '',
            },
        }, () => {
            this.props.fetchDays();
        });
    };

    render() {
        const { classes } = this.props;

        return (
            <Grid
                container
                item
                xs={12}
                spacing={2}
                className={classes.mainItem}
            >
                <Grid item xs={12}>
                    <Typography variant={'h6'} color={'textSecondary'}>New Schedule Day</Typography>
                </Grid>

                <Grid item xs={12} md={3}>
                    <TextField
                        label={'Date'}
                        type={'date'}
                        value={this.state.newDay.date}
                        onChange={this.onDateChange}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={3}>
                    <TextField
                        label={'New Day Name'}
                        type={'text'}
                        value={this.state.newDay.name}
                        onChange={this.onNameChange}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} style={{padding: 0}} />

                {this.state.newDay.image ? (
                    <Grid item xs={12} md={3}>
                        <Image
                            imageId={this.state.newDay.image}
                            coreContainer={this.props.coreContainer}
                            className={classes.dayImage}
                        />
                    </Grid>
                ) : null}

                <Grid container item xs={12} md={3} spacing={2}>
                    <Grid item xs={12} md={6}>
                        <FileUploadButton
                            onUpload={this.onImageUpload}
                            coreContainer={this.props.coreContainer}
                            variant={'outlined'}
                            color={'primary'}
                            fullWidth
                        >{this.state.newDay.image ? 'Change Picture' : 'Upload Picture'}</FileUploadButton>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            fullWidth
                            disabled={!this.state.newDay.date || !this.state.newDay.image}
                            onClick={this.onAddNewDayClick}
                        >Add to Schedule</Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(EventScheduleNewDay);
