import React from 'react';
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";
import {IEventDetails} from "../../../../core/models/IEvent";
import {Grid} from "@material-ui/core";
import EventDetailsGeneralCard from "./EventDetailsGeneralCard";

import PeopleIcon from '@material-ui/icons/People';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import MessageIcon from '@material-ui/icons/Message';
import WarningIcon from '@material-ui/icons/Warning';
import ScheduleIcon from '@material-ui/icons/Schedule';
import MapIcon from '@material-ui/icons/Map';
import WebIcon from '@material-ui/icons/Web';
import SettingsIcon from '@material-ui/icons/Settings';

interface IEventDetailsGeneralProps extends ICoreContainerRequired {
    eventId: string;
    eventData: IEventDetails;
    fetchEventData: () => any;
}

class EventDetailsGeneral extends React.Component<IEventDetailsGeneralProps, any> {
    onCardClick = (target: string) => () => {
        this.props.coreContainer.IAppRouter.push(`/events/${this.props.eventId}?view=${target}`);
    };

    render() {
        return (
            <Grid container spacing={4}>
                <Grid item xs={12}>

                </Grid>
                <EventDetailsGeneralCard
                    icon={<PeopleIcon fontSize={'large'}/>}
                    header={'Users'}
                    subheader={`${this.props.eventData.usersCount} users registered`}
                    quantity={this.props.eventData.usersCount}
                    onClick={this.onCardClick('users')}
                />
                <EventDetailsGeneralCard
                    icon={<DriveEtaIcon fontSize={'large'}/>}
                    header={'Cars'}
                    subheader={`${this.props.eventData.carsCount} cars registered`}
                    quantity={this.props.eventData.carsCount}
                    onClick={this.onCardClick('cars')}
                />

                <EventDetailsGeneralCard
                    icon={<MessageIcon fontSize={'large'}/>}
                    header={'Posts'}
                    subheader={`${this.props.eventData.postsCount} posts, ${this.props.eventData.postsCommentsCount} comments, ${this.props.eventData.postsLikesCount} likes`}
                    quantity={this.props.eventData.postsCount}
                    onClick={this.onCardClick('posts')}
                />

                <EventDetailsGeneralCard
                    icon={<WarningIcon fontSize={'large'}/>}
                    header={'Abuse reports'}
                    subheader={`${this.props.eventData.abuseReportsToReviewCount} reports to review`}
                    quantity={this.props.eventData.abuseReportsToReviewCount}
                    onClick={this.onCardClick('abuseReports')}
                />

                <EventDetailsGeneralCard
                    icon={<ScheduleIcon fontSize={'large'}/>}
                    header={'Schedule'}
                    subheader={`${this.props.eventData.scheduleDaysCount} days, ${this.props.eventData.scheduleActivitiesCount} activities`}
                    quantity={this.props.eventData.scheduleActivitiesCount}
                    onClick={this.onCardClick('schedule')}
                />

                <EventDetailsGeneralCard
                    icon={<MapIcon fontSize={'large'}/>}
                    header={'Map'}
                    subheader={`${this.props.eventData.mapCarsVisible} cars, ${this.props.eventData.mapUsersVisible} users visible`}
                    quantity={this.props.eventData.mapCarsVisible + this.props.eventData.mapUsersVisible}
                    onClick={this.onCardClick('map')}
                />

                <EventDetailsGeneralCard
                    icon={<WebIcon fontSize={'large'}/>}
                    header={'Web Map'}
                    subheader={`Embeddable map settings`}
                    quantity={null}
                    onClick={this.onCardClick('webMap')}
                />

                <EventDetailsGeneralCard
                    icon={<SettingsIcon fontSize={'large'}/>}
                    header={'Settings'}
                    subheader={`Important inputs and switches`}
                    quantity={null}
                    onClick={this.onCardClick('settings')}
                />
            </Grid>
        );
    }
}

export default EventDetailsGeneral;
