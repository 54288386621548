import React, {BaseSyntheticEvent} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, InputLabel,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import {IUserInsertModel} from "../../../../core/models/IUser";
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";
import {ICar} from "../../../../core/models/ICar";

interface IAddNewEventUserButtonProps extends ICoreContainerRequired {
    eventId: string;
    onAdd: (userId?: string) => any;
}

interface IAddNewEventUserButtonState {
    formOpened: boolean;
    userData: IUserInsertModel;
    cars: ICar[];
}

enum CarSpecialId {
    noCarSelected = 'noCarSelected',
    newCar = 'newCar',
}

class AddNewEventUserButton extends React.Component<IAddNewEventUserButtonProps, IAddNewEventUserButtonState> {
    private readonly _defaultUserData: IUserInsertModel = {
        country: '',
        city: '',
        email: '',
        phone: '',
        name: '',
        carId: CarSpecialId.noCarSelected,
    };

    constructor(props: IAddNewEventUserButtonProps) {
        super(props);

        this.state = {
            formOpened: false,
            userData: {...this._defaultUserData},
            cars: [],
        };
    }

    fetchCars = async (afterFetch?: () => any) => {
        this.setState({
            cars: await this.props.coreContainer.IEventCarsService.getCars(this.props.eventId),
        }, () => {
            if (afterFetch) {
                afterFetch();
            }
        });
    };

    formOpen = () => this.setState({
        formOpened: true,
    }, () => this.fetchCars());

    formClose = () => this.setState({
        formOpened: false,
        userData: {...this._defaultUserData},
    });

    canBeAdded = () => (
        !!this.state.userData.country &&
        !!this.state.userData.email &&
        !!this.state.userData.name
    );

    addUser = async () => {
        if (!this.canBeAdded()) {
            return;
        }

        const userData: IUserInsertModel = {
            ...this.state.userData,
            carId: this.state.userData.carId === CarSpecialId.noCarSelected ? null : this.state.userData.carId,
        };

        this.formClose();

        const userId: string | null = await this.props.coreContainer.IEventUsersService.addEventUser(this.props.eventId, userData);

        if (!userId) {
            this.props.coreContainer.ISnackbarsController.show({
                message: 'User create error',
                severity: 'error',
            });
            return;
        }

        this.props.onAdd();
        // if (await this.props.coreContainer.IDialogsController.confirm(`Do you want to open details of ${userData.name}?`)) {
        //     this.props.onAdd(userId);
        // } else {
        //     this.props.onAdd();
        // }
    };

    onFieldChange = (fieldName: string) => async (event: BaseSyntheticEvent) => {
        if (fieldName === 'carId' && event.target.value === CarSpecialId.newCar) {
            const newCarId: string | null = await this.props.coreContainer.IEventCarsService.addNewCar(
                this.props.eventId,
                await this.props.coreContainer.IEventUsersService.getEventUsers(this.props.eventId),
                false,
            );

            if (newCarId) {
                this.fetchCars(() => {
                    this.setState({
                        userData: {
                            ...this.state.userData,
                            carId: newCarId,
                        },
                    });
                });

                return;
            }

            return;
        }

        this.setState({
            userData: {
                ...this.state.userData,
                [fieldName]: event.target.value,
            },
        });
    };

    render() {
        return (
            <React.Fragment>
                <Button
                    variant={'outlined'}
                    size={'large'}
                    color={'primary'}
                    onClick={this.formOpen}
                >+ Add new User</Button>

                {this.state.formOpened ? (
                    <Dialog
                        open={this.state.formOpened}
                        maxWidth={'sm'}
                        fullWidth
                    >
                        <DialogTitle>Add new user</DialogTitle>

                        <DialogContent>
                            <TextField
                                label={'Name'}
                                fullWidth
                                value={this.state.userData.name}
                                onChange={this.onFieldChange('name')}
                                required
                            />

                            <TextField
                                label={'E-mail'}
                                fullWidth
                                value={this.state.userData.email}
                                onChange={this.onFieldChange('email')}
                                required
                            />

                            <TextField
                                label={'Phone number (with country prefix +XX)'}
                                fullWidth
                                value={this.state.userData.phone}
                                onChange={this.onFieldChange('phone')}
                                required
                            />

                            <TextField
                                label={'City'}
                                fullWidth
                                value={this.state.userData.city}
                                onChange={this.onFieldChange('city')}
                                required
                            />

                            <TextField
                                label={'Country'}
                                fullWidth
                                value={this.state.userData.country}
                                onChange={this.onFieldChange('country')}
                                required
                            />

                            <InputLabel
                                style={{marginTop: 10}}
                            >Car</InputLabel>
                            <Select
                                value={this.state.userData.carId}
                                fullWidth
                                onChange={this.onFieldChange('carId')}
                            >
                                <MenuItem
                                    value={CarSpecialId.noCarSelected}
                                >- No car selected -</MenuItem>
                                <MenuItem
                                    value={CarSpecialId.newCar}
                                >- New Car -</MenuItem>

                                {this.state.cars.map((car) => (
                                    <MenuItem
                                        key={car.carId}
                                        value={car.carId}
                                    >{car.name} - ({car.licensePlate})</MenuItem>
                                ))}
                            </Select>
                        </DialogContent>

                        <DialogActions>
                            <Button
                                color={'primary'}
                                disabled={!this.canBeAdded()}
                                onClick={this.addUser}
                            >Add user</Button>

                            <Button
                                color={'secondary'}
                                onClick={this.formClose}
                            >Cancel</Button>
                        </DialogActions>
                    </Dialog>
                ) : null}
            </React.Fragment>
        );
    }
}

export default AddNewEventUserButton;
