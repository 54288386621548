import React from 'react';
import {Chip} from "@material-ui/core";
import {IUser} from "../../../../core/models/IUser";
import AddIcon from '@material-ui/icons/Add'

interface IEventCarDetailsUsersProps {
    editing: boolean;
    users: IUser[];
    onUserClick: (userId: string) => any;
    onUnbindClick: (userId: string) => any;
    onBindNewUserClick: () => any;
}

class EventCarDetailsUsers extends React.Component<IEventCarDetailsUsersProps, any> {
    render() {
        return (
            <React.Fragment>
                {this.props.users.map((user) => (
                    <Chip
                        onClick={() => this.props.onUserClick(user.userId)}
                        onDelete={this.props.editing ? () => this.props.onUnbindClick(user.userId) : undefined}
                        label={user.name}
                        style={{marginRight: 10}}
                        color={'primary'}
                    />
                ))}
                <Chip
                    icon={<AddIcon />}
                    label={'Bind new user'}
                    onClick={this.props.onBindNewUserClick}
                    color={'primary'}
                    variant={'outlined'}
                    disabled={!this.props.editing}
                />
            </React.Fragment>
        );
    }
}

export default EventCarDetailsUsers;
