import React from 'react';
import {
    AppBar,
    Button,
    createStyles,
    Dialog, DialogContent, Grid,
    IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField,
    Theme,
    Toolbar, Typography,
    withStyles,
    WithStyles,
} from "@material-ui/core";
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";
import CloseIcon from "@material-ui/icons/Close";
import {IUser, IUserInsertModel} from "../../../../core/models/IUser";
import {v1 as uuidV1} from 'uuid';
import {ICar, ICarInsertModel} from "../../../../core/models/ICar";
import LinearProgressWithLabel from "../../../usages/LinearProgressWithLabel";
import * as EmailValidator from 'email-validator';

const styles = (theme: Theme) => createStyles({

});

interface IImportUsersFromCsvButtonProps extends ICoreContainerRequired, WithStyles<typeof styles> {
    eventId: string;
    onInsert: () => any;
}

interface IBookingData {
    importId: string;
    bookingId: number;
    car: ICarInsertModel | null;
    carExternalId: number | null;
    users: (IUserInsertModel & {contactId: number})[];
}

interface IImportUsersFromCsvButtonState {
    bookings: IBookingData[];
    carsInserted: number;
    csvWithCars: string;
    csvWithCoDrivers: string;
    dialogOpened: boolean;
    eventCars: ICar[];
    eventUsers: IUser[];
    errors: string[];
    insertInProgress: boolean;
    usersInserted: number;
}

class ImportUsersFromCsvButton extends React.Component<IImportUsersFromCsvButtonProps, IImportUsersFromCsvButtonState> {
    private readonly _defaultState: IImportUsersFromCsvButtonState = {
        bookings: [],
        carsInserted: 0,
        csvWithCars: '',
        csvWithCoDrivers: '',
        dialogOpened: false,
        eventCars: [],
        eventUsers: [],
        errors: [],
        insertInProgress: false,
        usersInserted: 0,
    };

    constructor(props: IImportUsersFromCsvButtonProps) {
        super(props);
        this.state = {...this._defaultState};
    }

    componentDidMount(): void {
        this.fetchCars();
    }

    fetchCars = async () => this.setState({
        eventCars: await this.props.coreContainer.IEventCarsService.getCars(this.props.eventId),
        eventUsers: await this.props.coreContainer.IHttpService.eventUsersGet(this.props.eventId),
    });

    onDialogToggleRequest = () => this.setState({
        ...this._defaultState,
        dialogOpened: !this.state.dialogOpened,
    }, () => {
        if (this.state.dialogOpened) {
            this.fetchCars();
        }
    });

    onInsertClick = async () => {
        const allCarsCount: number = this.countAllCarsToInsert();
        const allUsersCount: number = this.countAllUsersToInsert();

        if (!allCarsCount && !allUsersCount) {
            return;
        }

        if (!(await this.props.coreContainer.IDialogsController.confirm((`Are you sure you want to add ${allCarsCount} cars and ${allUsersCount} users?`)))) {
            return;
        }

        await new Promise((resolve) => this.setState({
            insertInProgress: true,
        }, () => resolve()));

        for (const booking of this.state.bookings) {
            await this.insertSingleBooking(booking);
        }

        this.props.coreContainer.ISnackbarsController.show({
            message: 'Import finished',
            severity: 'success',
        });

        this.onDialogToggleRequest();
    };

    insertSingleBooking = (booking: IBookingData): Promise<void> => new Promise<void>(async (resolve, reject) => {
        const {
            car,
            users,
        } = booking;

        let carId: string | null = null;
        if (car) {
            const { resourceId } = await this.props.coreContainer.IHttpService.eventCarsPost(this.props.eventId, {
                name: car.name,
                year: car.year,
                color: car.color,
                licensePlate: car.licensePlate,
                image: '',
                externalId: car.externalId,
            });

            carId = resourceId;
        }

        for (const user of users) {
            try {
                await this.props.coreContainer.IHttpService.eventUsersPost(this.props.eventId, {
                    name: user.name,
                    email: user.email,
                    phone: user.phone,
                    carId: carId,
                    city: user.city,
                    country: user.country,
                });
            } catch (err) {
                console.error(err);
            }
        }

        this.setState({
            carsInserted: carId ? this.state.carsInserted + 1 : this.state.carsInserted,
            usersInserted: this.state.usersInserted + users.length,
        }, () => {
            setTimeout(() => {
                resolve();
            }, 100);
        });
    });

    onCsvWithCarsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
            bookings,
            errors,
        } = this.parseCsv(
            event.currentTarget.value,
            this.state.csvWithCoDrivers,
        );

        this.setState({
            bookings: bookings,
            errors: errors,
            csvWithCars: event.currentTarget.value,
        });
    };

    onCsvWithCoDriversChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
            bookings,
            errors,
        } = this.parseCsv(
            this.state.csvWithCars,
            event.currentTarget.value,
        );

        this.setState({
            bookings: bookings,
            csvWithCoDrivers: event.currentTarget.value,
            errors: errors,
        });
    };

    parseCsv = (
        csvWithCars: string,
        csvWithDrivers: string,
    ): {
        bookings: IBookingData[],
        errors: string[],
    } => {
        const errors: string[] = [];

        const owners: string[][] = csvWithCars.split('\n').map((row) => row.split(';'));
        const coDrivers: string[][] = csvWithDrivers.split('\n').map((row) => row.split(';'));

        const allEmails: string[] = [];
        const isEmailFree = (email: string): boolean => {
            if (!EmailValidator.validate(email)) {
                errors.push(`INVALID_EMAIL: Email "${email}" is invalid - skipping this line`);
                return false;
            }

            if (allEmails.includes(email.toLowerCase())) {
                errors.push(`DUPLICATED_EMAIL: Email "${email}" is duplicated in this import (importing only first occurence)`);
                return false;
            }

            if (this.state.eventUsers.some((user) => user.email === email.toLowerCase())) {
                errors.push(`EMAIL_TAKEN: User with email "${email}" already exists in this event`);
                return false;
            }

            allEmails.push(email);
            return true;
        };

        const allCarsIds: number[] = [];
        const isCarIdFree = (carId: number): boolean => {
            if (allCarsIds.includes(carId)) {
                errors.push(`DUPLICATED_CAR_ID: Car with carId #${carId} is duplicated in this import (importing only first occurence)`);
                return false;
            }

            if (this.state.eventCars.some((car) => car.externalId === carId)) {
                errors.push(`CAR_ID_TAKEN: Car with carId #${carId} already exists in this event`);
                return false;
            }

            allCarsIds.push(carId);
            return true;
        };

        const bookings: IBookingData[] = [];
        const pushUserToBooking = (
            bookingId: number,
            user: IUserInsertModel,
            bookingImportId?: string,
        ): void => {
            for (const booking of bookings) {
                if (booking.bookingId !== bookingId) {
                    continue;
                }

                if (bookingImportId !== undefined && booking.importId !== bookingImportId) {
                    continue;
                }

                booking.users.push(user);
                return;
            }

            bookings.push({
                importId: uuidV1(),
                bookingId: bookingId,
                car: null,
                carExternalId: null,
                users: [user],
            });
        };

        for (const owner of owners) {
            if (owner.length !== 12) {
                errors.push(`Bad row: ${owner.join(';')}`);
                continue;
            }

            const [
                bookingIdString,
                carIdString,
                makeModel,
                year,
                color,
                license,
                contactIdString,
                userName,
                userEmail,
                userTele,
                userCity,
                userCountry,
            ] = owner;

            if (!userEmail) {
                continue;
            }

            const importId: string = uuidV1();
            const bookingId: number = Number(bookingIdString);
            const carId: number = Number(carIdString);

            if (isCarIdFree(carId)) {
                const car: ICarInsertModel = {
                    color: color,
                    licensePlate: license,
                    name: makeModel,
                    year: year,
                    externalId: carId,
                    image: '',
                };

                bookings.push({
                    importId: importId,
                    bookingId: bookingId,
                    car: car,
                    carExternalId: carId,
                    users: [],
                });
            }

            if (!isEmailFree(userEmail)) {
                continue;
            }

            pushUserToBooking(bookingId, {
                country: userCountry,
                city: userCity,
                email: userEmail,
                phone: userTele,
                name: userName,
                carId: null,
                isAdmin: false,
                contactId: Number(contactIdString),
            }, importId);
        }

        for (const coDriver of coDrivers) {
            if (coDriver.length !== 7) {
                continue;
            }

            const [
                bookingIdString,
                contactIdString,
                name,
                email,
                tele,
                city,
                country,
            ] = coDriver;

            if (!isEmailFree(email)) {
                continue;
            }

            const bookingId: number = Number(bookingIdString);
            pushUserToBooking(bookingId, {
                country: country,
                city: city,
                email: email,
                phone: tele,
                name: name,
                carId: null,
                isAdmin: false,
                contactId: Number(contactIdString),
            });
        }

        return {
            bookings: bookings,
            errors: errors,
        };
    };

    countAllCarsToInsert = (): number => this.state.bookings.filter((booking) => booking.car).length;

    countAllUsersToInsert = (): number => this.state.bookings.reduce((acc, cur) => acc + cur.users.length, 0);

    render() {
        const allCarsCount: number = this.countAllCarsToInsert();
        const allUsersCount: number = this.countAllUsersToInsert();

        return (
            <React.Fragment>
                <Button
                    variant={'outlined'}
                    color={'primary'}
                    size={'large'}
                    style={{
                        marginLeft: 10,
                    }}
                    onClick={this.onDialogToggleRequest}
                >+ Add Users from CSV</Button>

                <Dialog
                    open={this.state.dialogOpened}
                    fullScreen
                >
                    <AppBar
                        style={{
                            position: 'relative',
                            marginBottom: 20,
                        }}
                    >
                        <Toolbar>
                            <IconButton
                                edge={'start'}
                                color={'inherit'}
                                onClick={this.onDialogToggleRequest}
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography
                                variant={'h6'}
                                style={{
                                    flex: 1,
                                }}
                            >
                                Add Users from CSV
                            </Typography>
                            <Button
                                color={'inherit'}
                                variant={'outlined'}
                                autoFocus
                                onClick={this.onInsertClick}
                                disabled={this.state.insertInProgress}
                            >Add Users</Button>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <Grid container>
                            {!this.state.insertInProgress ? (
                                <React.Fragment>
                                    <Grid item xs={12}>
                                        <p>
                                            Please insert data in format: <br />
                                            BookingId;CarId;MakeModel;Year;Color;License;ContactId;Name;Email;Tele;City;Country
                                        </p>
                                    </Grid>
                                    <Grid item xs={12} style={{marginBottom: 30}}>
                                        <TextField
                                            multiline={true}
                                            fullWidth
                                            value={this.state.csvWithCars}
                                            onChange={this.onCsvWithCarsChange}
                                            rows={5}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p>
                                            Please insert data in format: <br />
                                            BookingId;ContactId;Name;Email;Tele;City;Country
                                        </p>
                                    </Grid>
                                    <Grid item xs={12} style={{marginBottom: 30}}>
                                        <TextField
                                            multiline={true}
                                            fullWidth
                                            value={this.state.csvWithCoDrivers}
                                            onChange={this.onCsvWithCoDriversChange}
                                            rows={5}
                                        />
                                    </Grid>
                                </React.Fragment>
                            ) : (() => {
                                const insertedCarsPercentage = allCarsCount ? (this.state.carsInserted / allCarsCount) * 100 : 0;
                                const insertedUsersPercentage: number = allUsersCount ? (this.state.usersInserted / allUsersCount) * 100 : 0;

                                return (
                                    <React.Fragment>
                                        <Grid item xs={12}>
                                            <Typography>Cars insert progress:</Typography>
                                            <LinearProgressWithLabel
                                                value={insertedCarsPercentage}
                                                label={`${this.state.carsInserted}/${allCarsCount}`}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>Users insert progress:</Typography>
                                            <LinearProgressWithLabel
                                                value={insertedUsersPercentage}
                                                label={`${this.state.usersInserted}/${allUsersCount}`}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                );
                            })()}
                            {this.state.errors.length ? (
                                <Grid item xs={12} style={{color: 'red'}}>
                                    <Typography>Errors:</Typography>
                                    <ul>
                                        {this.state.errors.map((e) => <li key={uuidV1()}>{e}</li>)}
                                    </ul>
                                </Grid>
                            ) : null}

                            <Grid item xs={12}>
                                <ul>
                                    <li>{`Cars to insert: ${allCarsCount}`}</li>
                                    <li>{`Users to insert: ${allUsersCount}`}</li>
                                </ul>
                            </Grid>

                            <Grid item xs={12}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>CarId</TableCell>
                                            <TableCell>CarMakeModel</TableCell>
                                            <TableCell>CarYear</TableCell>
                                            <TableCell>CarColor</TableCell>
                                            <TableCell>CarLicensePlate</TableCell>
                                            <TableCell>UserName</TableCell>
                                            <TableCell>UserEmail</TableCell>
                                            <TableCell>UserTele</TableCell>
                                            <TableCell>UserCity</TableCell>
                                            <TableCell>UserCountry</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.bookings.map((booking) => {
                                            const rowSpan: number = booking.users.length;

                                            if (!booking.car) {
                                                return booking.users.map((user) => (
                                                    <TableRow key={uuidV1()}>
                                                        <TableCell colSpan={5} />
                                                        {[
                                                            user.name,
                                                            user.email,
                                                            user.phone,
                                                            user.city,
                                                            user.country,
                                                        ].map((value) => (
                                                            <TableCell
                                                                key={uuidV1()}
                                                            >{value}</TableCell>
                                                        ))}
                                                    </TableRow>
                                                ));
                                            }

                                            return [
                                                (
                                                    <TableRow key={uuidV1()}>
                                                        {[
                                                            booking.car.externalId,
                                                            booking.car.name,
                                                            booking.car.year,
                                                            booking.car.color,
                                                            booking.car.licensePlate,
                                                        ].map((value) => (
                                                            <TableCell
                                                                key={uuidV1()}
                                                                rowSpan={rowSpan + 1}
                                                            >{value}</TableCell>
                                                        ))}
                                                    </TableRow>
                                                ),
                                                ...booking.users.map((user) => (
                                                    <TableRow key={uuidV1()}>
                                                        {[
                                                            user.name,
                                                            user.email,
                                                            user.phone,
                                                            user.city,
                                                            user.country,
                                                        ].map((value) => (
                                                            <TableCell
                                                                key={uuidV1()}
                                                            >{value}</TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))
                                            ];
                                        })}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(ImportUsersFromCsvButton);
