import React from 'react';
import AppBar from '@material-ui/core/AppBar';
// import Avatar from '@material-ui/core/Avatar';
// import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
// import HelpIcon from '@material-ui/icons/Help';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
// import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
// import NotificationsIcon from '@material-ui/icons/Notifications';
// import Tab from '@material-ui/core/Tab';
// import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
// import Tooltip from '@material-ui/core/Tooltip';
// import Typography from '@material-ui/core/Typography';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import {ICoreContainerRequired} from "../../core/ICoreContainer";
import {PubSubConsumer} from "../../core/pubSub/PubSubConsumer";
import ExitIcon from '@material-ui/icons/ExitToApp'

const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = (theme: Theme) =>
    createStyles({
        secondaryBar: {
            zIndex: 0,
        },
        menuButton: {
            marginLeft: -theme.spacing(1),
        },
        iconButtonAvatar: {
            padding: 4,
        },
        link: {
            textDecoration: 'none',
            color: lightColor,
            '&:hover': {
                color: theme.palette.common.white,
            },
        },
        button: {
            borderColor: lightColor,
        },
        logoutButton: {
            color: 'white',
        },
    });

interface HeaderProps extends ICoreContainerRequired, WithStyles<typeof styles> {
    onDrawerToggle: () => void;
}

function Header(props: HeaderProps) {
    const { classes, onDrawerToggle, coreContainer } = props;

    const userFullName = coreContainer.IAuthStore.get().adminData.name;
    if (!userFullName) {
        return null;
    }

    const [ userFirstName ] = userFullName.split(' ');

    return (
        <React.Fragment>
            <AppBar color="primary" position="sticky" elevation={0}>
                <Toolbar>
                    <Grid container spacing={1} alignItems="center">
                        <Hidden smUp>
                            <Grid item>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={onDrawerToggle}
                                    className={classes.menuButton}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Grid>
                        </Hidden>
                        <Grid item xs />
                        <Grid item>
                            <PubSubConsumer pubSub={coreContainer.IAuthStore}>
                                Hi, {userFirstName}!
                            </PubSubConsumer>
                        </Grid>
                        {/*<Grid item>*/}
                        {/*    <Tooltip title="Alerts • No alerts">*/}
                        {/*        <IconButton color="inherit">*/}
                        {/*            <NotificationsIcon />*/}
                        {/*        </IconButton>*/}
                        {/*    </Tooltip>*/}
                        {/*</Grid>*/}
                        {/*<Grid item>*/}
                        {/*    <IconButton color="inherit" className={classes.iconButtonAvatar}>*/}
                        {/*        <Avatar src="/static/images/avatar/1.jpg" alt="My Avatar" />*/}
                        {/*    </IconButton>*/}
                        {/*</Grid>*/}
                        <Grid item>
                            <IconButton className={classes.logoutButton} onClick={coreContainer.IAuthService.logout}>
                                <ExitIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}

export default withStyles(styles)(Header);
