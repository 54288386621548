import React from 'react';
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";
import {
    AppBar,
    Button,
    Dialog,
    DialogContent,
    Grid,
    IconButton, Table, TableBody, TableCell, TableRow,
    TextField,
    Toolbar,
    Typography
} from "@material-ui/core";
import {IUser} from "../../../../core/models/IUser";
import CloseIcon from "@material-ui/icons/Close";

export enum SmsDialogModeEnum {
    text,
    code,
}

interface IUserSendable extends IUser {
    sent: boolean;
}

interface IEventUsersMultipleSmsDialogProps extends ICoreContainerRequired {
    mode: SmsDialogModeEnum;
    onCloseRequest: () => any;
    users: IUser[];
}

interface IEventUsersMultipleSmsDialogState {
    smsBody: string;
    users: IUserSendable[];
}

class EventUsersMultipleSmsDialog extends React.Component<IEventUsersMultipleSmsDialogProps, IEventUsersMultipleSmsDialogState> {
    state: IEventUsersMultipleSmsDialogState = {
        smsBody: '',
        users: [],
    };

    constructor(props: IEventUsersMultipleSmsDialogProps) {
        super(props);

        this.state.users = props.users.map((user) => ({
            ...user,
            sent: false,
        }));

        if (props.mode === SmsDialogModeEnum.code) {
            this.state.smsBody = 'Hi <NAME>! Welcome to Gran Turismo Polonia. In order to read the schedule and connect with other drivers you need the DRIVER app. Download it here: https://apps.apple.com/us/app/gran-turismo-driver/id1508586559 Your login code is <CODE>.';
        }
    }

    onSendClick = async () => {
        if (!(await this.props.coreContainer.IDialogsController.confirm(`Are you sure you want to send SMS to ${this.state.users.length} users?`))) {
            return;
        }

        for (const user of this.state.users) {
            await this.sendSingleSms(user);
        }

        const allSmsSent = this.state.users.every((user) => user.sent);
        if (allSmsSent) {
            this.props.coreContainer.ISnackbarsController.show({
                message: `All SMS (${this.state.users.length}) sent successfully`,
                severity: 'success',
            })
        } else {
            const smsSentSuccessfully = this.state.users.filter((user) => user.sent).length;
            this.props.coreContainer.ISnackbarsController.show({
                message: `${smsSentSuccessfully} of ${this.state.users.length} sent successfully`
            });
        }

        this.props.onCloseRequest();
    };

    sendSingleSms = async (user: IUser) => {
        return new Promise(async (resolve) => {
            try {
                await this.props.coreContainer.ISmsService.sendSingleSms(
                    user.phone,
                    this.getUserMessage(user.userId),
                );

                this.setState({
                    users: this.state.users.map((u) => u.userId === user.userId ? ({
                        ...u,
                        sent: true,
                    }) : u)
                }, () => resolve());
            } catch {
                resolve();
            }
        });
    };

    getUserMessage = (userId: string) => {
        if (this.props.mode === SmsDialogModeEnum.code) {
            const userData = this.props.users.find((user) => user.userId === userId);
            if (!userData) {
                throw new Error('USER_NOT_FOUND');
            }

            const [
                userName,
                userSurname,
            ] = userData.name.split(' ');

            return this.state.smsBody
                .replace('<CODE>', userData.code)
                .replace('<NAME>', userName)
                .replace('<SURNAME>', userSurname);
        }

        return this.state.smsBody;
    };

    render() {
        return (
            <Dialog open={true} fullScreen>
                <AppBar style={{
                    position: 'relative',
                    marginBottom: 20,
                }}>
                    <Toolbar>
                        <IconButton
                            edge={'start'}
                            color={'inherit'}
                            onClick={this.props.onCloseRequest}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant={'h6'}
                            style={{
                                flex: 1,
                            }}
                        >
                            SMS
                        </Typography>
                        <Button
                            color={'inherit'}
                            variant={'outlined'}
                            autoFocus
                            onClick={this.onSendClick}
                            disabled={!this.state.smsBody}
                        >Send SMS</Button>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                label={'Message'}
                                onChange={(event) => this.setState({smsBody: event.currentTarget.value})}
                                value={this.state.smsBody}
                                fullWidth
                                multiline={true}
                            />
                        </Grid>
                        <Grid item xs={12} style={{marginTop: 20}}>
                            <Table>
                                <TableBody>
                                    {this.state.users.map((user) => (
                                        <TableRow key={user.userId}>
                                            <TableCell style={{width: 300}}>{user.name}</TableCell>
                                            <TableCell style={{width: 150}}>{user.phone}</TableCell>
                                            <TableCell style={{width: 100}}>{user.sent ? 'Sent' : 'Not sent'}</TableCell>
                                            <TableCell>{this.getUserMessage(user.userId)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}

export default EventUsersMultipleSmsDialog;
