import {IAuthTokenStore} from "./IAuthTokenStore";
import * as localStorage from 'local-storage';

export class AuthTokenStore implements IAuthTokenStore {
    private readonly _tokenStoredKey: string = 'gtpAdminToken';
    private _token: string | undefined;

    public getToken(): string | undefined {
        if (!this._token) {
            this._token = this.getLocalStorageToken();
        }

        return this._token;
    }

    public removeToken(): void {
        this._token = undefined;
        this.setLocalStorageToken(undefined);
    }

    public setToken(token: string): void {
        this._token = token;
        this.setLocalStorageToken(token);
    }

    private getLocalStorageToken(): string | undefined {
        return localStorage.get(this._tokenStoredKey);
    }

    private setLocalStorageToken(token: string | undefined): void {
        localStorage.set(this._tokenStoredKey, token);
    }
}
