import React from 'react';
import {
    createStyles,
    Grid,
    LinearProgress,
    Table, TableCell,
    TableRow,
    Theme,
    Typography,
    WithStyles,
    withStyles
} from "@material-ui/core";
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";
import {IUsersActiveInLast5MinutesResponseUserRowDto} from "../../../../core/models/IStats";
import moment from "moment";

const styles = (theme: Theme) => createStyles({
    usersTable: {
        backgroundColor: 'white',
        marginTop: 16,
    },
});

interface ILastActiveUsersProps extends ICoreContainerRequired, WithStyles<typeof styles> {

}

interface ILastActiveUsersState {
    initialized: boolean;
    users: IUsersActiveInLast5MinutesResponseUserRowDto[];
}

class LastActiveUsers extends React.Component<ILastActiveUsersProps, ILastActiveUsersState> {
    state: ILastActiveUsersState = {
        initialized: false,
        users: [],
    };

    componentDidMount(): void {
        this.fetchUsers();
    }

    fetchUsers = async () => {
        const { users } = await this.props.coreContainer.IHttpService.statsGetUsersActiveInLast5Minutes();
        this.setState({
            initialized: true,
            users,
        });
    };

    navigateToUser = (user: IUsersActiveInLast5MinutesResponseUserRowDto) => {
        // this.props.coreContainer.IAppRouter.push(`/events/${user.eventId}?view=users&user=${user.userId}`);
    };

    render() {
        return (
            <Grid
                container
            >
                <Grid
                    item
                    xs={6}
                >
                    <Typography
                        variant={'h5'}
                    >
                        Users active in last 5 minutes
                        {this.state.initialized ? ` (${this.state.users.length})` : null}
                    </Typography>
                    {this.state.initialized ? (
                        <Table className={this.props.classes.usersTable}>
                            {this.state.users.map((user) => (
                                <TableRow
                                    key={user.userId}
                                    onClick={() => this.navigateToUser(user)}
                                    hover
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                >
                                    <TableCell>
                                        {user.eventName}
                                    </TableCell>
                                    <TableCell>
                                        {user.userName}
                                    </TableCell>
                                    <TableCell>
                                        {user.carName}
                                    </TableCell>
                                    <TableCell>
                                        {moment(user.userLastActivity).format('YYYY-MM-DD HH:mm:ss')}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </Table>
                    ) : (
                        <LinearProgress />
                    )}
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(LastActiveUsers);
