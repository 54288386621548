import React from 'react';
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";
import {
    Button,
    ButtonGroup,
    createStyles, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid,
    LinearProgress,
    TextField,
    Theme, Typography,
    withStyles,
    WithStyles
} from "@material-ui/core";
import {IUserDetails, IUserInsertModel} from "../../../../core/models/IUser";
import {ICar} from "../../../../core/models/ICar";
import {FormDialogInputTypeEnum} from "../../../../core/dialogsController/FormDialogInputTypeEnum";
import CarCard from "../cars/CarCard";
import EventDetailsUserDetailsMap from "./EventDetailsUserDetailsMap";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Image from "../../../usages/Image";
import FileUploadButton from "../../../usages/FileUploadButton";

const styles = (theme: Theme) => createStyles({
    topButtonGroup: {
        marginBottom: 10,
        marginRight: 10,
    },
    expansionPanelSummaryTypography: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    basicDataInputContainer: {
        marginBottom: 5,
    },
});

interface IEventDetailsUserDetailsProps extends ICoreContainerRequired, WithStyles<typeof styles> {
    eventId: string;
    userId: string;
    onCloseRequest: () => any;
}

interface IEventDetailsUserDetailsState {
    fetched: boolean;
    editing: boolean;
    userDetails: IUserDetails | null;
    userChanges: Partial<IUserDetails>;
}

class EventDetailsUserDetails extends React.Component<IEventDetailsUserDetailsProps, IEventDetailsUserDetailsState> {
    state: IEventDetailsUserDetailsState = {
        fetched: false,
        editing: true,
        userDetails: null,
        userChanges: {},
    };

    componentDidMount() {
        this.fetchUserData();
    }

    fetchUserData = async () => {
        const userDetails: IUserDetails = await this.props.coreContainer.IEventUsersService.getUserDetails(
            this.props.eventId,
            this.props.userId,
        );

        this.setState({
            fetched: true,
            userDetails,
            userChanges: {},
        });
    };

    editingToggle = () => this.setState({
        editing: !this.state.editing,
        userChanges: {},
    });

    onFieldChange = (field: keyof IUserInsertModel) => (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
        userChanges: {
            ...this.state.userChanges,
            [field]: event.currentTarget.value,
        },
    });

    saveChanges = async () => {
        await this.props.coreContainer.IEventUsersService.editEventUser(
            this.props.eventId,
            this.props.userId,
            this.state.userChanges,
        );

        this.fetchUserData();
    };

    deleteUser = async () => {
        if (await this.props.coreContainer.IEventUsersService.deleteUser(this.props.eventId, this.props.userId)) {
            this.props.onCloseRequest();
        }
    };

    changeCar = async () => {
        const cars: ICar[] = await this.props.coreContainer.IEventCarsService.getCars(this.props.eventId);
        const formResult = await this.props.coreContainer.IDialogsController.form({
            title: 'Select User Car',
            inputs: [
                {
                    type: FormDialogInputTypeEnum.select,
                    options: [
                        {
                            label: '- No car -',
                            value: 'NO_CAR_SELECTED',
                        },
                        ...cars.map((car) => ({
                            label: `${car.name} - ${car.licensePlate}`,
                            value: car.carId,
                        }))
                    ],
                    label: 'Select a car',
                    field: 'carId',
                    defaultValue: this.state.userDetails?.carId ? this.state.userDetails.carId : 'NO_CAR_SELECTED',
                },
            ],
            confirmText: 'Change',
        });

        if (!formResult) {
            return;
        }

        await this.props.coreContainer.IEventUsersService.editEventUser(this.props.eventId, this.props.userId, {
            carId: formResult.carId === 'NO_CAR_SELECTED' ? null : formResult.carId,
        });

        this.fetchUserData();
    };

    toggleAdminPrivileges = async () => {
        if (!(await this.props.coreContainer.IDialogsController.confirm('Are you sure?'))) {
            return;
        }

        await this.props.coreContainer.IEventUsersService.editEventUser(this.props.eventId, this.props.userId, {
            isAdmin: !this.state.userDetails?.isAdmin,
        });

        this.fetchUserData();
    };

    sendSms = async () => {
        if (!this.state.userDetails) {
            return;
        }

        const {
            code: userCode,
            name: userFullName,
            phone: userPhoneNumber,
        } = this.state.userDetails;


        const [ userFirstName ] = userFullName.split(' ');

        const defaultSmsText: string = `Hi ${userFirstName}! Your login code is ${userCode}. Enjoy!`;

        const smsText: string = await this.props.coreContainer.IDialogsController.prompt(
            `SMS to ${userFullName}:`,
            defaultSmsText,
        );

        if (!smsText) {
            return;
        }

        const smsSendResult: boolean = await this.props.coreContainer.ISmsService.sendSingleSms(
            userPhoneNumber,
            smsText,
        );

        if (smsSendResult) {
            this.props.coreContainer.ISnackbarsController.show({
                message: 'SMS sent successfully',
                severity: 'success',
            });
        } else {
            this.props.coreContainer.ISnackbarsController.show({
                message: 'SMS send error',
                severity: 'error',
            });
        }
    };

    sendPushNotification = async () => {
        if (!this.state.userDetails) {
            return;
        }

        const notificationTitle: string = await this.props.coreContainer.IDialogsController.prompt(
            `Notification to ${this.state.userDetails.name} title:`,
            'Notification from Administrator'
        );

        const notificationBody: string = await this.props.coreContainer.IDialogsController.prompt(
            `Notification to ${this.state.userDetails.name} body:`,
        );

        if (!notificationBody) {
            return;
        }

        const sendResult = await this.props.coreContainer.IHttpService.eventUsersSendPushNotification(
            this.props.eventId,
            this.props.userId,
            notificationTitle,
            notificationBody,
        );

        if (sendResult.success) {
            this.props.coreContainer.ISnackbarsController.show({
                message: 'Push notification sent successfully',
                severity: 'success',
            });
        } else {
            this.props.coreContainer.ISnackbarsController.show({
                message: 'Push notification sent error',
                severity: 'error',
            });
        }
    };

    destroyUserSession = async () => {
        if (!(await this.props.coreContainer.IDialogsController.confirm(`Are you sure?`))) {
            return;
        }

        await this.props.coreContainer.IHttpService.eventUsersRevokeAccess(
            this.props.eventId,
            this.props.userId,
        );

        this.fetchUserData();
    };

    onImageUpload = async (uploadedImageId: string) => {
        this.setState({
            userChanges: {
                ...this.state.userChanges,
                image: uploadedImageId,
            },
        });
    };

    deleteProfilePhoto = () => {
        this.setState({
            userChanges: {
                ...this.state.userChanges,
                image: '',
            },
        });
    };

    onCarClick = () => {
        if (!this.state.userDetails || !this.state.userDetails.carId) {
            return;
        }

        this.props.coreContainer.IAppRouter.push(
            `/events/${this.props.eventId}?view=cars&carId=${this.state.userDetails.carId}`
        );
    };

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <ButtonGroup className={classes.topButtonGroup}>
                    <Button
                        variant={'outlined'}
                        color={'primary'}
                        onClick={this.props.onCloseRequest}
                    >{'< back to users list'}</Button>

                    {this.state.editing ? [
                        (<Button
                            key={'saveChanges'}
                            variant={'outlined'}
                            color={'primary'}
                            onClick={this.saveChanges}
                            disabled={!(Object.keys(this.state.userChanges).length)}
                        >Save changes</Button>),
                        // (<Button
                        //     key={'abortChanges'}
                        //     variant={'outlined'}
                        //     color={'secondary'}
                        //     onClick={this.editingToggle}
                        // >Finish editing</Button>),
                    ] : (
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            onClick={this.editingToggle}
                        >Edit user</Button>
                    )}
                </ButtonGroup>

                <ButtonGroup className={classes.topButtonGroup}>
                    <Button
                        variant={'outlined'}
                        color={'primary'}
                        onClick={this.changeCar}
                        disabled={!this.state.editing}
                    >Change car</Button>

                    <Button
                        variant={'outlined'}
                        color={'primary'}
                        onClick={this.toggleAdminPrivileges}
                        disabled={!this.state.editing}
                    >{this.state.userDetails?.isAdmin ? 'Revoke Admin Privileges' : 'Grant Admin Privileges'}</Button>

                    {this.state.userDetails?.logged ? (
                        <Button
                            variant={'outlined'}
                            color={'secondary'}
                            onClick={this.destroyUserSession}
                            disabled={!this.state.editing}
                        >Destroy user session</Button>
                    ) : null}

                    <Button
                        variant={'outlined'}
                        color={'secondary'}
                        onClick={this.deleteUser}
                        disabled={!this.state.editing}
                    >Delete user</Button>
                </ButtonGroup>

                <ButtonGroup>
                    <Button
                        variant={'outlined'}
                        color={'primary'}
                        onClick={this.sendSms}
                    >Send SMS with code</Button>

                    <Button
                        variant={'outlined'}
                        color={'primary'}
                        onClick={this.sendPushNotification}
                        disabled={!this.state.userDetails?.firebaseTokenRegistered}
                    >Send Push Notification</Button>
                </ButtonGroup>

                {this.state.fetched && this.state.userDetails ? (
                    (
                        <React.Fragment>
                            <ExpansionPanel defaultExpanded={true}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography className={classes.expansionPanelSummaryTypography}>Basic user data</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid container>
                                        <Grid item xs={12} md={4}>
                                            <Grid item xs={12} className={classes.basicDataInputContainer}>
                                                <TextField
                                                    label={'Code'}
                                                    value={this.state.userDetails?.code}
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.basicDataInputContainer}>
                                                <TextField
                                                    label={'Name'}
                                                    value={this.state.userChanges.name ? this.state.userChanges.name : this.state.userDetails?.name}
                                                    disabled={!this.state.editing}
                                                    onChange={this.onFieldChange('name')}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.basicDataInputContainer}>
                                                <TextField
                                                    label={'Email'}
                                                    value={this.state.userChanges.email !== undefined ? this.state.userChanges.email : this.state.userDetails?.email}
                                                    disabled={!this.state.editing}
                                                    onChange={this.onFieldChange('email')}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.basicDataInputContainer}>
                                                <TextField
                                                    label={'Phone'}
                                                    value={this.state.userChanges.phone !== undefined ? this.state.userChanges.phone : this.state.userDetails?.phone}
                                                    disabled={!this.state.editing}
                                                    onChange={this.onFieldChange('phone')}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.basicDataInputContainer}>
                                                <TextField
                                                    label={'City'}
                                                    value={this.state.userChanges.city !== undefined ? this.state.userChanges.city : this.state.userDetails?.city}
                                                    disabled={!this.state.editing}
                                                    onChange={this.onFieldChange('city')}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.basicDataInputContainer}>
                                                <TextField
                                                    label={'Country'}
                                                    value={this.state.userChanges.country !== undefined ? this.state.userChanges.country : this.state.userDetails?.country}
                                                    disabled={!this.state.editing}
                                                    onChange={this.onFieldChange('country')}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.basicDataInputContainer}>
                                                <TextField
                                                    label={'Occupation'}
                                                    value={this.state.userChanges.occupation !== undefined ? this.state.userChanges.occupation : this.state.userDetails?.occupation}
                                                    disabled={!this.state.editing}
                                                    onChange={this.onFieldChange('occupation')}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            xs={12}
                                            md={2}
                                            style={{
                                                paddingLeft: 20
                                            }}
                                        >
                                            {this.state.userChanges.image !== undefined || this.state.userDetails.image ? (
                                                <Image
                                                    imageId={this.state.userChanges.image !== undefined ? this.state.userChanges.image : this.state.userDetails.image}
                                                    coreContainer={this.props.coreContainer}
                                                    style={{
                                                        width: '80%',
                                                        marginBottom: 10,
                                                    }}
                                                    alt={'No photo'}
                                                />
                                            ) : null}
                                            <Grid item xs={12} style={{marginBottom: 5}}>
                                                <FileUploadButton
                                                    onUpload={this.onImageUpload}
                                                    coreContainer={this.props.coreContainer}
                                                    variant={'outlined'}
                                                    color={'primary'}
                                                    disabled={!this.state.editing}
                                                    fullWidth
                                                >Upload photo</FileUploadButton>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    variant={'outlined'}
                                                    color={'secondary'}
                                                    disabled={!this.state.editing}
                                                    fullWidth
                                                    onClick={this.deleteProfilePhoto}
                                                >Delete</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>

                            <ExpansionPanel defaultExpanded={true}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography className={classes.expansionPanelSummaryTypography}>Car details</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid container>
                                        {this.state.userDetails?.carData ? (
                                            <Grid container>
                                                <CarCard
                                                    coreContainer={this.props.coreContainer}
                                                    car={this.state.userDetails.carData}
                                                    onClick={this.onCarClick}
                                                />
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>

                            {this.state.userDetails?.currentLocation ? (
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Typography className={classes.expansionPanelSummaryTypography}>Map</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <EventDetailsUserDetailsMap
                                            eventId={this.props.eventId}
                                            userId={this.props.userId}
                                            userDetails={this.state.userDetails}
                                            coreContainer={this.props.coreContainer}
                                        />
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            ) : null}

                            <ExpansionPanel>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography className={classes.expansionPanelSummaryTypography}>JSON</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid item>
                                        <pre>{JSON.stringify(this.state.userDetails, null, 2)}</pre>
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </React.Fragment>
                    )
                ) : <LinearProgress />}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(EventDetailsUserDetails);
