import {IMenuRow} from "./IMenuRow";
import {PubSubAbstract} from "../pubSub/PubSubAbstract";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from '@material-ui/icons/People'
import EventIcon from '@material-ui/icons/Event'
import SettingsIcon from '@material-ui/icons/Settings';
import React from "react";
import {MenuIdentifersEnum} from "./MenuIdentifiersEnum";
import {AppPaths} from "../router/AppPaths";
import {IMenuController} from "./IMenuController";
import {IAppRouter} from "../router/IAppRouter";
import {IEventsHub} from "../eventsHub/IEventsHub";
import {IAuthStore} from "../auth/IAuthStore";

export class MenuController extends PubSubAbstract<MenuIdentifersEnum> implements IMenuController {
    private _identifier: MenuIdentifersEnum | undefined;
    public get identifier(): MenuIdentifersEnum | undefined {
        if (this._identifier === undefined) {
            this._identifier = this.checkPath();
            this._appRouter.initMenuSubscribe();
        }

        return this._identifier;
    }
    
    constructor(
        private readonly _appRouter: IAppRouter,
        private readonly _authStore: IAuthStore,
        private readonly _eventsHub: IEventsHub,
    ) {
        super();
    }

    public getMenu(): IMenuRow[] {
        return [
            'Modules',
            this.menuRowFactory('Dashboard', <DashboardIcon />, MenuIdentifersEnum.Apps),
            this.menuRowFactory('Instances', <EventIcon />, MenuIdentifersEnum.Events),
            'Settings',
            ...(this._authStore.get().adminData.superAdmin ? [
                this.menuRowFactory('Admins', <PeopleIcon />, MenuIdentifersEnum.SettingsAdmins),
                this.menuRowFactory('Config', <SettingsIcon />, MenuIdentifersEnum.SettingsConfig)
            ] : []),
        ];
    }

    private menuRowFactory(text: string, icon: JSX.Element, identifier: MenuIdentifersEnum): IMenuRow {
        return {
            text,
            icon,
            action: () => this.setActive(identifier),
            active: (this.identifier === identifier),
        };
    }

    private checkPath(): MenuIdentifersEnum | undefined {
        switch (this._appRouter.currentPath()) {
            case AppPaths.Root: return MenuIdentifersEnum.Apps;
            case AppPaths.Events: return MenuIdentifersEnum.Events;
            case AppPaths.SettingsAdmins: return MenuIdentifersEnum.SettingsAdmins;
            case AppPaths.SettingsConfig: return MenuIdentifersEnum.SettingsConfig;
            default: return undefined;
        }
    }

    private setActive(identifier: MenuIdentifersEnum): void {
        if (identifier !== this.identifier) {
            this._identifier = identifier;
            this.publish(identifier);
            this._eventsHub.menuControllerPathChange.publish(identifier);
        }
    }
}
