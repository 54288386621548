import React from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";

interface IFormTableProps {
    header?: string[];
    rows: any[][];
    styles?: any[];
}

const FormTable = (props: IFormTableProps) => (
    <Table>
        {props.header ? (
            <TableHead>
                <TableRow>
                    {props.header.map((header, index) => (
                        <TableCell
                            key={`header_${index}`}
                        >{header}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
        ) : null}
        <TableBody>
            {props.rows.map((row, rowIndex) => (
                <TableRow key={`row_${rowIndex}`}>
                    {row.map((cell, cellIndex) => (
                        <TableCell
                            style={props.styles && props.styles[cellIndex] ? props.styles[cellIndex] : undefined}
                            key={`cell_${rowIndex}_${cellIndex}`}
                        >{cell}</TableCell>
                    ))}
                </TableRow>
            ))}
        </TableBody>
    </Table>
);

export default FormTable;
