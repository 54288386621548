import React from 'react';
import {Button, ButtonGroup, Grid, LinearProgress, TextField} from "@material-ui/core";
import {ICarDetails} from "../../../../core/models/ICar";
import {ICoreContainerRequired} from "../../../../core/ICoreContainer";
import EventCarDetailsUsers from "./EventCarDetailsUsers";
import {IUser} from "../../../../core/models/IUser";
import {FormDialogInputTypeEnum} from "../../../../core/dialogsController/FormDialogInputTypeEnum";
import ExpandablePanel from "../../../usages/ExpandablePanel";
import Image from "../../../usages/Image";
import FileUploadButton from "../../../usages/FileUploadButton";

interface IEventCarDetailsProps extends ICoreContainerRequired {
    eventId: string;
    carId: string;
    onCloseRequest: () => any;
}

interface IEventCarDetailsState {
    editing: boolean;
    carDetails: ICarDetails | null;
    changes: Partial<ICarDetails>;
}

class EventCarDetails extends React.Component<IEventCarDetailsProps, IEventCarDetailsState> {
    state: IEventCarDetailsState = {
        editing: true,
        carDetails: null,
        changes: {},
    };

    componentDidMount(): void {
        this.fetchCarDetails();
    }

    fetchCarDetails = async () => this.setState({
        carDetails: await this.props.coreContainer.IEventCarsService.getCarDetails(
            this.props.eventId,
            this.props.carId,
        ),
        changes: {},
    });

    toggleEditing = async () => {
        await this.fetchCarDetails();

        this.setState({
            editing: !this.state.editing,
            changes: {},
        })
    };

    onDeleteClick = async () => {
        if (await this.props.coreContainer.IEventCarsService.deleteCar(this.props.eventId, this.props.carId)) {
            this.props.onCloseRequest();
        }
    };

    unbindUser = async (userId: string) => {
        if (!(await this.props.coreContainer.IDialogsController.confirm('Are you sure you want to unbind this user?'))) {
            return;
        }

        await this.props.coreContainer.IEventUsersService.editEventUser(
            this.props.eventId,
            userId,
            {
                carId: null,
            },
        );

        await this.fetchCarDetails();
    };

    bindNewUser = async () => {
        const users: IUser[] = await this.props.coreContainer.IEventUsersService.getEventUsers(this.props.eventId);

        interface IUserSelectForm {
            userId: string;
        }

        const NO_USER_SELECTED = 'NO_USER_SELECTED';

        const formData = await this.props.coreContainer.IDialogsController.form<IUserSelectForm>({
            title: 'Select user',
            inputs: [{
                field: 'userId' as keyof IUserSelectForm,
                type: FormDialogInputTypeEnum.select,
                label: 'User',
                options: [
                    {
                        value: NO_USER_SELECTED,
                        label: '- Select user to bind -'
                    },
                    ...users
                        .filter((user) => (!this.state.carDetails?.users.map(({userId}) => userId).includes(user.userId)))
                        .map((user) => ({
                            value: user.userId,
                            label: user.name,
                        })),
                ],
                defaultValue: NO_USER_SELECTED,
            }],
        });

        if (!formData) {
            return;
        }

        const { userId } = formData;

        if (userId === NO_USER_SELECTED) {
            return;
        }

        await this.props.coreContainer.IEventUsersService.editEventUser(
            this.props.eventId,
            userId,
            {
                carId: this.props.carId,
            },
        );

        this.fetchCarDetails();
    };

    onCarDataChange = (changes: Partial<ICarDetails>) => this.setState({
        changes: {
            ...this.state.changes,
            ...changes,
        },
    });

    onImageUpload = (imageId: string) => this.onCarDataChange({
        image: imageId,
    });

    deleteImage = () => this.onCarDataChange({
        image: '',
    });

    saveChanges = async () => {
        await this.props.coreContainer.IHttpService.eventCarsPatch(this.props.eventId, this.props.carId, this.state.changes);
        // this.toggleEditing();
        this.fetchCarDetails();
    };

    onInputChange = (field: keyof ICarDetails) => (event: React.ChangeEvent<HTMLInputElement>) => this.onCarDataChange({
        [field]: event.currentTarget.value,
    });

    onUserClick = (userId: string) => {
        this.props.coreContainer.IAppRouter.push(`/events/${this.props.eventId}?view=users&user=${userId}`);
    };

    render() {
        if (!this.state.carDetails) {
            return <LinearProgress />
        }

        return (
            <React.Fragment>
                <Grid item xs={12}>
                    <ButtonGroup
                        style={{
                            marginBottom: 10,
                        }}
                    >
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            onClick={this.props.onCloseRequest}
                        >{'< back to cars list'}</Button>

                        {this.state.editing ? (
                            <Button
                                variant={'outlined'}
                                color={'primary'}
                                onClick={this.saveChanges}
                                disabled={!Object.keys(this.state.changes).length}
                            >Save changes</Button>
                        ) : null}

                        {/*<Button*/}
                        {/*    variant={'outlined'}*/}
                        {/*    color={'primary'}*/}
                        {/*    onClick={this.toggleEditing}*/}
                        {/*>{this.state.editing ? 'Finish editing' : 'Edit'}</Button>*/}

                        <Button
                            variant={'outlined'}
                            color={'secondary'}
                            onClick={this.onDeleteClick}
                            disabled={!this.state.editing}
                        >{'Delete'}</Button>
                    </ButtonGroup>
                </Grid>

                <ExpandablePanel defaultExpanded={true} label={'Basic car data'}>
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Grid item xs={12} style={{marginBottom: 5}}>
                                <TextField
                                    label={'Make and Model'}
                                    fullWidth
                                    disabled={!this.state.editing}
                                    value={this.state.changes.name !== undefined ? this.state.changes.name : this.state.carDetails?.name}
                                    onChange={this.onInputChange('name')}
                                />
                            </Grid>
                            <Grid item xs={12} style={{marginBottom: 5}}>
                                <TextField
                                    label={'Year'}
                                    fullWidth
                                    disabled={!this.state.editing}
                                    value={this.state.changes.year !== undefined ? this.state.changes.year : this.state.carDetails?.year}
                                    onChange={this.onInputChange('year')}
                                />
                            </Grid>
                            <Grid item xs={12} style={{marginBottom: 5}}>
                                <TextField
                                    label={'Color'}
                                    fullWidth
                                    disabled={!this.state.editing}
                                    value={this.state.changes.color !== undefined ? this.state.changes.color : this.state.carDetails?.color}
                                    onChange={this.onInputChange('color')}
                                />
                            </Grid>
                            <Grid item xs={12} style={{marginBottom: 5}}>
                                <TextField
                                    label={'License plate'}
                                    fullWidth
                                    disabled={!this.state.editing}
                                    value={this.state.changes.licensePlate !== undefined ? this.state.changes.licensePlate : this.state.carDetails?.licensePlate}
                                    onChange={this.onInputChange('licensePlate')}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={2} style={{paddingLeft: 20}}>
                            {this.state.changes.image !== undefined || this.state.carDetails?.image ? (
                                <Image
                                    imageId={this.state.changes.image !== undefined ? this.state.changes.image : this.state.carDetails?.image}
                                    coreContainer={this.props.coreContainer}
                                    style={{
                                        width: '80%',
                                        marginBottom: 10,
                                    }}
                                    alt={'No photo'}
                                />
                            ) : null}
                            <Grid item xs={12} style={{marginBottom: 5}}>
                                <FileUploadButton
                                    onUpload={this.onImageUpload}
                                    coreContainer={this.props.coreContainer}
                                    variant={'outlined'}
                                    color={'primary'}
                                    disabled={!this.state.editing}
                                    fullWidth
                                >Upload photo</FileUploadButton>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant={'outlined'}
                                    color={'secondary'}
                                    disabled={!this.state.editing}
                                    fullWidth
                                    onClick={this.deleteImage}
                                >Delete</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </ExpandablePanel>

                <ExpandablePanel defaultExpanded={true} label={'Users'}>
                    <EventCarDetailsUsers
                        editing={this.state.editing}
                        users={this.state.carDetails.users}
                        onUserClick={this.onUserClick}
                        onUnbindClick={this.unbindUser}
                        onBindNewUserClick={this.bindNewUser}
                    />
                </ExpandablePanel>

                <ExpandablePanel defaultExpanded={false} label={'JSON'}>
                    <pre>{this.state.carDetails ? JSON.stringify(this.state.carDetails, null, 2) : <LinearProgress />}</pre>
                </ExpandablePanel>
            </React.Fragment>
        );
    }
}

export default EventCarDetails;
