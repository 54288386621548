import {IAuthService} from "./IAuthService";
import {IHttpService} from "../http/IHttp.service";
import {IAuthStore} from "./IAuthStore";
import {IAuthCheckResponseDto} from "../models/IAuth";
import {IAuthTokenStore} from "./IAuthTokenStore";

export class AuthService implements IAuthService {
    constructor(
        private readonly _authTokenStore: IAuthTokenStore,
        private readonly _authStore: IAuthStore,
        private readonly _httpService: IHttpService,
    ) { }

    public async checkAuthorization(): Promise<void> {
        try {
            const checkResult: IAuthCheckResponseDto = await this._httpService.authCheck();

            this._authStore.update({
                authorized: true,
                adminData: {
                    adminId: checkResult.adminData.adminId,
                    email: checkResult.adminData.email,
                    name: checkResult.adminData.name,
                    superAdmin: checkResult.adminData.superAdmin,
                },
            });
        } catch {
            this._authTokenStore.removeToken();
            this._authStore.reset();
        }
    }

    public async login(email: string, password: string): Promise<void> {
        const loginResult = await this._httpService.authLogin(email, password);

        this._authTokenStore.setToken(loginResult.token);

        this._authStore.update({
            authorized: true,
            adminData: {
                adminId: loginResult.adminData.adminId,
                email: loginResult.adminData.email,
                name: loginResult.adminData.name,
                superAdmin: loginResult.adminData.superAdmin,
            },
        });
    }

    public logout = (): void => {
        this._authTokenStore.removeToken();
        this._authStore.reset();
    }
}
