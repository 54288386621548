import React from 'react';
import SubModule from "../../usages/SubModule";
import {Grid, Typography} from "@material-ui/core";
import LastActiveUsers from "./lastActiveUsers/LastActiveUsers";
import {ICoreContainerRequired} from "../../../core/ICoreContainer";
import UpcomingEvents from "./upcomingEvents/UpcomingEvents";

interface IRootModuleProps extends ICoreContainerRequired {

}

export class RootModule extends React.Component<IRootModuleProps> {
    render() {
        return (
            <SubModule
                title={'Dashboard'}
                subModules={[
                    {
                        label: 'Home',
                        component: (
                            <React.Fragment>
                                <UpcomingEvents coreContainer={this.props.coreContainer} />
                                <Grid container style={{marginTop: 20}}>
                                    <Grid item xs={12}>
                                        <Typography>
                                            <LastActiveUsers coreContainer={this.props.coreContainer} />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        ),
                        backgroundTransparent: true,
                    },
                    {
                        label: 'Something',
                        component: (
                            <Typography>
                                Here you'll see something. We will discuss what :)
                            </Typography>
                        ),
                    },
                ]}
            />
        );
    }
}
